export const sliderInput = {
  data() {
    return {
      touchstartX: 0,
      touchstartY: 0,
      touchendX: 0,
      touchendY: 0,
    };
  },
  mounted() {
    this.addEventListeners();
  },
  watch: {
    menuOpen(val) {
      if (val) {
        this.removeEventListeners();
      } else {
        this.addEventListeners();
      }
    },
    modalOpen(val) {
      if (val) {
        this.removeEventListeners();
      } else {
        this.addEventListeners();
      }
    },
  },
  methods: {
    //on wheel change slide
    onWheel(event) {
      if (this.now() - this.lastScrollTime < 200) {
        return;
      }

      // get wheel direction
      this.lastScrollTime = this.now();
      const value = event.wheelDelta || -event.deltaY;
      const delta = Math.sign(value);
      if (delta === -1) {
        this.slideNext();
      }
      if (delta === 1) {
        this.slidePrev();
      }
    },
    arrowKeyHandler(e) {
      if (e.key === "ArrowRight") {
        this.slideNext();
      } else if (e.key === "ArrowLeft") {
        this.slidePrev();
      }
    },
    onTouchStart(e) {
      this.touchstartX = event.changedTouches[0].screenX;
      this.touchstartY = event.changedTouches[0].screenY;
    },
    onTouchEnd(e) {
      let pageWidth = window.innerWidth || document.body.clientWidth;
      let treshold = Math.max(1, Math.floor(0.01 * pageWidth));

      const limit = Math.tan(((45 * 1.5) / 180) * Math.PI);

      this.touchendX = event.changedTouches[0].screenX;
      this.touchendY = event.changedTouches[0].screenY;

      let x = this.touchendX - this.touchstartX;
      let y = this.touchendY - this.touchstartY;

      let xy = Math.abs(x / y);
      let yx = Math.abs(y / x);
      if (Math.abs(x) > treshold || Math.abs(y) > treshold) {
        if (yx <= limit) {
          if (x < 0) {
            console.log("left");
            this.slideNext();
          } else {
            console.log("right");
            this.slidePrev();
          }
        }
        if (xy <= limit) {
          if (y < 0) {
            console.log("top");
          } else {
            console.log("bottom");
          }
        }
      } else {
        console.log("tap");
      }
    },
    addEventListeners() {
      const gestureZone = document.getElementById("view-chapters");

      //swipe listeners
      gestureZone.addEventListener("touchstart", this.onTouchStart, false);

      gestureZone.addEventListener("touchend", this.onTouchEnd, false);

      //wheel event listener
      window.addEventListener(
        "wheel",
        this.onWheel,
        { passive: true, capture: true },
        false
      );

      //right/left event listener
      window.addEventListener("keydown", this.arrowKeyHandler, false);

      this.lastScrollTime = this.now();
    },
    removeEventListeners() {
      const gestureZone = document.getElementById("view-chapters");

      //remove event listeners

      gestureZone.removeEventListener("touchstart", this.onTouchStart, false);

      gestureZone.removeEventListener("touchend", this.onTouchEnd, false);

      window.removeEventListener("keydown", this.arrowKeyHandler, false);

      window.removeEventListener(
        "wheel",
        this.onWheel,
        { passive: true, capture: true },
        false
      );
    },
  },
  beforeDestroy() {
    this.removeEventListeners();
  },
};

//color filter values
export const colorFilterValues = {
  data() {
    return {
      colorFilters: {
        end: {
          R: "0.244 0.402 0.987",
          G: "0.268 0.65 0.975",
          B: "0.37 0.726 0.785",
        },
        end2: {
          R: "0.06 0.01 0.97",
          G: "0.12 0.52 0.94",
          B: "0.37 0.59 0.47",
        },
        start: {
          //grey
          R: "0.37 0.67 1",
          G: "0.37 0.74 1",
          B: "0.37 0.82 1",
        },
        startOld: {
          R: "0.06 0.01 0.97",
          G: "0.12 0.52 0.94",
          B: "0.37 0.59 0.47",
        },
        endOld: {
          R: "0.27 0.78 0.84",
          G: "0.01 0.39 0.95",
          B: "0.02 0.16 0.07",
        },
        menuBlue: {
          R: "0.3 0.67 1",
          G: "0.3 0.74 1",
          B: "0.3 0.82 1",
        },
      },
    };
  },
};

//color filter timeline related items
export const colorFilterTimeline = {
  data() {
    return {
      filterID: "filter-chapter-slider",
      animationTimescale: 0.9,
      colorFilterTimeline: null,
      filterNone: "1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0",
    };
  },
  computed: {},
  mounted() {},
  methods: {
    //create color filter timeline
    createColorFilterTimeline() {
      if (this.$bowser.engine.name === "EdgeHTML") {
        console.log("%c%s", "color: red;", "edge, disable color filters");
        return;
      }

      const colorFilter = document.getElementById(this.filterID);

      const filterR = colorFilter.childNodes[1].childNodes[0];
      const filterG = colorFilter.childNodes[1].childNodes[1];
      const filterB = colorFilter.childNodes[1].childNodes[2];

      const colorFilters = this.colorFilters;

      const tlFilterRGB = new TimelineMax({
        paused: false,
      }).timeScale(this.animationTimescale);

      const delay = 0.1;
      const duration = 0.5;
      const ease = "Power2.easeInOut";

      tlFilterRGB
        .to(
          filterR,
          duration,
          {
            ease: ease,
            attr: { tableValues: colorFilters.end.R },
            onComplete() {
              tlFilterRGB.reverse();
            },
          },
          delay
        )
        .to(
          filterG,
          duration,
          {
            ease: ease,
            attr: { tableValues: colorFilters.end.G },
          },
          delay
        )
        .to(
          filterB,
          duration,
          {
            ease: ease,
            attr: { tableValues: colorFilters.end.B },
          },
          delay
        );

      this.colorFilterTimeline = tlFilterRGB;
    },
    runColorFilterTimeline() {
      if (!this.colorFilterTimeline) return;

      console.log("runColorFilterTimeline");
      this.colorFilterTimeline.play();
    },
    stopColorFilterTimeline() {
      if (!this.colorFilterTimeline) return;

      console.log("stopColorFilterTimeline");
      this.colorFilterTimeline.stop().invalidate().progress(0);
    },
    killColorFilterTimeline() {
      if (!this.colorFilterTimeline) return;

      console.log("killColorFilterTimeline");
      this.colorFilterTimeline.kill();
    },
  },
};

//chapters slider timeslines and animations
export const chaptersSliderAnimations = {
  data() {
    return {
      animationTimescale: 0.7,
    };
  },
  methods: {
    slideInAnimation(el) {
      const vm = this;

      const tlEnter = new TimelineMax({
        onComplete: function () {
          vm.enableParallax();
        },
      }).timeScale(this.animationTimescale);

      const {
        slideHeader,
        slideTitle,
        slideBackground,
        slideBackgroundImage,
        slideImage,
        slideContents,
        slideNav,
        slideScrollIndicator,
      } = this.$refs;

      const slideChapterBox = slideHeader.childNodes[0].childNodes[0];
      const slideChapterLabel = slideHeader.childNodes[1].childNodes[0];
      const slideChapterNo = slideHeader.childNodes[1].childNodes[1];
      const slideTitlePrefix = slideHeader.childNodes[1].childNodes[3];

      const slideIntro = slideContents.childNodes[1];
      const slideIntroLine = slideIntro.childNodes[1].childNodes[1];
      const slideButton =
        slideContents.childNodes[2].childNodes[1].childNodes[0];

      const slideNavNext = slideNav.childNodes[0];
      const slideNavPrev = slideNav.childNodes[1];

      const slideBackgroundImageImage = slideBackgroundImage.childNodes[0];

      const slideBackgroundMask = el.childNodes[1];

      let split = new SplitText(slideTitle, {
        type: "words",
        position: "relative",
      });

      tlEnter
        .add("tlEnterContentStart", 0.3)
        .from(
          slideChapterBox,
          0.3,
          {
            width: 0,
          },
          0
        )
        .from(
          [slideChapterLabel, slideChapterNo],
          0.3,
          {
            autoAlpha: 0,
          },
          "tlEnterContentStart-=.1"
        )
        .from(
          slideScrollIndicator,
          0.3,
          {
            autoAlpha: 0,
            x: "+=20",
          },
          "tlEnterContentStart-=.1"
        )
        .from(
          slideTitlePrefix,
          0.2,
          {
            x: "-=20",
            autoAlpha: 0,
          },
          "tlEnterContentStart-=0.1"
        )
        .staggerFrom(
          split.words,
          0.4,
          {
            x: "-50",
            autoAlpha: 0,
            ease: Power2.easeOut,
          },
          0.05,
          "tlEnterContentStart"
        )
        .add("tlTitleIn", "tlEnterContentStart+=0.3")
        .from(
          slideIntro,
          0.3,
          {
            autoAlpha: 0,
            x: "-=20",
          },
          "tlTitleIn"
        )
        .from(
          slideIntroLine,
          0.3,
          {
            height: 0,
          },
          "tlTitleIn+=.1"
        )
        .from(
          slideButton,
          0.3,
          {
            autoAlpha: 0,
            x: "-=20",
          },
          "tlTitleIn+=.1"
        )
        .from(
          slideNavNext,
          0.3,
          {
            autoAlpha: 0,
            x: "-=20",
          },
          "tlTitleIn+=.1"
        )
        .from(
          slideNavPrev,
          0.3,
          {
            autoAlpha: 0,
            x: "+=20",
          },
          "tlTitleIn+=.1"
        )
        .from(
          slideBackgroundMask,
          0.3,
          {
            autoAlpha: 0.4,
            // x: "-=10%",
            // ease: Power1.easeInOut
          },
          0.35
        )
        .from(
          slideBackground,
          0.7,
          {
            width: 0,
            //right: '-10%',
            ease: Power1.easeInOut,
          },
          0.2
        )
        .from(
          slideBackground,
          1,
          {
            scale: 1.1,
            ease: Power2.easeOut,
          },
          0.2
        )
        .from(
          slideBackgroundImageImage,
          0.9,
          {
            x: "-10%",
            ease: Power2.easeOut,
            // ease: Back.easeOut.config(.5)
          },
          0.2
        )
        .from(
          slideImage,
          0.65,
          {
            scale: 0.85,
            autoAlpha: 0,
            x: "+10%",
            y: "7%",
            ease: Power3.easeOut,
            // ease: Back.easeInOut.config(1)
          },
          0.5
        );
    },
    slideOutAnimation(el) {
      const tlLeave = new TimelineMax();

      const slideBackground = el.childNodes[0];

      const slideImage = el.childNodes[2].childNodes[0];

      const slideBackgroundMask = el.childNodes[1];

      const slideContents = el.childNodes[3];

      const slideHeader = slideContents.childNodes[0];
      const slideTitle = slideHeader.childNodes[1].childNodes[2];
      const slideTitlePrefix = slideHeader.childNodes[1].childNodes[3];

      const slideIntro = slideContents.childNodes[1];
      const slideButton =
        slideContents.childNodes[2].childNodes[1].childNodes[0];
      const slideChapterBox = slideHeader.childNodes[0].childNodes[0];
      const slideChapterLabel = slideHeader.childNodes[1].childNodes[0];
      const slideChapterNo = slideHeader.childNodes[1].childNodes[1];

      const slideNav = el.childNodes[4];
      const slideNavNext = slideNav.childNodes[0];
      const slideNavPrev = slideNav.childNodes[1];
      const slideScrollIndicator = el.childNodes[5];

      let split = new SplitText(slideTitle, {
        type: "words",
        position: "relative",
      });

      tlLeave
        .add("tlLeaveStart")
        .to(
          slideChapterBox,
          0,
          {
            width: 0,
          },
          "tlLeaveStart"
        )
        .to(
          [slideChapterLabel, slideChapterNo],
          0.2,
          {
            autoAlpha: 0,
          },
          "tlLeaveStart"
        )
        .to(slideTitlePrefix, 0.2, {
          autoAlpha: 0,
          x: "-=20",
        })
        .to(
          [slideIntro, slideButton, slideNavPrev],
          0.2,
          {
            autoAlpha: 0,
            x: "-=20",
          },
          "tlLeaveStart"
        )
        .to(
          slideScrollIndicator,
          0.2,
          {
            autoAlpha: 0,
            x: "+=20",
          },
          "tlLeaveStart"
        )
        .to(
          slideNavNext,
          0.2,
          {
            autoAlpha: 0,
            x: "+=20",
          },
          "tlLeaveStart"
        )
        .to(
          slideButton,
          0.2,
          {
            autoAlpha: 0,
            x: "-=20",
          },
          "tlLeaveStart"
        )
        .staggerTo(
          split.words,
          0.2,
          {
            x: "-50",
            autoAlpha: 0,
            ease: Power2.easeIn,
          },
          0.05,
          "tlLeaveStart"
        )
        .to(
          slideBackground,
          0.8,
          {
            scale: 1.1,
            autoAlpha: 0.4,
            ease: Power2.easeInOut,
          },
          "tlLeaveStart"
        )
        .to(
          slideBackgroundMask,
          0.35,
          {
            autoAlpha: 0.4,
            x: "-=10%",
            ease: Power1.easeInOut,
          },
          "tlLeaveStart"
        )
        .to(
          slideImage,
          0.4,
          {
            scale: 0.85,
            autoAlpha: 0,
            x: "-10%",
            y: "7%",
          },
          0
        );
    },
  },
};
