<template>
  <div class="media-wrap">
    <div class="video-container vimeo-container-youtube">
      <transition name="video-fade">
        <iframe
          :src="`https://www.youtube.com/embed/${data.id}?rel=0&modestbranding=1&showinfo=0`"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowfullscreen
          v-show="videoLoaded"
        ></iframe>
      </transition>
    </div>
    <media-caption v-if="data.description || info">
      <template v-slot:desc v-if="data.description">
        {{ data.description }}
      </template>
      <template v-slot:sub v-if="info">
        <span v-html="info"></span>
      </template>
    </media-caption>
  </div>
</template>

<script>
import MediaCaption from "@/components/ui/MediaCaption.vue";

export default {
  name: "VideoYoutube",
  props: ["data"],
  data() {
    return {
      videoLoaded: false,
    };
  },
  computed: {
    info() {
      if (this.data.title || this.data.photographer) {
        let info = "video: ";

        info = this.data.title ? info + this.data.title : info;
        info = this.data.photographer
          ? info + " by " + this.data.photographer
          : info;

        return info;
      }
      return false;
    },
  },
  components: {
    MediaCaption,
  },
  methods: {
    setLink() {
      if (this.data.title && this.data.link) {
        this.data.title = `<a href='${this.data.link}' target='_blank'>${this.data.title}</a>`;
      }
    },
  },
  created() {},
  mounted() {
    const vm = this;
    setTimeout(function () {
      vm.videoLoaded = true;
    }, 500);
  },
};
</script>

<style scoped></style>
