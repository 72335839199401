export const timelineLoading = {
  methods: {
    createLoadingTimeline() {
      //timeline variables

      const {
        loadingScreen,
        logoImgLogo,
        logoTextLondolozi,
        logoTextAfrica,
        logoAfrica,
        logoImgAfrica,
        loadingText,
        layerImage,
        loadingImage,
        layerBackground,
        layerInk,
        layerInkImg,
      } = this.$refs;

      const splitText = {
        africa: new SplitText(logoTextAfrica, {
          type: "chars",
        }),
        londolozi: new SplitText(logoTextLondolozi, {
          type: "chars",
        }),
      };

      const logoAfricaSvg = logoImgAfrica.$el;
      const africaPath1 = logoAfricaSvg.childNodes[0];
      const africaPath2 = logoAfricaSvg.childNodes[1];

      //morph into spinner and rotate
      const tlAfricaSpin = new TimelineMax({
        repeat: -1,
      });

      tlAfricaSpin
        .add("morphStart")
        .to(
          africaPath1,
          0.4,
          {
            morphSVG: {
              shape: africaPath2,
              type: "rotational",
            },
            delay: 1,
            ease: Power0.easeIn,
          },
          "morphStart"
        )
        .add("morphStarted")
        .to(
          logoAfricaSvg,
          1.5,
          {
            rotation: 1080,
            transformOrigin: "center",
            ease: Power1.easeInOut,
          },
          "morphStarted-=.3"
        )
        .add("spinFinished")
        .to(
          africaPath1,
          0.4,
          {
            fill: "rgb(55, 152, 251)",
          },
          "morphStarted-=.1"
        )
        .to(
          africaPath1,
          0.4,
          {
            fill: "rgb(238, 238, 238)",
          },
          "spinFinished-=.5"
        )
        .to(
          africaPath1,
          0.4,
          {
            morphSVG: {
              shape: africaPath1,
              type: "rotational",
            },
            ease: Power2.easeOut,
          },
          "spinFinished-=.3"
        );

      //in animation
      const tlLoadingIn = new TimelineMax({
        paused: true,
      });

      tlLoadingIn
        .set(loadingScreen, {
          left: 0,
        })
        .add("start")
        .to(
          [layerBackground, layerImage, layerInk],
          0.8,
          {
            width: "200%",
            ease: Power2.easeInOut,
            autoAlpha: 1,
          },
          "start"
        )
        .add("logoStart", "-=.4")
        .from(
          logoImgLogo,
          0.6,
          {
            autoAlpha: 0,
            y: "-=20",
            ease: Power2.easeOut,
          },
          "logoStart"
        )
        .staggerFrom(
          splitText.londolozi.chars,
          0.2,
          {
            autoAlpha: 0,
            scale: 0.8,
            y: "-=20",
            transformOrigin: "50% top",
            ease: Power2.easeOut,
          },
          0.03,
          "logoStart+=.1"
        )
        .add("logoIn", "+=.07")
        .from(
          logoAfrica,
          0.9,
          {
            scale: 0,
            autoAlpha: 0,
            y: "+=20",
            // rotationY:90,
            // ease: Back.easeInOut.config(1.4)
            ease: Power4.easeInOut,
          },
          "logoIn-=.35"
        )
        .staggerFrom(
          splitText.africa.chars,
          0.85,
          {
            autoAlpha: 0,
            scale: 0.5,
            y: "-=10",
            transformOrigin: "50% top",
            //ease: Back.easeInOut.config(0.8),
            ease: Power4.easeInOut,
            cycle: {
              x: ["+=64", "+=38", "+=12", "-=12", "-=38", "-=64"],
              delay: function (i) {
                return (
                  Math.floor(
                    Math.abs(i - (splitText.africa.chars.length - 1) / 2)
                  ) * 0.1
                );
              },
            },
          },
          0,
          "logoIn-=.5"
        )
        .add("africaIn")
        .call(this.winkLogo, null, this, "africaIn-=0.4")
        .add(tlAfricaSpin, "-=.4")
        .from(
          loadingImage,
          10,
          {
            x: "-5%",
            ease: Power1.easeOut,
            scale: 0.95,
          },
          "start"
        )
        .to(
          layerInkImg,
          7,
          {
            scale: 1,
            y: "-=10",
            ease: Power2.easeOut,
          },
          "start"
        );

      this.timelines.standard.in = tlLoadingIn;

      //out animation
      const tlLoadingOut = new TimelineMax({
        paused: true,
      });

      tlLoadingOut
        .add("startOut")
        .to(
          [layerBackground, layerImage, layerInk],
          0.5,
          {
            ease: Power2.easeOut,
            width: 0,
            autoAlpha: 0,
          },
          "startOut"
        )
        .to(
          [logoImgLogo, logoAfrica, logoTextLondolozi, logoTextAfrica],
          0.4,
          {
            y: "-=20",
            autoAlpha: 0,
          },
          "startOut-=0.2"
        )
        .set(loadingScreen, {
          left: "-100%",
        })
        .set([logoImgLogo, logoAfrica, logoTextLondolozi, logoTextAfrica], {
          clearProps: "all",
          onComplete() {
            tlLoadingIn.pause();
          },
        });

      this.timelines.standard.out = tlLoadingOut;
    },
  },
};
