<template>
  <span v-html="data.html" class="wp-html"> </span>
</template>

<script>
export default {
  name: "BlockHtml",
  props: ["data"],
};
</script>
