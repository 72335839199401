<template>
  <div class="media-caption caption" :class="`caption-align-${textAlign}`">
    <div v-if="$slots.desc" class="caption-main din uppercase">
      <slot name="desc"></slot>
    </div>
    <div v-if="$slots.sub" class="caption-sub italic grey2">
      <slot name="sub"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "MediaCaption",
  props: {
    textAlign: {
      type: String,
      default: "left",
    },
  },
};
</script>

<style scoped>
.caption-align-right {
  text-align: right;
  padding: 0.25em 1.25em 0 0;
}
.caption-align-right::before {
  display: none;
}
</style>
