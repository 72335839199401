<template>
  <div id="loading-screen" ref="loadingScreen" class="fixed">
    <div id="intro-scene" ref="introScene" v-if="!introShown">
      <div class="intro-background">
        <div class="background-gradient absolute" ref="introGradient"></div>
        <img :src="`${$urls.img}/loading/giraffee.jpg`" ref="introBackground" />
      </div>
      <div class="intro-contents layout-row height-100">
        <div class="intro-left layout-column layout-align-center-end">
          <div class="intro-text trajan" ref="introText">
            <span
              >“During my long walk to Freedom, I had the rare privilege to
              visit Londolozi.</span
            >
            <span
              >There I saw people of all races living in harmony amidst the
              beauty that mother nature offers.</span
            >
            <span>There I saw a living lion in the wild.</span>
            <span
              >Londolozi represents a model of the dream I cherish for the
              future of nature preservation in our country.”</span
            >
          </div>
          <div class="mandela-name relative">
            <img
              id="mandela-sig"
              :src="`${$urls.img}/loading/londolozi_africa_decal_mandela_sig2.png`"
              ref="mandelaSig"
            />
            <div class="intro-name italic" ref="introName">Nelson Mandela</div>
            <img
              id="mandela-stamp"
              class="absolute"
              :src="`${$urls.img}/loading/londolozi_africa_decal_mandela_stamp.png`"
              ref="mandelaStamp"
            />
          </div>
        </div>
        <div class="intro-right layout-column layout-align-center-start">
          <svg-mandela
            ref="mandela"
            class="intro-mandela"
            :playMandelaTimeline="playMandela"
          ></svg-mandela>
        </div>
      </div>
      <div class="skip-wrap" ref="skipWrap">
        <transition name="fade">
          <div
            class="intro-skip absolute layout-align-start-center layout-row"
            @click="playIntroLeave"
            v-show="loaderLoaded && viewLoaded"
            ref="skipButton"
          >
            <button class="btn-text btn italic">Skip</button>
            <i class="ai-right"></i>
          </div>
        </transition>
      </div>
    </div>

    <div id="scene-loading" class="scene" v-show="!showIntro">
      <div id="layer-logo" class="layer layer-center">
        <div
          class="
            londolozi-logo
            layout-column layout-align-center-center
            logo-main
          "
        >
          <img
            ref="logoImgLogo"
            class="logo-img-logo"
            :src="`${$urls.img}/londolozi-logo-wink.${winkExt}`"
          />
          <img
            class="hidden preload"
            :src="`${$urls.img}/londolozi-logo-wink.gif`"
          />
          <div class="logo-text-londolozi trajan" ref="logoTextLondolozi">
            LondolozI
          </div>
          <div class="logo-text-africa trajan" ref="logoTextAfrica">Impact</div>
          <div class="logo-img-africa absolute" ref="logoAfrica">
            <svg-africa ref="logoImgAfrica"></svg-africa>
          </div>
        </div>
      </div>
      <div id="layer-image" class="layer" ref="layerImage">
        <img
          class="leopard-loading"
          :src="`${$urls.img}/loading/londolozi-leopard-loading.png`"
          ref="loadingImage"
        />
      </div>
      <div id="layer-ink" class="layer" ref="layerInk">
        <img :src="`${$urls.img}/loading/ink1.jpg`" ref="layerInkImg" />
      </div>
      <div
        id="layer-background"
        ref="layerBackground"
        class="layer overlay"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TweenMax from "gsap/TweenMax";

import SvgAfrica from "@/components/misc/SvgAfrica.vue";
import SvgMandela from "@/components/misc/SvgMandela.vue";
import { storeDataLoading, loadImages, winkLogoMixin } from "@/mixins/core.js";
import { timelineIntro } from "@/mixins/loading/timelineIntro.js";
import { timelineLoading } from "@/mixins/loading/timelineLoading.js";

export default {
  name: "LoadingScreen",
  components: {
    SvgAfrica,
    SvgMandela,
  },
  mixins: [
    storeDataLoading,
    loadImages,
    winkLogoMixin,
    timelineLoading,
    timelineIntro,
  ],
  data() {
    return {
      timelines: {
        intro: {
          in: null,
          out: null,
          durationIn: 1500,
          durationOut: 0,
        },
        standard: {
          in: null,
          out: null,
          durationIn: 2000, //4500
          durationOut: 0.4,
        },
      },
      loading: true,
      playMandela: false,
      images: [
        this.$urls.img + "/londolozi-logo-wink.png",
        this.$urls.img + "/londolozi-logo-wink.gif",
        this.$urls.img + "/loading/londolozi-leopard-loading.png",
        this.$urls.img + "/loading/ink1.jpg",
        this.$urls.img + "/loading/giraffee.jpg",
        this.$urls.img + "/loading/londolozi_africa_decal_mandela_stamp.png",
        this.$urls.img + "/loading/londolozi_africa_decal_mandela_sig2.png",
      ],
    };
  },
  computed: {
    bodyEl() {
      return document.body;
    },
  },
  watch: {
    loading(val) {
      if (val) {
        //this.timelines.in.restart();
      } else {
        //this.timelines.out.restart();
      }
    },
    viewLoaded(val) {
      if (val) {
        this.toggleLoading();
      }
    },
    viewReady(val) {
      //check if first load is finished
      if (!val) {
        this.toggleLoading();
      }
    },
  },
  mounted() {
    //preload images
    this.preloadImages(this.images, this.firstLoad);

    //set up timeline on mounted
    if (!this.introShown) {
      this.createIntroTimeline();
    }
    this.createLoadingTimeline();
  },
  methods: {
    firstLoad() {
      console.log("play loader for first time");
      this.toggleLoader(true);
    },
    toggleLoading(manualTrigger = false) {
      this.loading = !this.loading;

      this.toggleLoader(this.loading, manualTrigger);
    },
    toggleLoader(open, manualTrigger = false) {
      const vm = this;

      let tl = this.showIntro ? this.timelines.intro : this.timelines.standard;
      let timeScale = this.showIntro ? 1.25 : 1;

      //opening loader
      if (open) {
        console.log("open loader");

        this.bodyEl.classList.add("loading", "no-scroll");

        this.toggleStatus({
          type: "loaderLoaded",
          status: false,
        });

        tl.in.timeScale(timeScale).restart();

        if (!manualTrigger) {
          setTimeout(function () {
            vm.toggleStatus({
              type: "loaderLoaded",
              status: true,
            });
          }, tl.durationIn);
        }
      }

      //closing loader
      else {
        const vm = this;
        setTimeout(function () {
          vm.bodyEl.classList.remove("loading", "no-scroll");
        }, 200);
        if (this.showIntro) {
        } else {
          console.log("close loader");
          tl.out.restart();
        }
      }
    },
    playIntroLeave(skip = false) {
      //change home timescale if intro skipped
      let outTimescale = 3;
      if (skip) {
        outTimescale = 4.5;
        this.toggleStatus({
          type: "introSkip",
          status: true,
        });
      }
      console.log("playIntroLeave");
      this.timelines.intro.in.pause();
      this.timelines.intro.out.timeScale(outTimescale).play();
    },
    clearIntro() {
      //toggle intro shown flags
      console.log("remove intro now");
      this.toggleStatus({
        type: "viewReady",
        status: true,
      });
      this.toggleStatus({
        type: "showIntro",
        status: false,
      });
      this.toggleStatus({
        type: "introShown",
        status: true,
      });

      //destroy timeline
      this.timelines.intro.in.kill();
      this.timelines.intro.out.kill();
      this.timelines.intro.in = null;
      this.timelines.intro.out = null;
    },
    startMandelaTimeline() {
      this.playMandela = true;
    },
  },
};
</script>

<style scoped>
.intro-skip {
  left: 93%;
  top: 5%;
  transform: translate(-50%, 0);
  z-index: 4;
  opacity: 1;
  text-align: center;
  transition: 0.25s ease-in-out;
  padding: 0.625rem 0.75em;
  cursor: pointer;
}
.intro-skip button {
  padding: 0;
}
.intro-skip:hover {
  padding-left: 0.704em;
}
.intro-skip:hover i {
  padding-left: 0.259em;
}
.skip-wrap {
  visibility: hidden;
  opacity: 0;
}
.skip-wrap i {
  font-size: 1.688em;
  height: 0.889em;
  margin: 0 0.296em 0 0.37em;
  width: 1.185em;
  transition: 0.25s ease-in-out;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease-in;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
#intro-scene {
  background: white;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  background-position: bottom center;
  background-size: 100%;
}
.background-gradient {
  height: 100%;
  width: 100%;
  background: radial-gradient(
    ellipse closest-side,
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 2;
}
.intro-background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  right: 0;
}
.intro-background img {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -1.6vw;
  transform-origin: bottom right;
}
.intro-contents {
  z-index: 2;
  position: relative;
  font-size: 1rem;
}
.intro-left {
  width: 57.1%;
  position: relative;
  margin-bottom: 2.8%;
}
.intro-text {
  text-align: right;
  line-height: 2;
  margin-bottom: 1em;
  pointer-events: none;
  font-size: 1.6em;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: #464749;
  font-style: italic;
  max-width: 31em;
}
.intro-text > span {
  padding-right: 0.313em;
}
.intro-text,
.intro-name {
  pointer-events: none;
  user-select: none;
}
.intro-name {
  color: var(--grey2);
  font-size: 1.1em;
  text-align: right;
}
#mandela-sig {
  margin-bottom: 0.4em;
  max-width: 10.5em;
}
#mandela-stamp {
  top: -1em;
  right: 11.5em;
  margin: 0;
  max-width: 8.5em;
}
.intro-right {
  width: 42.9%;
  padding-left: 5.6%;
  position: relative;
}
.intro-mandela {
  margin-bottom: 8%;
  width: 52%;
}

#loading-screen {
  background-position: center center;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  z-index: 11;
  opacity: 1;
  background: transparent;
  overflow: hidden;
}
#layer-background {
  z-index: 9;
  background: white;
  width: 0;
  left: 0;
}
#layer-logo {
  top: 16.8%;
  z-index: 12;
}
#layer-image {
  bottom: 2vh;
  left: 0;
  transform: none;
  width: 0;
  z-index: 11;
  opacity: 0;
  overflow: hidden;
  height: 34.375em;
  top: 40%;
}
#layer-ink {
  z-index: 10;
  left: 0;
  bottom: -13%;
  width: 0;
  overflow: hidden;
  height: 42.625em;
  top: 41%;
}
#layer-ink img {
  display: block;
  transform: scale(0.98);
  transform-origin: bottom left;
  opacity: 0.65;
  width: 55em;
  min-width: 32.5em;
  max-width: 88vh;
}
.wink-preload {
  display: none !important;
}
.scene {
  top: 0;
}
#scene-loading {
  font-size: 16px;
}
.layer {
  position: absolute;
}
.layer-center {
  left: 50%;
  transform: translate(-50%, 0);
}
.londolozi-logo {
  width: 100%;
}
.leopard-loading {
  /* filter: url(#filter-blue); */
  z-index: 12;
  display: block;
  min-width: 56.25em;
  width: 93.75em;
  max-width: 150vh;
}
/*=============================================
=                  MEDIA QUERIES             =
=============================================*/
@media screen and (max-width: 1600px) {
  .intro-contents {
    font-size: 15px;
  }
}
@media screen and (max-width: 1440px) {
  .intro-contents {
    font-size: 14px;
  }
  .intro-left {
    width: 62.5%;
  }
  .intro-right {
    width: 37.5%;
    padding-left: 5%;
  }
  .intro-name {
    font-size: 17px;
  }
}
@media screen and (max-width: 1200px) {
  #scene-loading {
    font-size: 14px;
  }
  .intro-contents {
    font-size: 13px;
  }
  .intro-text {
    max-width: 26.6em;
  }
  .intro-left {
    width: auto;
    margin-left: auto;
  }
  .intro-right {
    margin-left: 5%;
    margin-right: auto;
    min-width: 20em;
    padding-left: 0;
    width: auto;
  }
  .intro-name {
    font-size: 16px;
  }
}
@media screen and (max-width: 1024px) {
  #layer-image img {
    margin-left: -5.538em;
  }
  #layer-ink img {
    margin-left: -6.538em;
  }
  #scene-loading {
    font-size: 13px;
  }
  #scene-loading .logo-main {
    font-size: 12px;
  }
  .portrait .intro-contents {
    flex-direction: column;
  }
  .portrait .intro-left {
    margin: auto auto 0 auto;
    align-items: center;
  }
  .portrait .intro-right {
    margin-bottom: auto;
    min-height: 18.923em;
    margin-top: -6em;
    margin-left: auto;
    margin-right: auto;
    width: 35em;
  }
  .portrait .intro-name {
    text-align: left;
  }
  .portrait .intro-background {
    left: 0;
    right: auto;
    width: 130%;
  }
  .portrait .intro-text {
    text-align: left;
  }
  .portrait .mandela-name {
    align-self: flex-start;
  }
  .portrait #mandela-sig {
    margin-left: -0.9em;
  }
  .portrait #mandela-stamp {
    bottom: -10em;
    top: auto;
    left: -0.8em;
    right: auto;
  }
}
@media screen and (max-width: 1020px) {
  .landscape .intro-contents {
    font-size: 12px;
  }
}
@media screen and (max-width: 991px) {
  .intro-skip {
    top: 3%;
    left: 94%;
  }
  .landscape .intro-contents {
    font-size: 11px;
  }
  .landscape .intro-name {
    font-size: 15px;
  }
  .landscape .intro-text {
    margin-bottom: 0.9em;
    max-width: 26em;
  }
  .landscape #mandela-stamp {
    top: -0.7em;
  }

  .portrait .intro-contents {
    font-size: 12px;
  }
  .portrait .intro-left {
    padding: 0 3em;
  }
  #scene-loading .logo-main {
    font-size: 10.5px;
  }
}
@media screen and (max-width: 767px) {
  #scene-loading {
    font-size: 10px;
  }
  .intro-skip {
    left: 90%;
  }
  .landscape .intro-contents {
    font-size: 9.5px;
  }
  .landscape .intro-text {
    line-height: 1.9;
  }
  .landscape .intro-name {
    font-size: 14px;
  }
  .landscape .mandela-name {
    font-size: 10px;
  }
  .landscape .intro-right {
    min-width: 18em;
    margin-bottom: 1.5rem;
  }
  .landscape .intro-left {
    margin-bottom: 1em;
  }
  .landscape .intro-text {
    margin-bottom: 0.6em;
  }
  .landscape #mandela-stamp {
    top: -0.4em;
    right: 12em;
    max-width: 8em;
  }
  .portrait #layer-image {
    top: 47%;
  }
  .portrait #layer-ink {
    top: 48%;
  }
  .portrait .intro-background {
    width: 170%;
  }
  .portrait .intro-background img {
    left: -15vw;
  }
}
@media screen and (max-width: 650px) {
  .landscape .intro-contents {
    font-size: 9px;
  }
  .portrait .intro-contents {
    font-size: 10.5px;
  }
  .portrait .intro-name {
    font-size: 14px;
  }
}
@media screen and (max-width: 550px) {
  .landscape .intro-contents {
    font-size: 9px;
  }
  .portrait .intro-contents {
    font-size: 9px;
  }
  .portrait .intro-right {
    width: 35em;
  }
}
@media screen and (max-width: 479px) {
  #scene-loading {
    font-size: 9px;
  }
  #scene-loading .logo-main {
    font-size: 10px;
  }
  #layer-image img {
    margin-left: -7em;
  }
  #layer-ink img {
    margin-left: -10em;
  }
  .portrait .intro-text {
    max-width: 22em;
  }
  .portrait .intro-right {
    margin-top: -5.7em;
    width: 20em;
    min-height: 17em;
  }
  .portrait .intro-name {
    font-size: 13px;
  }
}
@media screen and (max-width: 420px) {
  #scene-loading .logo-main {
    font-size: 9.5px;
  }
  #scene-loading {
    font-size: 8px;
  }
  .portrait .intro-text {
    max-width: 20em;
    margin-bottom: 0.8em;
  }
  .portrait .intro-right {
    margin-top: -7em;
    width: 21em;
  }
  .portrait #mandela-stamp {
    left: -0.7em;
    bottom: -8.6em;
    max-width: 7.4em;
  }
}
</style>
