<template>
  <a
    class="btn-download layout-align-start-center layout-row"
    target="_blank"
    :href="data.url"
  >
    <span class="grey2 italic">{{ data.prompt }}</span>
    <i class="ai-right"></i>
    <span class="h4">{{ data.text }}</span>
  </a>
</template>

<script scoped>
export default {
  name: "ButtonDownload",
  props: ["data"],
};
</script>
