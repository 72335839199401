<template>
  <modal
    :name="modalName"
    :preMount="false"
    :disableBackdrop="true"
    :contentTransition="'modal-in'"
    :backdropTransition="'modal-overlay-in'"
    @before-open="beforeOpen"
    @before-close="beforeClose"
    @opened="opened"
    @closed="closed"
    v-if="viewLoaded"
  >
    <div class="modal" :id="`modal-${modalName}`" :class="category">
      <div
        class="modal-contents-wrap slideout"
        :class="{ 'modal-has-bottom-decal': bottomDecal }"
      >
        <div class="modal-contents" ref="modalContents">
          <div class="modal-title" ref="modalTitle">
            <div class="title-line"></div>
            <h2 class="trajan">{{ title }}</h2>
          </div>
          <div class="modal-body" ref="modalBody">
            <slot name="content">Modal Content</slot>
          </div>
        </div>
        <div v-if="topDecal" class="modal-top-decal" ref="modalTopDecal">
          <img :src="topDecal" />
        </div>
        <div v-if="bottomDecal" class="modal-bottom-decal">
          <img :src="bottomDecal" />
        </div>
        <div v-if="hasPattern" class="modal-pattern fixed center-left">
          <img :src="`${$urls.img}/patterns/pattern-right-radial.png`" />
        </div>
      </div>
      <button
        class="btn-close btn absolute btn-side"
        type="button"
        @click="hideModal"
        ref="modalButton"
      ></button>
    </div>
  </modal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { eventListeners, storeScrollBarWidth } from "@/mixins/core.js";

export default {
  name: "ModalContent",
  props: [
    "modalName",
    "title",
    "bottomDecal",
    "hasPattern",
    "topDecal",
    "category",
  ],
  mixins: [eventListeners, storeScrollBarWidth],
  computed: {
    ...mapState(["modalOpen", "menuOpen", "viewReady", "viewLoaded"]),
  },
  data() {
    return {
      timeline: null,
      scrollbarWidth: false,
    };
  },
  watch: {
    $route(to, from) {
      //if removing modal hash, close modal
      if (
        to.name === from.name &&
        to.path === from.path &&
        from.hash.includes("#modal")
      ) {
        this.hideModal();
      }
    },
    viewReady(val) {
      if (val) {
        //open modal if has in url
        if (this.$route.hash === `#modal-${this.modalName}`) {
          this.showModal(this.modalName);
        }
      }
    },
  },
  methods: {
    ...mapActions(["showModal", "hideModal"]),
    toggleBodyClass(val) {
      if (val) {
        this.bodyEl.classList.add("no-scroll");
      } else {
        this.bodyEl.classList.remove("no-scroll");
      }
    },
    createTimeline() {
      const { modalTitle, modalBody } = this.$refs;

      const modalTitleText = modalTitle.childNodes[1];
      const modalTitleLine = modalTitle.childNodes[0];

      const tlModal = new TimelineMax({
        paused: true,
        delay: 0.3,
      });

      tlModal
        .from(
          modalTitleText,
          0.8,
          {
            autoAlpha: 0,
            x: "+=80",
            ease: Power2.easeOut,
          },
          0.2
        )
        .staggerFrom(
          modalBody.childNodes,
          0.8,
          {
            autoAlpha: 0,
            x: "+=80",
            ease: Power2.easeOut,
          },
          0.1,
          0.4
        )
        .from(
          modalTitleLine,
          0.4,
          {
            height: 0,
          },
          1
        );

      if (this.topDecal) {
        const { modalTopDecal } = this.$refs;

        tlModal.from(
          modalTopDecal,
          0.8,
          {
            autoAlpha: 0,
            x: "+=80",
            ease: Power2.easeOut,
          },
          0.4
        );
      }

      this.timeline = tlModal;
    },
    beforeOpen() {
      const vm = this;
      this.addScrollbarPaddingToStore(this.currentScrollBarWidth);

      setTimeout(function () {
        vm.toggleBodyClass(true);
      }, 100);

      //add hash to url for back functionality
      this.$router.push({ hash: `modal-${this.modalName}` });

      //add event listeners
      this.addEventListeners();

      //close menu if open
      if (this.menuOpen) {
        this.toggleStatus({
          type: "menuOpen",
          status: false,
        });
      }

      //create timeline
      this.createTimeline();
      setTimeout(vm.playTimeline, 50);
    },
    opened() {},
    playTimeline() {
      const vm = this;
      if (this.timeline) {
        console.log("%c%s", "color: red;", "timeline ready, play");
        this.timeline.play();
      } else {
        console.log("%c%s", "color: red;", "timeline not ready set timeout");
        setTimeout(vm.playTimeline, 300);
      }
    },
    beforeClose() {
      const vm = this;
      setTimeout(function () {
        vm.toggleBodyClass(false);
      }, 100);

      this.timeline.timeScale(1.5).delay(0);
      this.timeline.reverse();

      //remove event listeners
      this.removeEventListeners();
      //remove modal hash
      this.$router.push({ hash: false });
    },
    closed() {
      this.removeScrollbarPaddingToStore();
      this.timeline.kill();
      this.timeline = null;
    },
    onEscape(e) {
      if (this.modalOpen && e.keyCode == 27) {
        this.hideModal();
      }
    },
    onClick(e) {
      if (this.modalOpen && e.target.className === "modal-content-wrapper") {
        this.hideModal();
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.modal-content {
  height: 100%;
  right: 0;
  left: auto;
  margin: 0;
  padding: 0;
  max-width: calc(100% - var(--button-rem));
  width: 38.75em;
  transform: translate(0, 0);
}
.modal-contents-wrap {
  height: 100%;
  position: relative;
  background: white;
  overflow: auto;
  overflow-x: hidden;
}
.modal-contents {
  padding: 3rem 1.818rem 3rem;
  overflow: auto;
  overflow-x: hidden;
  position: relative;
  z-index: 1;
}
.modal-pattern {
  pointer-events: none;
  z-index: 0;
  left: auto;
  right: 0;
  mix-blend-mode: multiply;
  top: 50%;
  transform: translate(60%, -50%);
  height: 100vh;
}
.modal-pattern img {
  height: 100%;
  min-height: 800px;
}
.modal-title {
  max-width: 70%;
  position: relative;
  margin: 5rem 0 2.1rem;
  padding-left: 1.25rem;
}
.title-line {
  background: var(--blue);
  width: 4px;
  position: absolute;
  left: -1.818rem;
  height: calc(100% - 0.8rem);
  top: 0.1rem;
}
.modal-contents::after {
  position: absolute;
  bottom: 4rem;
  height: 2px;
  background-color: var(--grey2);
  width: 3.909rem;
  left: 50%;
  transform: translate(-50%, 0);
  content: "";
  display: none;
}
.modal-in-enter-active {
  transition: 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}
.modal-in-leave-active {
  transition: 0.6s 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
.modal-in-enter,
.modal-in-leave-to {
  transform: translate(calc(100% + 60px), 0);
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/
@media screen and (max-width: 767px) {
  .modal-content {
    max-width: calc(100% - 49px);
  }
}

@media screen and (max-width: 479px) {
  .modal-content {
    max-width: calc(100% - 24px);
  }
  .modal-contents {
    padding: 3rem 1.5rem 3rem 1.818rem;
  }
  .modal-title {
    max-width: 80%;
    padding-left: 1.14rem;
  }
}
@media screen and (max-width: 420px) {
  .modal-content {
    max-width: calc(100% - 23px);
  }
}
</style>
