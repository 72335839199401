import { mapState, mapActions, mapGetters } from "vuex";

//parallax related props
export const parallaxFunctions = {
  computed: {
    ...mapState(["disableParallax"]),
  },
};

//modal related props
export const modalFunctions = {
  computed: {
    ...mapState(["modalOpen"]),
  },
  methods: {
    ...mapActions(["showModal", "hideModal"]),
  },
};

//ligtbox related props
export const lightboxFunctions = {
  computed: {
    ...mapState(["modalOpen"]),
  },
  methods: {
    ...mapActions(["showModal", "hideModal", "setLightboxData"]),
    openLightbox(data) {
      this.setLightboxData(data);

      let modalName = data.type == "image" ? "lightbox" : "videoModal";
      this.showModal(modalName);
    },
  },
};

//zoom functions
export const zoomFunctions = {
  computed: {
    ...mapState(["modalOpen", "menuOpen"]),
    viewportmeta() {
      return document.querySelector('meta[name="viewport"]');
    },
  },
  methods: {
    resetAndPreventZoom() {
      console.log("%c%s", "color: blue;", "reset and prevent zoom");
      this.viewportmeta.content =
        "width=device-width, minimum-scale=1.0, maximum-scale=1.0";
    },
    resetAndAllowZoom() {
      console.log("%c%s", "color: blue;", "reset and allow zoom");
      this.viewportmeta.content =
        "width=device-width, minimum-scale=1.0, maximum-scale=1.0";

      const vm = this;
      setTimeout(function () {
        vm.allowZoom();
      }, 300);
    },
    allowZoom() {
      console.log("%c%s", "color: blue;", "allow zoom");
      this.viewportmeta.content =
        "width=device-width, minimum-scale=1.0, maximum-scale=1.8";
    },
  },
};

//menu button related props
export const menuFunctions = {
  computed: {
    ...mapState(["menuButton", "menuOpen"]),
  },
  methods: {
    ...mapActions(["showMenuButtonAction", "hideMenuButtonAction"]),
  },
};

//check if intro skipped
export const introSkip = {
  computed: {
    ...mapState(["introSkip"]),
  },
};

//get dimenions on resize
export const handleResize = {
  data() {
    return {
      window: {
        width: 0,
        height: 0,
        orientation: null,
        fontsize: null,
      },
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      this.window.width = window.outerWidth;
      this.window.height = window.outerHeight;

      this.window.orientation = device ? device.orientation : null;

      this.window.fontsize = window.getComputedStyle(
        document.documentElement
      ).fontSize;
    },
  },
};

//store actions & methods to get and set scrollbar width
export const storeScrollBarWidth = {
  data() {
    return {
      scrollbarWidth: 0,
    };
  },
  computed: {
    ...mapState(["scrollBarWidth", "currentScrollBarWidth"]),
    bodyEl() {
      return document.body;
    },
    isBrowser() {
      return typeof window !== "undefined";
    },
  },
  methods: {
    ...mapActions(["toggleStatus"]),
    updateScrollBarWidth() {
      if (!this.isBrowser) {
        return null;
      }
      this.scrollbarWidth =
        window.innerWidth - document.documentElement.offsetWidth;
    },
    setBodyCss(name, value) {
      if (this.isBrowser) {
        var s = document.body.style;
        s[name] = value;
      }
    },
    addScrollbarPaddingToStore(customWidth = false) {
      if (this.scrollbarWidth || customWidth) {
        console.log("%c%s", "color: red;", "here");
        this.toggleStatus({
          type: "scrollBarWidth",
          status: customWidth ? customWidth : this.scrollbarWidth + "px",
        });
      }
    },
    removeScrollbarPaddingToStore() {
      this.toggleStatus({
        type: "scrollBarWidth",
        status: "0px",
      });
    },
  },
};

//basic evet listener functions
export const eventListeners = {
  methods: {
    addEventListeners() {
      //escape
      document.addEventListener("keydown", this.onEscape, false);
      //backdrop click
      document.addEventListener("click", this.onClick, false);
    },
    removeEventListeners() {
      //escape
      document.removeEventListener("keydown", this.onEscape, false);
      //backdrop click
      document.removeEventListener("click", this.onClick, false);
    },
  },
};

//loading and view data related store actions and state
export const storeDataLoading = {
  data() {
    return {
      viewLeaveDuration: 500, //time to clean up stage
      viewEnterDelay: 300, //time to clear loader
      loaderOpenDelay: 500, //time for loader to open across content
      showChild: false,
    };
  },
  computed: {
    ...mapState([
      "viewDataLoaded",
      "viewLoaded",
      "loaderLoaded",
      "viewReady",
      "introShown",
      "showIntro",
      "modalOpen",
      "menuOpen",
      "disableParallax",
    ]),
  },
  watch: {
    viewReady(val) {
      this.viewShow();

      if (!val) {
        this.showChild = false;
      }
    },
    modalOpen(val) {
      if (val) {
        this.$modal.push(val);
      } else {
        this.$modal.pop();
      }
    },
  },
  methods: {
    ...mapActions([
      "loadPartData",
      "toggleStatus",
      "showModal",
      "hideModal",
      "getPostsData",
    ]),
    //trigger view loaded state to true
    initView() {
      const vm = this;

      if (this.loaderLoaded) {
        console.log("loader ready");
        this.toggleStatus({
          type: "viewLoaded",
          status: true,
        });

        //trigger viewready manually if showing intro
        //delay for loader to close before starting view show animations etc
        if (!this.showIntro) {
          setTimeout(function () {
            vm.toggleStatus({
              type: "viewReady",
              status: true,
            });
          }, this.viewEnterDelay);
        }
      } else {
        setTimeout(function () {
          vm.initView();
        }, 100);
      }
    },
    //empty function to be overwritten in view
    viewShow() {
      console.log("viewShow");
      this.showChild = true;
    },
    viewLeaveAnimations(to, from) {
      return;
    },
    leaveView(to, from, next) {
      const vm = this;

      this.viewLeaveAnimations(to, from);

      //hide all open modals
      this.hideModal();

      //if going to home and intro not yet show, set it to be shown by loader
      if (!this.introShown && to.path === "/") {
        //dont show the intro if user entered from not home
        this.toggleStatus({
          type: "introShown",
          status: true,
        });

        //this will show the intro if user entered page on not home and then went home
        // console.log(
        // 	"going to home from another view and intro not yet show, set it to show"
        // );
        // this.toggleStatus({
        // 	type: "showIntro",
        // 	status: true
        // });
      }

      //time to clean up stage
      console.log("leaving view in " + this.viewLeaveDuration);
      setTimeout(function () {
        vm.toggleStatus({
          type: "viewReady",
          status: false,
        });

        //allow time for loader to open
        setTimeout(function () {
          next();
        }, vm.loaderOpenDelay);
      }, this.viewLeaveDuration);
    },
    preloadPostImages() {
      if (this.data.posts) {
        let images = [];

        this.data.posts.forEach((posts) => {
          if (this.$store.state.data.POSTS[posts.name]) {
            this.$store.state.data.POSTS[posts.name].data.forEach((post) => {
              images.push(post.thumbnail);
            });
          }
        });

        this.preloadImages(images, function () {
          console.log("done preloading post images");
        });
      }
    },
  },
};

//standard before leaving view
export const beforeLeaveRoute = {
  data() {
    return {
      leaving: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    this.leaving = true;

    //leave view gracefully
    this.leaveView(to, from, next);
  },
};

//get time now
export const timeNow = {
  methods: {
    now() {
      return Date.now();
    },
  },
};

//preload an array of images with callback
export const loadImages = {
  methods: {
    preloadImages(images, callback) {
      let imageLoaded = 0;
      for (const imageSrc of images) {
        const img = new Image();
        img.src = imageSrc;

        img.onload = () => {
          imageLoaded++;
          console.log("%c%s", "color: DarkSeaGreen;", "loaded img " + img.src);

          if (imageLoaded === images.length) {
            console.log(
              "%c%s",
              "color: DarkSeaGreen;",
              "Done loading " + images.length + " images"
            );
            callback();
          }
        };
      }
    },
  },
};

//wink function
export const winkLogoMixin = {
  data() {
    return {
      winkExt: "png",
    };
  },
  methods: {
    winkLogo() {
      console.log("wink logo");
      this.winkExt = "gif";

      const vm = this;
      setTimeout(function () {
        vm.winkExt = "png";
      }, 900);
    },
  },
};

//chapter part
export const chapterPart = {
  props: ["data", "leavingView", "currentChapter", "part", "show"],
  data() {
    return {
      tempTimelineButtonFlag: false,
      debugTimeline: false,
      doParallax: false,
      parallaxActive: false,
      parallax: {
        parallax: null,
      },
      itemsToShow: [["showTitle", 300]],
      showInfoBoxes: false,
      showBackground: false,
      showQuote: false,
      showTitle: false,
      showDecal: false,
      showInk: false,
      showDelay: false,
      showDiagram: false,
      showLead: false,
      showPattern: false,
      showImageBox: false,
      showVideoBox: false,
      showMisc: false,
    };
  },
  computed: {
    ...mapState([
      "viewReady",
      "menuOpen",
      "modalOpen",
      "disableParallax",
      "scrollBarWidth",
    ]),
    viewCSSId() {
      return `chapter-${this.currentChapter}-${this.part.id}`;
    },
    viewCSSClasses() {
      return `view-part part-${this.part.id} section-scene`;
    },
    bodyEl() {
      return document.body;
    },
    parallaxScalarX() {
      let scalar = 0.2; //desktop

      if (device.type == "tablet") {
        scalar = 0.55;
      } else if (device.type == "mobile") {
        scalar = 0.56;
      }
      return scalar;
    },
    parallaxScalarY() {
      let scalar = 0.1; //desktop

      if (device.type == "tablet") {
        scalar = 0.08;
      } else if (device.type == "mobile") {
        scalar = 0.08;
      }
      return scalar;
    },
    parallaxOptions() {
      return {
        scalarX: this.parallaxScalarX,
        scalarY: this.parallaxScalarY,
        frictionX: device.type == "desktop" ? 0.08 : 0.15,
        frictionY: device.type == "desktop" ? 0.08 : 0.15,
        selector: ".parallax-item",
        limitY: device.type == "desktop" ? false : 10,
      };
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.viewShow();
      }
    },
    leavingView(val) {
      if (val) {
        this.viewLeave();
      }
    },
    menuOpen(val) {
      if (!val && this.leavingView) {
        return;
      } else {
        this.enableParallax(val);
      }
    },
    modalOpen(val) {
      this.enableParallax(val);
    },
  },
  methods: {
    showItems(entering = true, i = 0) {
      if (i == 0) {
        console.log("%c%s", "color: red;", "show items");
      }
      if (this.itemsToShow.length == 0) return;

      const vm = this;
      const items = this.itemsToShow;

      if (!entering) {
        this.bodyEl.classList.remove("items-shown");
      }

      if (i <= items.length - 1) {
        const item = items[i];
        const itemDelay = i == 0 ? 0 : items[i - 1][1];
        let delay = entering ? itemDelay : 0;

        setTimeout(function () {
          vm[item[0]] = !vm[item[0]];

          vm.showItems(entering, i + 1);
        }, delay);
      } else if (entering && i == items.length) {
        let delay = items[i - 1][1];

        //run parallax after final delay
        setTimeout(function () {
          vm.bodyEl.classList.add("items-shown");
          vm.createParallax();
        }, delay);
      }
    },
    goToTop() {
      this.bodyEl.scrollTop = 0;
    },
    viewShow() {
      this.goToTop();
      this.showItems();
    },
    viewLeave() {
      this.enableParallax(true);
      this.showItems(false);
    },
    createParallax() {
      if (this.disableParallax) return;

      if (this.doParallax) {
        console.log("%c%s", "color: red;", "do parallax");
        const vm = this;
        this.parallax.parallax = new this.$parallax(this.$refs.scene, {
          ...vm.parallaxOptions,
        });
        vm.parallaxActive = true;
      }
    },
    enableParallax(val) {
      if (this.disableParallax) return;

      if (!this.parallax.parallax) return;

      val
        ? console.log("%c%s", "color: red;", "disable parallax")
        : console.log("%c%s", "color: red;", "enable parallax");
      val ? this.parallax.parallax.disable() : this.parallax.parallax.enable();

      this.parallaxActive = val ? false : true;
    },
  },
  mounted() {
    //trigger show if already set to true
    console.log("%c%s", "color: red;", "show is");
    console.log(this.show);
    if (this.show) {
      this.viewShow();
    }
  },
  created() {
    if (this.debugTimeline) {
      window.addEventListener("keydown", (e) => {
        if (e.key == "e") {
          if (!this.tempTimelineButtonFlag) {
            this.enableParallax(true);
          }
          this.showItems(this.tempTimelineButtonFlag);

          this.tempTimelineButtonFlag = !this.tempTimelineButtonFlag;
        }
      });
    }
  },
};

//chapter part
export const partComponent = {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    timescale: {
      type: Number,
      default: 1,
    },
    timescaleReverse: {
      type: Number,
      default: 1.5,
    },
  },
  data() {
    return {
      timeline: null,
      timelineReverse: null,
    };
  },
  watch: {
    show(val) {
      if (this.timeline) {
        if (val) {
          this.timeline.play().timeScale(this.timescale);
        } else {
          if (this.timelineReverse) {
            console.log("%c%s", "color: red;", "timelineReverse play");
            this.timelineReverse.play().timeScale(this.timescaleReverse);
          } else {
            this.timeline.reverse().timeScale(this.timescaleReverse);
          }
        }
      }
    },
  },
  methods: {
    createTimeline() {
      return;
    },
  },
  mounted() {
    this.createTimeline();
  },
  beforeDestroy() {
    if (this.timeline) {
      this.timeline.kill();
    }
  },
};
