<template>
  <svg
    version="1.1"
    id="svg-africa"
    x="0px"
    y="0px"
    viewBox="-180 272 250 250"
    style="enable-background: new -180 272 250 250"
    xml:space="preserve"
  >
    <path
      id="africa"
      style="fill: #eeeeee"
      d="M0.6,297.8l-0.6,5l-0.8,0.3l-2.6-2.2l-0.9-1.8l-2.2-3l-0.3,1l0.8,1.6l0.5,1.7l2.6,2.1l0.3,1.7
	l2.9,4.6c0,0,1.6,4.2,2.2,4.2c0.6,0,0.9,2.2,1.6,2.1s2,1.8,2,1.8L5.3,317l1,2.8l4.2,3.8c0,0-1.2,0.8,0.1,1.2
	c1.3,0.4,1.7,4.7,1.7,4.7s0.1,5.4,1.3,5.1c1.2-0.3,0.6,1.6,2.6,1.7c2,0.1,3.7,7.2,3.7,7.2l0.5,0.8l1.3,2.4l0.6,0.1l0.5-0.4l1,1.8
	l0.6-0.6l1.6,1.6l0.8-0.3l0.9,0.8l2.1,2l1.8,1.3l0.1,1.1h1.1l2.1,1.8v2.1l-1.9,1.8l1.9,0.5l4.2,3.5h2.4l2-1.7c0,0,1,0.1,2,0.1
	s3.2-1.3,3.2-1.3l3.6-0.3l0.6-0.8l1.6-0.5l1.3-0.5l2.2,0.1l2.9-1.3h1v1.3v0.7v1v2.2l-0.9,0.9c0,0,0.7,4.2,0.1,4.1
	c-0.6-0.1-1.4,2.5-1.4,2.5c-1.2,0.3-4.3,10.8-4.3,10.8c-0.6,0-3.8,5.5-3.8,5.5l-4.1,3.6v1l-4.6,3.6l-4.7,4.6l-8,8.4
	c-0.8-0.1-2.1,2.2-2.1,2.2l-2,1.7c-0.8,2.1-3.5,6.6-3.5,6.6l-1.4,3.2c-1.2,2,0.5,3,0.5,3l1.6,2.5l-0.9,1.6l0.3,2l-0.6,0.5l0.8,0.9
	l1.4,4.1l0.1,1.3l1.8,1.7v2.1l-1.4,2.2l0.7,4l-0.8,0.4l0.8,0.8l-0.6,2.8l0.6,2.5l-1,1.8l0.5,1.4l-1.7,0.9c0,0-1.6,1-1.4,1.4
	c0.1,0.4-1.3,1.4-1.3,1.4l-2,1.8c0,0-1.2-1-2.2-0.1s-4.9,2.1-4.9,2.1v1.2l-0.7,0.2v0.6l-2,1.7v-1l-1.7,1.8L4.2,472
	c-1,1,0.1,2.6,0.1,2.6l0.6,2.5c-1.2,2.1,0.7,2.4,0.7,2.4L5,484.1c-0.6,1.4,0.6,1.8,0.6,1.8l-3.3,2.9l-6.6,2.6l-0.5,2l1-0.3l-0.1,0.4
	l-0.6,2.1l-2.4,6.2L-25.7,519h-3.4l0.4,1.1c0,0-2.2-0.2-2.9,0.2c-0.6,0.4-1.6-0.3-1.6-0.3s-3.6,0.4-4.5-0.1s-3.2,0.6-3.2,0.6
	l-4.1,0.1l-0.9,1.2c-2.5,0.3-2-0.6-3-0.6c-1.1,0-2.6-2.6-2.6-2.6l-1.4-2.5l0.2-1.5h1l-0.4-3l-5-11.1l-4.6-4.5l-0.5-3.3l-0.6-2.4
	l0.1-3.2l-1.2-1.8l-0.4-6.4l-8.3-15l-0.8-2.5c0,0,0.4-2,0-2.6c-0.4-0.7,0.6-3.3,0.6-3.3s2.2-6.8,2.2-7.4s0.8-0.1,0.9-1.6
	s2.6-2.9,2.6-2.9l0.5-4c0,0,1.3-2,0.1-2.2c-1.2-0.3-2.2-4.7-2.2-4.7l0.9-0.9l-0.6-3.8l-2.6-4.1l-0.1-2.7h-1.2c0,0-0.1-2.4-0.3-2.8
	c-0.1-0.4-9.8-10.9-9.8-10.9l-0.4-2l-0.8-1.6l1.4-1.4l0.2-1.5h1.6l-0.3-1c-1.2,0.1-0.8-1-0.8-1l0.8-1.6h-0.9l-0.5-0.6l1.4-2.6
	l-0.1-2.4l0.6-0.6v-1.5l-1-1.2l0.5-0.9H-82c-1.4-0.3-1.3-1.9-1.8-1.5s-1-1.3-1-1.3c-0.8-1.7-1.2,0.8-1.2,0.8l-2.4-0.3
	c-0.3-0.6-2.2,0.5-2.2,0.5c-3.7,1.5-5.1-3.2-5.1-3.2c-0.3-1.4-3-3-3-3l-11.2,0.4l-0.6,1l-3.6,0.6c-1.8-0.1-7,2.9-7,2.9l-4.2-1.6
	c-2.5-0.6-10.1,2-10.1,2c-6.3,2.6-9.7-1.4-9.7-1.4l-8.4-6.8l-2.9-1.7c-0.8-1.3-2-2.1-2.1-2.5s-1.4-1.8-1.4-1.8c0.5-2.1-1.6-5-1.6-5
	l-2.1-1.7l0.2-1.9h-1l-1-0.8l-1-1.2l-0.6-1.6h-1.4h-0.6l-0.8-1.7h-1.1v-3.4l1.2-1.7l-1.4-3.2c-1,0.1-0.7-1.6-0.7-1.6l2.6-2.5v-2
	l1-2.1l0.9-1.2l0.8-1.8l0.1-5.6l-0.6-1l0.8-1.7l0.3-2.5h-1l-0.4-2h-0.8c-0.3-1.7,3.3-6,3.3-6l0.9-1.6l5.4-6.6
	c-0.8-2.1,2.5-4.3,2.5-4.3c1-0.3,2.1-1.3,3.3-2.6c1.2-1.3,1.4-2.4,2.5-2.5c0.2,0,0.3,0,0.5-0.1c1.2-0.2,3.3-0.5,4.2-1.3
	c1-0.9,2.8-2.1,2.8-2.1c0.1-0.1,0.2-0.2,0.4-0.2c4.4-3,2.5-5.3,2.5-5.3c0.6-2.8,2.1-4.7,2.1-4.7s0.9,0.1,0.9-1.3
	c0.1-2.4,8.8-5.5,8.8-5.5c2.1-5.4,4.9-6.9,5.3-5.5s1.9,2,1.9,2l4.9,0.3l1.3,1l2.8-0.1l1.6-2.1l3.4-0.1c-0.4-1.2,3.3-2.1,3.3-2.1
	l3.7,0.1l0.8-0.5l2.6-0.3l6.4-0.1c0,0,2.4-0.9,3.2-0.4c0.8,0.5,1.8,0.6,2,0c0.1-0.6,3.8,0.5,3.8,0.5l1.4-1.1h2.5
	c0.6-0.3,1.4,1.7,1.4,1.7c0.9-0.8,1.7-0.8,1.7-0.8c0.9,0.5-1,2-1,2c-1.4,0.4,0.9,3.2,0.9,3.2c1.3,0.9-3.6,4.9-3.6,4.9
	c-0.4,1.6,1.7,1.4,1.7,1.4c-0.1-1.3,1.6-0.5,1.6-0.5c-0.3,2,0.6,2.5,0.6,2.5c0.3,0.9,5.1,0.8,5.1,0.8c1.2,0.1,7.4,2.4,7.4,2.4
	c-0.1,3.4,6.4,4.9,6.4,4.9c1.5-0.3,4.5,1.7,4.5,1.7c3.8,0.6,3.8-3.6,3.8-3.6c-2.2-3.2,3.4-5,3.4-5c1.4-1.2,5.9,0.6,5.6,0.8
	c-0.3,0.1,0.8,1.6,0.8,1.6h4.5c1.4,0.7,2,2,2,2c2.6-1.3,12,1.7,12,1.7s3.6-1.3,3.6-2.2s2.6,0.5,2.6-0.2c0-1,2.9,0,2.9,0
	c1,1.7,6.6,0.4,6.6,0.4C-0.7,292.5,0.6,297.8,0.6,297.8z M54.3,446.8l-0.9,0.5l-0.6,2.5l-0.7,0.8l-0.5,0.8H50v2h-1v1.8l-0.7,0.6v-1
	l-1.4,1v2.2h-1v-1.2l-1.8,1.2l0.6,1.3h-1.2v-0.8l-2.4,0.2v0.8l-1.2-0.7l-1.6,0.7l-1.3,0.7v1.6l-2,2.3l0.5,1.8l0.6,6.3l-0.3,0.8
	l-2.2,4l-2.1,1.4l-0.6,2.2v3v1.3l1.1,1.2l-0.4,1v3.5l3.4,2.9h1.8c0,0,1.6-1.6,2.2-0.9l2.6-0.5L52,471.5l1.4-6.6l1.7-2.8v-3.8l1,0.3
	l0.6,1.2l0.6-1l-0.5-1.7l0.5-3.7l-0.8-0.5l-0.3-4.6C56.6,448.5,54.4,445.4,54.3,446.8z M-90.8,400.2h-1v1h1V400.2z M-88.5,394.8h-1
	v1h1V394.8z"
    ></path>
    <path
      id="spinner"
      style="fill: #3798fb"
      class="hidden"
      d="M-149,397.1c0,51.9,42.1,94,94,94s94-42.1,94-94c0,54.5-42.1,98.7-94,98.7S-149,451.6-149,397.1"
    />
    <path
      id="spinner2"
      style="fill: #3798fb"
      class="hidden"
      d="M-54.5,491c51.9,0,94-42.1,94-94s-42.1-94-94-94c54.5,0,98.7,42.1,98.7,94S0,491-54.5,491"
    />
    <path
      id="spinner3"
      style="fill: #3798fb"
      class="hidden"
      d="M39,397.5c0-51.9-42.1-94-94-94s-94,42.1-94,94c0-54.5,42.1-98.7,94-98.7S39,343,39,397.5"
    />
  </svg>
</template>

<script>
export default {
  name: "SvgAfrica",
};
</script>

<style scoped>
#svg-africa {
  fill: #eeeeee;
}
</style>
