import Vue from "vue";
import Router from "vue-router";

//views
import ViewHome from "./views/home/Home.vue";

//other

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "view-home",
      component: ViewHome,
    },
    {
      path: "/enquire/",
      name: "view-enquire",
      pathToRegexpOptions: { strict: true },
      component: () =>
        import(/* webpackChunkName: "enquire" */ "./views/enquire/Enquire.vue"),
    },
    {
      path: "/chapters/",
      component: () =>
        import(
          /* webpackChunkName: "slider" */ "./views/chapters/Chapters.vue"
        ),
      pathToRegexpOptions: { strict: true },
      children: [
        {
          path: "",
          redirect: "1-new-systems-for-living/",
        },
        {
          path: "/",
          redirect: "1-new-systems-for-living/",
        },
        {
          path: ":slide/",
          name: "view-chapter-slide",
          pathToRegexpOptions: { strict: true },
          component: () =>
            import(
              /* webpackChunkName: "slide" */ "./views/chapters/ChapterSlide.vue"
            ),
        },
      ],
    },
    {
      path: "/chapters/:slide/",
      component: () =>
        import(
          /* webpackChunkName: "chapter" */ "./views/chapters/Chapter.vue"
        ),
      children: [
        {
          path: "/",
          redirect: "1",
        },
        {
          path: ":section",
          name: "view-chapter-section",
        },
      ],
    },
    // {
    //   path: "/learning-journeys",
    //   name: "view-journeys",
    //   component: () =>
    //     import(/* webpackChunkName: "journeys" */ "./views/journeys/Journeys.vue")
    // },
    {
      path: "/404/",
      name: "view-404",
      pathToRegexpOptions: { strict: true },
      component: () =>
        import(/* webpackChunkName: "view404" */ "./views/404/404.vue"),
    },
    {
      path: "*",
      redirect: "/404/",
    },
  ],
});

export default router;
