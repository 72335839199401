<template>
  <div class="media-wrap">
    <div class="video-container video-container-vimeo">
      <transition name="video-fade">
        <iframe
          ref="video"
          :src="`https://player.vimeo.com/video/${data.id}?byline=0&color=3798fb&portrait=0&title=0`"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
          v-show="videoLoaded"
        ></iframe>
      </transition>
    </div>
    <media-caption v-if="data.description || info">
      <template v-slot:desc v-if="data.description">
        {{ data.description }}
      </template>
      <template v-slot:sub v-if="info">
        <span v-html="info"></span>
      </template>
    </media-caption>
  </div>
</template>

<script>
import MediaCaption from "@/components/ui/MediaCaption.vue";

export default {
  name: "VideoVimeo",
  props: ["data"],
  data() {
    return {
      player: null,
      videoLoaded: false,
    };
  },
  computed: {
    info() {
      if (this.data.title || this.data.photographer) {
        let info = "video: ";

        info = this.data.title ? info + this.data.title : info;
        info = this.data.photographer
          ? info + " by " + this.data.photographer
          : info;

        return info;
      }
      return false;
    },
  },
  components: {
    MediaCaption,
  },
  methods: {
    onVideoLoad() {
      console.log("video loaded");
      console.log(this.player);

      this.videoLoaded = true;
    },
  },
  setLink() {
    if (this.data.title && this.data.link) {
      this.data.title = `<a href='${this.data.link}' target='_blank'>${this.data.title}</a>`;
    }
  },
  created() {
    this.videoLoaded = false;
  },
  mounted() {
    this.player = new Vimeo.Player(this.$refs.video);

    this.player.on("loaded", this.onVideoLoad);
  },
};
</script>

<style scoped></style>
