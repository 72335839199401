export const timelineIntro = {
  methods: {
    createIntroTimeline() {
      const {
        loadingScreen,
        introScene,
        introBackground,
        introText,
        introName,
        mandelaStamp,
        mandelaSig,
        skipWrap,
        skipButton,
        mandela,
      } = this.$refs;

      let introTextSentences = [];
      introText.childNodes.forEach(function (el, i) {
        introTextSentences[i] = new SplitText(el, {
          type: "words",
        });
      });

      let sentenceStaggerDelay = "-=0.1";
      let sentenceStaggerDuration = 1.2;
      let wordStaggerDelay = 0.1;

      //intro out
      const tlIntroOut = new TimelineMax({
        paused: true,
        delay: 0.5,
      });

      tlIntroOut
        .to(
          skipButton,
          1.5,
          {
            autoAlpha: 0,
          },
          0
        )
        .staggerTo(
          [
            introTextSentences[0].words,
            introTextSentences[1].words,
            introTextSentences[2].words,
            introTextSentences[3].words,
          ],
          1.5,
          {
            transformOrigin: "top center",
            y: "-=20",
            autoAlpha: 0,
            rotationX: -25,
            ease: Power1.easeInOut,
          },
          0.3
        )
        .to(
          [introName, mandelaSig, mandelaStamp],
          1.5,
          {
            transformOrigin: "top center",
            y: "-=20",
            autoAlpha: 0,
            rotationX: -25,
            ease: Power1.easeInOut,
          },
          "-=.6"
        )
        .to(
          introScene,
          3.5,
          {
            autoAlpha: 0,
            ease: Power1.easeOut,
          },
          "-=0.5"
        )
        .set(loadingScreen, {
          left: "-100%",
        })
        .call(this.clearIntro, null, this);

      this.timelines.intro.out = tlIntroOut;

      const tlIntroIn = new TimelineMax({
        paused: true,
        delay: 0.5,
      });

      tlIntroIn
        .set(
          skipWrap,
          {
            autoAlpha: 1,
          },
          "introInStart+=1"
        )
        .set(loadingScreen, {
          left: 0,
        })
        .to(introScene, 0.4, {
          autoAlpha: 1,
        })
        .add("introInStart")
        .from(
          introBackground,
          4,
          {
            autoAlpha: 0,
            ease: Power1.easeOut,
            x: "-=10",
          },
          "introInStart"
        )
        .from(
          introBackground,
          5.6,
          {
            scale: 1.01,
            rotationX: -25,
            ease: Power1.easeOut,
          },
          "introInStart"
        )
        .staggerFrom(
          introTextSentences[0].words,
          sentenceStaggerDuration,
          {
            autoAlpha: 0,
            rotationX: -25,
            y: "+=20",
            transformOrigin: "bottom center",
            ease: Power1.easeInOut,
          },
          wordStaggerDelay,
          "introInStart+=2"
        )
        .add("firstSentenceIn")
        .staggerFrom(
          introTextSentences[1].words,
          sentenceStaggerDuration,
          {
            autoAlpha: 0,
            rotationX: -25,
            y: "+=20",
            transformOrigin: "bottom center",
            ease: Power1.easeInOut,
          },
          wordStaggerDelay,
          "firstSentenceIn-=1"
        )
        .staggerFrom(
          introTextSentences[2].words,
          sentenceStaggerDuration,
          {
            autoAlpha: 0,
            rotationX: -25,
            y: "+=20",
            transformOrigin: "bottom center",
            ease: Power1.easeInOut,
          },
          wordStaggerDelay,
          sentenceStaggerDelay
        )
        .staggerFrom(
          introTextSentences[3].words,
          sentenceStaggerDuration,
          {
            autoAlpha: 0,
            rotationX: -25,
            y: "+=20",
            transformOrigin: "bottom center",
            ease: Power1.easeInOut,
          },
          wordStaggerDelay,
          sentenceStaggerDelay
        )
        .add("mandelaStart", "-=8.2")
        .call(this.startMandelaTimeline, null, this, "mandelaStart")
        .from(
          [introName, mandelaSig],
          1.7,
          {
            autoAlpha: 0,
            rotationX: -25,
            y: "+=15",
            transformOrigin: "bottom center",
            ease: Power1.easeInOut,
          },
          "mandelaStart+=7.8"
        )
        .from(
          mandelaStamp,
          0.85,
          {
            autoAlpha: 0,
            rotationX: -25,
            y: "+=15",
            scale: 1.4,
            ease: Power2.easeIn,
          },
          "mandelaStart+=8.3"
        )
        .call(this.playIntroLeave, null, this, "+=1");

      this.timelines.intro.in = tlIntroIn;
    },
  },
};
