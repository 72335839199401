<template>
  <div class="media-wrap">
    <div class="video-container-native">
      <transition name="video-fade">
        <video controls :poster="data.poster">
          <source :src="data.src" type="video/mp4" />
        </video>
      </transition>
    </div>
    <media-caption v-if="data.description || info">
      <template v-slot:desc v-if="data.description">
        {{ data.description }}
      </template>
      <template v-slot:sub v-if="info">
        <span v-html="info"></span>
      </template>
    </media-caption>
  </div>
</template>

<script>
import MediaCaption from "@/components/ui/MediaCaption.vue";

export default {
  name: "VideoNative",
  props: ["data"],
  data() {
    return {
      videoLoaded: false,
    };
  },
  computed: {
    info() {
      if (this.data.title || this.data.photographer) {
        let info = "video: ";

        this.setLink();

        info = this.data.title ? info + this.data.title : info;
        info = this.data.photographer
          ? info + " by " + this.data.photographer
          : info;

        return info;
      }
      return false;
    },
  },
  components: {
    MediaCaption,
  },
  methods: {
    setLink() {
      if (this.data.title && this.data.link) {
        this.data.title = `<a href='${this.data.link}' target='_blank'>${this.data.title}</a>`;
      }
    },
  },
  created() {},
  mounted() {
    const vm = this;
    setTimeout(function () {
      vm.videoLoaded = true;
    }, 500);
  },
};
</script>

<style scoped>
.video-container-native {
  max-width: 100%;
  display: block;
  background: var(--grey);
}
.video-container-native video {
  max-width: 100%;
  display: block;
}
</style>
