<template>
  <div id="layer-mandela" class="layer">
    <svg
      version="1.1"
      id="svg-mandela"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 338 458"
      style="enable-background: new 0 0 338 458"
      xml:space="preserve"
    >
      <path
        id="path-mandela"
        d="M79.8,368.1c-2.9,1.5-6.6-4.9-4.9-10.3c-8.1-1.8-2.4-3.6,2.7-1.4c-2.2,7.6,2.1,4.6,4.7,12.4
		c2.1,10.5,7.9-6.3,3.5,0.2c-0.5-4.3-0.7-6.7-3-9.4c-2.5-1.7,6.8,0.4-3.1-4c-3-2.1-4.4-0.3-5.1-4.2c-5.6,6.2-5.8-6.3-8.8-2.5
		c-1.9-14.4-3.8-3.3-2.3-3.6c-1.7-6.7-2.6-6.2-2.9-8.9c-0.9-9.3-5.5-4.8-4.1-9.6c-4.9-5.9-0.2-8.7-5.3-11.9c0.4-5.4-2.8-5.5-3-11.3
		c0.8-2.2-6.7-18.1-7.2-7.8c-1.2-1.9,1.6-4.2-0.4-6.8c1.3-4.8-0.1-5.3-1.5-9.5c1.6-8.8-1-13.4-0.7-19.6c-4.1-4.3-7.1-10.7-4.9-19.7
		c1.8-2.7-0.9-9.8,3.8-11.8c-0.8-0.2-7.2-1.7-1.7-3.3c1.9,1.3-4.1-6.5-4.4-14.5c-5.5,0-7.4-1.1-8.9,4.1c1.4-11.6-1.9-8.5-3.5-1.2
		c-5.6,4.7-1.1,20.7-1.1,25.9c2.8,10.9,6.4,11.2,5.6,20.5c-2.7-0.2-8.6-11.8-6-11.4c-0.2-8-4.2-14.3-4.3-23.6
		c1.8-1.6-3.6-5.2,1.1-10.8c2.2-5.6,9.7-7.8,0.2-11.8c-10.3,10.7,0.1-3.4-7-6.9c-1.6,3.5-3.6-7.5-0.5-4.8c1.2,0.5,2.6-9.3,1.6-9.1
		c4.2-10-1.1,0.8-3-8.9c6.6-3.2,1-5.5,4.8-7.1c-5.4-3.2-2.4-3.6,0.3-6.5c3.9,1.4,9.1,9.4,4,10.8c1.7,12.5,1.1-4,6.1,4.5
		c4.8,2-3.1,10.6,3.7,11.3c2.8-7.6,3.2,2.5-1.6,3.7c6.9,1-8,3.7,0.3,3.7c3.5,10.6,3.8-8.8,7.2,0.3c3.2,5.2,4.7-8,4.4,2.4
		c-9.7,4.3,2.8,1.7,2,0.5c3.2-4.5,0-5.8,6.9-1.8c1-4.7-3.9-3.6,0.2-8.4c-8.6,3.8-7.1-2.3-3.8-1.8c0,1.6,8.8,0.2,3.6-2.7
		c1.4-5.4-1.3-5.3,1.8-7.4c-4.7-8.7,6.8,5.2,4.8,3c-3.9,5,11.1,2.2,3.4-6.6c-1.1-3.9-0.2-4.6-4.4-7.4c-0.1-1.7,4.1-3.2,2.8-3.7
		c-5.9-0.2,2.2-15.3-1-10.3c0.9,0.6,1.8-7.4,4.4-3.2c-3,5.4,8.8-8.4,2.5-5.2c-3.1-4.4-1-2.3-4.3,2.9c1.8-4.5-0.7-2.5-3.1-5.2
		c-1.4,0.6,1.3-5.2,2-3.8c2.3,4.2,8.5-9.7,0.2-4.5c0.7-4-4.7-4,3.4-5.9c-4.2-5.1-1.9-4.3,0.3-7.1c-1.2-6.1-1.6-3.8,4.9-3.2
		c1.9-9.3-5.9-1.5-3.6-7.5c1.5-7.8-7.1,2.9-3.8-6.7c6.9,4.3,6.7,11.4,9.5,1.9c-8.2-5-0.5-0.4,2.4-6.8c1.6,6.9-3,6.7,3.1,3.2
		c-1.5,4.4-4.6,3.9-0.2,8.4c-3.7-8.4,11.1-9.5,3.4-8.7c0.5,4,12.8-1.7,6.3-1.2c-11.1-2-2.3-0.4-1.7-5c3.7,3.6,3.7,5.9,8.7-0.7
		c-11,3-4-5.9,2.7,0.9c-4.7,5.4,4.8,0.4-0.4,7c2.2-4.1,4.3,0.3,4.4,1.4c9.4-5.8-1.9-4.5,4.3-8.5c5.7,5.1-2.5,11.9-3.9,10.9
		c8.2,1.7-13.9,1.8-6.8,1.4c8.2,1.2,5.3,1.6,11.5,1.9c0-10.8,8.1,0.4,2.1-2.9c-2.5,3.7-1.3,3.7,0.4,7.8c-2.8-0.2-12.2-6.6-7.2-0.2
		c12.7,4.6-12.7,7.3-9,10.1c3.4,4.5-1.6-4.6,6.6,0.5c-11.5-4.4,12.3-4.7,2.4-2.1c-3.6,2.7-0.5,6.8-9,6.8c0,2.5-2.2-6.6-2.5-0.6
		c-9.4,2.5-3.1,5.6-1.3,4.3c5.4,0.8-8.3,9.1-5,10.8c-3.5,1.6,6.5,1.9-1.3,5.3c-3.6,10.9,3.4-0.9,1.8,3.3c-9.6,10,5.9-3.1,5.3,3.8
		c-6.6,7.1-8.2-1.8-8.6,4.4c6,5.3,8-6.9,10.8-5.3c-2.5-5.6,0.1-7-1.7-9.5c0-2.9,7.2,1.8,3-5.4c-5.9-0.6,9.1-2,0.6-3.5
		c0.5-7.9,2.3,4.2,2.9-4.8c-5.3-4.3,8.4-1.3,4,0.9c9.3-0.3-3.9,1.3-2.2,2.1c1.7,5.2-3.1,11.6-1.6,9.9c0.2,4.3-5.6,3.6-4.9,6.5
		c6,5.2-7.1,10.5-6,12c-6.6-2.3-5.4,2.3-9,8c-1.9-0.5-3.2,3,3.7,3.2c-1.6,5.8-0.8,6.3-3.8,1.7c2.3-6-7.8,6-4,6.3
		c1,5.2-5-1.8-2.2,3.7c3.4,3,2.8,3.7,2.1,6.6c3.8,6.1,2.9,7.4,5.8,1.4c6.1-2.7,2.1-3.7,1.2,3c2.2-2.3,3.4,1.6,5.3-4.2
		c-2.5,13.2,1.4-4.5,4.5,4.8c-0.5,3.9,5.1-7.4-0.4,6.6c-4.7,1.5-2.1,0.7,0.2,2.4c-1,2.5-6.3,7.3-10.9,7.1c-3.3-1.5,3.9,5.7-1.7,3.1
		c-11.4,0.6,0.2,8.1-7.1,5.2c-3.2,3.5,12.5,9.3,9.4-0.4c-9.8-2.2,4-2.4,2.7,1.1c6.3-0.5-1.9-5.5,3.4-5.6c8.7,5.2,4.5-2.1,11.6,1.3
		c5.7-2.9,14.4-3.3,16.5-7.8c1.8-2.2,7.7,0.1,6.6-1.3c7.7-0.7,7,1.6,12,1.8c5.9,5.8,17.4,2,15.5,8.9c0.7,4.8-6.9,7.9-3.7,2.6
		c-10-6.5-7,0.9-11.4-0.4c-7.6-0.4,4.6,9.5,2.3,3.2c7.7,4.3-1.4,7.6-1.1,6.3c-7,4.5-6.6,5.6-12.3,1.8c6.1,1.5-4,4.7-5.3-0.4
		c8.8-1-2-5.5,4.7-6.4c2.6,4.6,9.3-11.5,0.7-3.6c-11,1.3-13.5-5-17.8,0.9c-13.3-1.2-9.4,2.7-19.5,3c-10.7,3.4-25.5-3.5-23.7,8.1
		c1.9-0.1,9.1-2,1.4,3.8c-6.2,4.8,1.7,2.1-0.9,6.6c-7.3,8.3,12.7,2.4,2,6.2c2.6,3.5-5.6,8.3,1.7,3.2c-0.7-10-0.1-0.6,2,0
		c2.8,5.6-2.4,0.7-4.1,7.1c-8.6-3.9-1.6,4.4-1.2,1.4c1.1,0.7-1.1,11.6-2.7,8.4c-0.2,8.5,7.6-7.5,3.2,3.5c-0.2,2.7-1.4,2.9,2,5.4
		c3.6,8,6,8.2,10.2,5.8c-1.6,4.3-8.8,5.7-1.2,9.2c0.9,2.9,3.5,0.2,6,1.8c-0.9,4-3.3,5.8,0.8,6.3c-3.2,6.3,2.4-1.7,3.5,5.8
		c8.2,0.7,1.6,4.2-3.6-0.8c6,1.7,1.8,7.3,11.9,6c2.6-3-0.4-2.6-0.1-6.2c-1.4-1.8,9.6-7,3.1,2.3c3.7-3.2,2.9-7.4,7-9.5
		c3.6,8.6-1.8-1.7,4.4-5.5c2.5,7.7,5.9-3.3,4.5-0.3c0.8-4.5-1.8-8.9,2.4-5.8c3.2,6.1,5.6-12,6.8-5.4c-1.7,5.6-6.3,12.2-9.6,20
		c-3.8,4.3-14,12.9-12.1,21.4c-3.5,4.7,6.8,22.3-0.9,14.3c-0.6-1.3-0.9-5.6-3.6-12.8c0.9-6.6-9.8,1.6-8.5,0.3
		c3.4,1.7,1.5,7.1,4.8,9.7c-0.1,3.3-5,7.5-1.7,8.3c6.5-1.8,0,2.8,8.3,8.3c-4.7,3.5,3.4,17.4,3.6,16.7c2.8,4,1.9,10.1,7.3,11.1
		c2.3,5.8-0.9,4.9,2.6,9.1c1.7,1.5,4,7.6,4.7,4c-3.2-6.8,8.9,8.9,1.6,4.2c0.6,0.8,4.6,10.1,6.9,5.1c2.9,1.1,3.2,6.8,5.2,9.2
		c7.2-10.6-3,12.9,3.6,2.1c-2.3-10.5,3.8-8.4,7.6-5.3c-1.8,0.2,8.1,7.5,10,7.6c2.7,7.1,3.3,1.6,1.8-3.4c4.7,2.2,2.7,5.8,7.5,6.5
		c-3.2,1.7-5.2,4.3-3.5,3.4c1.7,6.7,9.1,0.4,10.6,3.5c4.6-11.4-3.3,10,4.1,0.5c1.9,4.7-2.7-6.4,3.8,3c-2.5-10.2,4-0.5,5.7-4.9
		c14.5,1.7-1.6-2.4,7-1.7c3.3,0.1-4.5-4.3,6.2-2.6c4.1,4-8.1-4.5,1.7-4.5c5.8-1.1,2.1-4.2,8.3-3.8c-11.1,2,4.5-10.6,2-6.3
		c-0.4-6.9-2.8,0.2-5.2-1.4c-0.1,3.2-5-4.5-3.6-2.7c3.7-1.5,5.6-0.1,11,0.3c6.3,4.9-7.5-3.1,1.6-7c4.6,1.7,6.6,0.4,0.7-2.1
		c-10.9-0.1,5.7-4.1-2.7-4.1c3.8-1.4,4.2-8.1,5.8-6.2c0.2,5.3,10.5-3.4,6.8-4.9c4.8-8.2-2.6,0.8-4.5,5.2c0.8-6.6-5.5-0.2-6.7,0.1
		c-4.3,8.2-5.2-10.6-7.8-6.9c-4.9,4-4.4-2.7-7.3,3.3c0.2-4.1-14.2,1.7-5.7-4.5c-4.2,0-5.3,0.4-10.7,3.1c1.6-1.6,5-5.5-1.9-4.8
		c5.4-6.1-7.7-3.2-2-1.4c8.8-0.9-17.6-1.8-12.2-3.1c0.3-7.4,6.3,2,9.5-1c4.5-2.6,3.8-3.8,8.4-2.4c2.6-0.3,6.9-3.1,8.7,0.3
		c7.7-1.4-2.5-6.7,6.4-9.2c3.9,0.4,6.8-3.5,4.7-6.7c-3.6-5.6,6.6-5.6,0.1-6.8c-3.1-0.2,3.9-3.6,6-2.9c6.2-0.8-2-2.1,0.1-5.6
		c-8.7,0.2-20.2-3.3-27.9,0.7c-7.8-2.9-18.7-4.3-25.9-4.6c-2.6,3.9-22.5,1.2-13.9,4.4c-1.1,3.6,5.7,5.2,10.4,6.5
		c-2.3,0,13.8,4,5.7,4.9c-4.5,3.7,4.6,16.3-4.2,7.8c-0.6,4.3-10.4-10.7-2.5,0.9c-6.3,2.3-1-4.8-10.4-3.2c2.2-2.6,0.6-2.5,2.4-4.5
		c-0.8-7.1-3.7,5.8-8,0.2c-3.7-7.3-5.5-5.6-9.7-5.9c6.9-5.2,3-2.7-2.6-3.7c0.5-10-7.5,10.6-10.1-1.2c-1.4,1.8,5.3-1.1,8.2-2.7
		c-0.2-3.8-4.5-14.8,1.5-11.8c-3.3,9.5,2.4-5.9,3-0.8c-2.2,3.8-0.3,6.9,4,5.2c0.2-4.6,2.7,0.7,6-2.1c-4.3,4.9,11.6-3.6,3.5,2.8
		c10.9-3.6,1.7-7.7,9.6-9.6c-3.8,7.1,5.5,6.9,7.9,5.6c11.1-6.3,23.7,10.1,33.3-1.8c0.2,2.8,12.2-3,11.1-2c2.3-3.5,2.4-8.1,4-1.9
		c-5,0,8,6.7,2.8-0.5c0,1.5,4,0.4,0.4-2.3c4.8-6.9-8.6-11-8.3-9.6c0.3-3.2,0.1-7.6-2.6-7.3c-9.4,2.8-24.3-6.3-28.9-1.6
		c-8.1-3.9-0.1,5.5-6.9-1.7c2.2,3.4-8.1-0.9,0.6-1.3c-7.7,1-18.1,3.5-16.4-1.9c-0.9-3.5,7.2-3.3,11.2-3c4.8-1.7,7.8-4,7-3.1
		c2.9-0.9,8.8-5,6.9-0.1c-1,3.7,11.2-2.5,7.5-0.9c-5.6-2.7,11.4,3.4,7.6-3.4c3.6-2.7,6.7,0.2,7.4-3.3c7,3.1-5-6.2,4.4-6.7
		c-6.9-3.9,5.7-7-2.7-6.2c0.8-2-1.4-7.9-1.2-6.4c4.5-3.1-5.5-1.2-1.4-8c1.2-1.6-4.1-5.6-0.1-7.5c-3.1-2.6,1.1-8.2-5.4-8.9
		c-1.7-2.3,4.1-6.4,4.8-3.1c5.3-2.2-13.7-5.6-3.6-5.7c8.2,1.9-0.3-3.9,3.6-4c-7.9-0.4,3.9-10-1.6-7.2c3.5-5.1,4.5-1.8-0.6-3.1
		c4.1-5.6,3.8-8.9,5.4-2.3c5.8-5.4-6.8-8.5,0.5-9c-1.1-5.3,7,3,6.4-6.5c-1.6-1.3,2.8-5.4,9-3.5c3.6,5,7.4,3.9,5.1-1.3
		c2.4-1.4,3.6,7.9,4.3,7.5c-6.1,6.3-2.4,3-5.6,6c-5.3,1.3,2.2,4.8-7.3,4.4c4.7,5.9,21.6,0.1,31.9,3.9c8.5,3,16.3,3.9,20,7.5
		c11.5,2.1,3.9-2.7-1-3.3c2.8,2,3.2-6.2-0.2-8.6c8.1-3.6-6.8-6.2-5.4-8c-3.1-1.1-6.9,0.4,0.5-5.6c5,3.8,0.1-4.8-1.3-1.9
		c-5.8,4.5-15.6,4.3-22.1,5.6c0.6-7.4-5,1.4-6.4-7.8c-1.6-5.3-0.1,7.1,0.7,6.5c-3.6,2.1-4.3-12.5-0.9-9.3c4.2,3.6,2.3,3.5,6.3,5.5
		c9.2-3.1-1.3-1,0.8-5.9c2.5,1.2,6.8-7.1,6.8-2.2c6,4.3,2.8-4.1,8.4-1.2c6.2-1.3-8.2-5.3-3.4-2c-2.8,3.8-3.9,1.8-6.3-4
		c4.9-3.1,0.7-7,3.8-5.8c8.6,8.8,7.8-3,4.6-3c-4.9,2.8,5.7-4.1-1.3-6.1c3.8,3.7-7.5,3.7-7.2,3.9c-2.7,6.9-2.4-7.6-7.4-4.4
		c-8.7-2.3-0.5-1.5-2-3.8c-3.2-1.3,5.5-0.8,1.6-4.8c6.7,4.7-0.4,7.1,7.5,12.3c-3.5-7.8-1.3-0.9,3.4-8.7c3.7,6.4,9.3,1.5,7.6-2
		c-5.7,0.9-8.3,1.6-12.4,0.5c1.9,6.5-10.9-6.7-0.9-4.3c-8-2.7-4.4,3.4-8.8-0.4c-0.7,0.5-4,6.7-4.9,1c-5.1,7.7,1.4-10.2,6.2-3.9
		c0.5-10,0.7-1.1,6.8-5.6c-6.1,1,2.5-1.9,1.4-3c-1.4-1.6,6.2-2.9,3-5.1c-7.7-6.3-8.3,2-10-4.6c-1.9-13.5-0.3-2.7,3.8-6.8
		c2.3,2.6,0.7,2.5,7.9,1.3c-1.4-3.9-3.5,2.5-8-3.6c0.5,0.8-11.9-1.4-3.8-4c2.7-5.8-0.7,0.5-5.8,0.6c-8.8,7.9,9.4-10.2-2.4-6.1
		c-7-0.8,7.9-2.8-2-3.6c-5-1.7-2.6,2-1.4,4.4c-4.4,1-4-4.6-7.7-7.1c9.4,0.5,9.9,0.5,0.8-1.7c2.4-1-6.7-0.1-6.7-2.7
		c-1.5-4.3,3.8-0.4,0.9-5.1c10.8-1.5-4.8-2.5,2.4-3.4c1.7-1.9,1.3-5.5,5.3-5.7c-2.4,6.8-1.2,1.9-3.1,9.1c0.4,2.3,3.3,0.1,6.2-3.9
		c2.5,5.4-2.5-8.7,5.2,1.6c2.3,7-0.5,1.5,3.5-0.6c9.4,5.3-1.4-3.5,5.8-3.1c3.4-3.3-1.7,7.4,4.4-0.1c8.8-2.8,1.9,5,5.7,9
		c-1.6,4.5,5.9-6.7,9.6-9.6c-5.3,1-6.5,5.9-10.5,7.3c0.2-13.5,14.8-4.7,15-8.8c-9.3-8.9-1.8-2,0.6-2c-2.6-6.9-0.1-8.1,3.3-11.3
		c1.6,0.8-0.6-7-4.7-2.3c-2.7-2-10.2-8.4-0.5-3.5c-2.5-7.7-10.5-0.8-7.8-8.8c-3.6,1.7-1.8-5.4-2.3-6.4c-5.6-6.3-0.5,10.5-4.3,0.6
		c-2.5,0.9-3.4-6,0.2-5.1c6.7,4.8-4.8-7.2-5.4,5.1c-6.5-4.3,7-7.7-3.4-5.5c-4.2-4.2,1.4,5.5-3.6,0.7c-1.2,5.4-2.6-0.3-6.1,2.6
		c-13.9,1.7,3.1-11.5-2.5-4.9c9-0.9-4.1-9.1,1.9-10.1c-6.7,0-10.2,2.5-14.2-3.5c5.5,0.4-1.1-6.2-2.7,2.4c-2.2-1.7-6.3,1.7-2.7-6.2
		c-1-6.8-4.6,13.7-6.8,0c-7.6-0.8-1.7,0.6-1.4,3.7c8,2.2-1.4,8.9,0.1,1.6c-4.3,6.4,2.5,9.7-7.4,10.8c-1.4-2.2-15.9,13.4-5.8,1.7
		c0.3,1.9-2.8-5,0.3-4.2c0.2,5.8,4.9-5.3,3.3-5.1c-1.9-0.1-7,3.1-4.5-1c-5.8-0.2-1.4,6.1-4.1,6.2c2.5,8.3-6.6,13.9-1,11
		c3.9-2.6,3.6,4.9,1,2.6c-4.2,3.3-8.9-0.3-6.2-4.6c-1.4-2.1,9-9.1,0.6-3.6c-5.2-1.5-4.4,14.8-12.4,5.3c-4.2-11.3,2.2,8.9-3.9,4.2
		c3.8-7-4.2-1.7-4-5.7c1.9-7.8-5.4-4.1-4.2-1.1c4.9,5,7.8,4.3,0.3,8.9c-0.8-6.4,0.7,8.3-3.2-0.2c1.6,8.7-2.8,1.1-4.5,3.2
		c5.8,7.9-3.1,3.3,0.2,9c5.1-6.7,5.7,2.6,2.9-0.7c-6.7,4.2,4.7,5.2-1.7,7c3.9,1.6-4.7,15.3,1.9,14.4c-5.4,7.7-6.3,1.1-4.4-3.9
		c5.5-5.4-6.7-7.2,0.4-7.1c2.5,0.5-6.3-11.6-6.2-5.1c1-0.1-1.2,5.8-2.9,1.8c-0.3-6-2,2.6-7.1-0.1c2.9,3.1,18.6,4.5,3.7,5.5
		c-2.5-4.7-5.8-1.6-1,2.1c-1.5,0.8-10-4.4-14.4-5.8c-1.4,5.7,3.4-8.6-2.4-5.3c4.3-5-7.6,2.4-1.5-5.8c-6.6-3.5,1.5,12.1-3.6,3.8
		c-2.4-1.4-7.1,8-2.9,10.8c2.4-4.2,3.7-4.2,3.4,1c7.3-0.7-8.5,0.4-0.4,2c5.6,6.5-5.2,5.3-8.4,4.7c2.1-3.7,8-1.2-1.9-8.1
		c6,9.3-7.6,2.7-8.1,6.6c1.8,8.6-1.5-1.2-4-1.9c-4.9-4.3-10.5,7.3-12-3.3c0.7,1.9-6.6-12.1-1.1-4.5c3.5,6.3,6.6-6.9,9.1-1
		c-6.8,3.5-2.1,6.4,0.5,1.8c2.8,3.5,8.6-3.5,1.3-5.8c-0.6,3.8-6.9-6.6-0.2-2.8c3.4-4,3.7,9.3,10.2,3c0.3-1.1,3.2-0.5,3.5,4.2
		c2.9-3.1,5.3,0.8,4.7-4.7C79,65.7,65.5,73,71.8,67c-5.2,1.9,0.9-0.8-3.3-5.9c-2.6-6.4-4.7-0.9-5.4-3.3c-4.1,0.9,1.5-5.7-4.1-2.5
		c6-6-10.4-1.9-3-4c-2.5-10.7,0.3-0.2,5.3-2.6c-0.6-2-7.5-6.7,1.1-1c2.3-0.8,5.2-3.1,3.3,3.2c1.5,2,9.5-2.4,11,1.2
		c0.4-8.3-12.3-1.3-6.6-8.8c-1.8-1.1-5.1-3.6-5.4-1.1c0-5.8,3.2-6.4,1.5-10.7c10.9,4.3,4.4,1.7,3-3.7c2.7-2.2,5.2,4.5,5.3-2.2
		c3.1-4.4,18.3,1.5,9.1-1.4C83.2,19,92,23,90.6,16.7c2.7-0.8,15-4.7,13.6-2.5c3.4,4.5,9.7-3,8.5-0.4c4.5,1.8,3.8-6.3,8.6-3.2
		c-2.5-0.6,8.6-3.5,6.4-3.3c2.6,8,1.4-1.8,4.4-2.3c3.9,2.5,11.9-4.6,11.8,4.5c-0.4-3.5,5.2-11.7,8.9-7.1c2.2,4.1,8.1-2.1,1.6-0.4
		c7.2-5.4,6.6,4.7,10.4,0.4c-0.3,9.2,7.9,1.4,10.9,3.3c-5.6-3.9,3.9,1.8,2.7-3.5c-0.9,0.2,10.1,8,6.1,1.6c4.6,4.9,4.4-1.8,7.5,3.2
		c4.4-2.9,6.7,1.5,7.6-1.8c1.3,0.8,4.1,1.2,7.4,2.9c3.8-1,12.5-2.7,10.5,2.2c1.5-5.7,9,0.1,4.9,0.8c8.8-3.1,1,7.5,6.3,7.9
		c-1.6-8.2,5.4,1.3,7.7-1.7c6.5,3.3,6.3,7.1,13.6,8c1.5,5.2,3.4,4.1,6.5,7.6c0.2-0.2,3.8,10,4.1,2.9c2.1,3.5,12.1,6.4,10.2,9.3
		c2.8,5.2,4,3.3,8.5,8.7c1.9-0.1,1.7,8.3,3.4,3.2c4.6,7.4,0.4,7.7,4.2,11.2c1.3,5.9,10.1,12.7,5.9,13.9c-0.4,6.1,2.3,0.6,2.2,9.5
		c2.9-4.6,1.2,2.5,1.8,3.4c5.9,5.4-3.3,10.3,1.6,11c1.2-2.7,3.7-2.4,4.4,2.2c1.2,5.3,2.5,11.7,3.9,17.9c3,5.2,3.2,4.4,6.4,8.7
		c-2.9,3,1.2,11-0.7,13.1c-1.3,6.4-0.1,6.6,1.2,12.4c0.2,7.2,1.7,14.1,1.5,22.2c-0.7,3.3,2.9,7.9-3.3,8.4
		c4.1,10.2,4.2,29.2-1.1,41.3c-5.5,14.6-10.6,28.4-13.2,41.4c-9.9,11.7-13.5,24.7-16.8,40c0.9,18.4-30.2,36.1-9.3,49
		c150.9,141-24.5-18.9-12.9,61.8c-3.3,1.5-0.9-9.6-6.1-0.6c-0.2-1.1,1.2-10.9-1.9-2.6c-0.9-5.2-1.2,5.9-7.3,4.7
		c-3.6-1.7-5.1,3.2-11.3,3.9c-5.6,0.5-11.4,6.9-20.1,12.1c-6.4,4.3-12.4,8.5-15.6,11c-3.1,1.1-6.1,8.5-9.3,2.4
		c-0.8,4.9-11.3-3.5-7.4-0.5c6,2.5,7.8-3,3.4-6.8c-2.5-3.8-1.6,4.2-7.2,4.3c0.5,5.2-16.2-1.6-12.1,0.6c-0.9,0.8-7.8,0.8-3.9-3.8
		c3.6,7.5,9.1-3.1,7.2,1c3.8-1.1,4.1,2.7,7.1,1.2c-12.7-4.9-0.6-4.8-6.9-6.9c-2.5-5.5-8.5-4.2-11.4-8.3c-2.6,5.3-5,5.8-0.4,8.1
		c-6-1.1-4.1-5.8-8.9-6.6c-4,1.2,1.8-7.8-5.6-7.4c-3.4-6.2-7.9-2.1-2.2-0.3c8,6.7-8.5-3.4-9.5-3.2c1.1-6.1-5.3,3.4-4.6-3.7
		c-2.9,6.8-7.5-12.3-3.7-2.2c-3.6-3.3-1.9,4-5.2-3.4c6.1-2.3-6.2,2.1-0.7,4.3c-9.7-6.4,1.4-3.1-8.1-9.6c2.9,7.4-1.3,6.9-6-0.6
		c-8.1-9.7-2.6-2.4,0.8-7.2c2.3-3.1-17.5-9.8-9.6-12.5C87.8,369.3-33.4,421.1,79.8,368.1L79.8,368.1z M152.8,438.8
		C157.2,434.7,146.2,436.7,152.8,438.8L152.8,438.8z M154.3,428.3c-6.8-6.8-10.1,0.6,0.5,0.3L154.3,428.3L154.3,428.3z M192,416
		c5.7-4.4-3.3,0.6-2.7,0.7C185.8,413.8,193.9,422.1,192,416z M202.8,404.8c-4.9-7.3,4.6-2.1-1.7-6.9
		C197.4,391.1,199.5,410,202.8,404.8z M204.6,397.8c5.3,0.6,0.3-3.3,1.1-7.7C207.2,393.5,199.1,399.3,204.6,397.8z M211.5,390.9
		c7.8-9.8-0.8-5-1.1-1.2C210,390.3,210.3,393.1,211.5,390.9z M196.8,380.5c5.2-4-3.8-6-2.8-3.2C192.4,376.4,196.1,382.9,196.8,380.5
		z M86.9,378.9c2.9-7.6-1-3.5-1.9-1.4C86.3,377.1,85.8,380.8,86.9,378.9z M83.5,373.2C81.8,370.4,84,370.4,83.5,373.2L83.5,373.2z
		 M229.5,371.7c-0.2-2.4,8.3-20.2,4.3-3.5c4.7-9.1,1.1-13.3,8.9-14.8c-5.4,2,0.3-5,2.8-4.9c0-4.1-1.2-6.4,3.1-8.6
		c0.3-5,2.8-3.2,1.2-7.2c-1.9-9.6,5.8-3.6,3.4-10.1c-0.8-8.1-7.2-9.2-0.2-10.3c-6.7-2.1-2.2-2.1-7.8-4.4c3.5-0.5-2-9.3,2.6-0.7
		c2.7,3.7,2.2-8.7-3.3-7.3c-4.5-3.6,5.3,1.8,4.7-5.1c0.6-4.7-5.7,2-4-4.4c7.9,0.6-7.8-5.5,0.8-6.5c4.3,8.3-0.2-4.9,1.9-0.4
		c7.2-5.6,2.6-1.3,0.7-10.4c3.3-0.1,5.7,8.1,5.8,0.8c-7.8,0.7-3-12.4,0.2-3.9c4.4,0.7,1.9-7.2-2.5-4.9c-5.8-2.7-3.3-9.9,1.7-0.6
		c3.4-3.8,1-4,7.4-4.7c-1.2,0.1-2.3-7,2,2.2c6.5-0.7-5.1-10.2-0.6-9c-4.8-5.6-6.1,13.8-7.3,0.2c7.1,7-3.7-7.1,2.4-2.3
		c13.6,1.3,5.7-0.6-1-6.8c-2.1-3.6,0.1-3.6-2.7-7.4c4.3,0.1,7.5,5.2,12.1,4.5c4.4,6.8,0.8-4-4.6-5.8c-4.9-1-6-2,4.6-1.7
		c-8.2-3.7,0.9-0.9,4.3,0.1c6.5,1.4-7-1.2-7.6-3.7c-1.6,0.9-14.1-4-5.3,0.4c-2.1,3.8-15.5,0.3-23.8,0.7c5,3.1,5.6-4.2,11.4-1.1
		c-1-4.2,8.5-6-6.4-5.6c0.6-4.8,3.8,1.2,5.5-2.9c-5.2,0.2-19.7-0.2-11.7-0.8c-5.9-3.6-23.8-3.2-28.5,0.1c-2.6,2.7-8.5-0.4-3.8,2.9
		c12.5,0.1-5.5,6.3,1.7,4.7c3.7,5.8,1.2-8.7,8.6-6.3c1.1,2.5-3,5.9-6.2,12.1c10.6,6.1,1.6,1.3,0.5,3.6c0.2,1-6.2-3.2-7.8-0.5
		c1-10.9,0.9-5.8-4.8-4.1c1.3-2.9,8-7.5,1.8-8c-2.6-0.5-6.1,10.3,0,14.4c3-1.7,3.5,13.8,8.3,9.9c1.7,5.6,2.3,4.7,7.5,9.2
		c-1.8,1.2-2.1,1.7-6.1-1.7c-8.5,0,2.7,7.1,2.7,6.7c1.6,3.2,6.5,8,4.6,6.9c-0.1,2.6,9.6,9.5,5.1,10.3c8,3.8,1.1,8.8,2.4,12
		c-3.8,4.5,0,2.6,3.2,5.7c-6.4-0.8-3.1,6.4-0.4,1.4c6.1,3.6,9.3,8.5,7,8.6c3.3-2.2,2.5,4.5,1.6,3.6c5.5,0.3,0.9,5.3,3.9,11.3
		c-2,3.5,2.2,8,4.4,0.8c-2.9-0.4-4.9-5.7-1.7-2.8c1.7,1-1.5-7.4-0.3-7.1c-0.3-1.4-2.6-11.7-2.4-9.9c3.5-2.1-7.2-7.5-8.5-12.3
		c5,4.9-6.9-12.9-1.2-2.9c2-1.5,7.7,12.5,14.8,10.6c8.7,1.5-9.4-0.5,1.6,5.9c7,5.3,11,16.9,5.2,26.6c-6.2,9.1-9.8,21.3-13.4,29.7
		C230.1,361.2,228.6,368.8,229.5,371.7L229.5,371.7L229.5,371.7z M242.1,305.1c2-0.4-3-6.7,2.3,0.2
		C242,302.6,244.1,307.9,242.1,305.1z M233,255.8c-2.2-2.3-5.7-5.8,3.9-1.2C236.4,256.6,234.8,257.4,233,255.8z M235,249.5
		c-5.2-4-10.2-3.2-8.2-4.4c1.5-0.2,8.5,1.1,8.8-0.1C240.9,248.7,245.2,249,235,249.5L235,249.5L235,249.5z M256,246.2
		C250.2,235.4,254.7,246,256,246.2L256,246.2z M253.1,238.5c-9.5-5.9-7.2,2.3-13.7-1.8C234.2,233.7,257.6,234.7,253.1,238.5z
		 M224.2,232.9c-0.5-5.5,11.3-2,0.3,0.1L224.2,232.9z M75.3,349.2c0.9-7.4-6.8,2.1-1.3,0.6L75.3,349.2L75.3,349.2L75.3,349.2z
		 M70.3,345.8c4.2-2.1-0.3-4.3-3.5-6.9C68.3,339.6,69.7,349.2,70.3,345.8z M67,333.7c-3.7-2.1-3.6-9.5-4.2-6.9
		C62.1,330.7,64.3,336.2,67,333.7z M160.8,329.6c-6.9-1.7,14.1-1,0.3-2.9c-9.5-4.3-4.3-2.9-11.6-0.5c-0.8,0.9-15,0.1,0.5,2.7
		C153.8,327.8,157.2,331.4,160.8,329.6L160.8,329.6z M75.1,322.9c10.3-3.2-3-3.9,1-1.2C75.8,321,72.5,323.9,75.1,322.9z M75.6,319
		c9.9,1.6,6.2-6,2.6-4.3c-2.6-7.8-1.5,5.7-1,0.6C72.6,315.3,74.9,317.9,75.6,319L75.6,319z M199.7,318.9c-4-2.6,3.6-8.2-3.2-1.7
		C196,319.3,198.6,319.1,199.7,318.9z M190.8,303.9c1.3-5-1.1-3.8-2.2-0.7C189.6,302.8,191.4,306.7,190.8,303.9z M210.7,298.7
		c5.4-5.2-7.2-3.1-1.4,0.6l0.5,0.2L210.7,298.7z M186.2,287.2c-2.1-4.7,5.3-2.3,7.1-4.1c-2.4-3.8,4.1,3,5.3,0.9
		c2.4,1.8,3.1-12.9,2.3-10.2c-6.1-0.3-8.1-1.8-5.2-4.8c1.6,2.1,2.8,1.2,3.2-2.4c-3.7,0.3-13.1,6.9-9.6,7.4c-2.8,2.1-3.2,4.4-6.5,9.6
		C184.7,282.1,184,290.7,186.2,287.2L186.2,287.2z M180.9,274.1C179.7,272,182.2,275.3,180.9,274.1L180.9,274.1z M197.7,254.1
		c2.4,2.8,5.6-8.9-1.4,0C195.2,254.4,198.9,256.2,197.7,254.1z M263.8,247c-4.5-5,7.1-0.3-6.8-7.7C264.8,243.7,256,245.5,263.8,247
		L263.8,247z M43.3,220.8c3.9-1.3-5.5-6.4,0.1,0.9L43.3,220.8L43.3,220.8L43.3,220.8z M203.3,216.6c-2.7-3.2,17.8-6.8,0.9-6.8
		c-7.4-1.3-3.9,7.7-6.3,4.1C192.1,213.2,200.2,220.7,203.3,216.6L203.3,216.6z M176.5,216.9c7.8-2.4-3.4-2.8-1.4-0.5l1.4,1
		L176.5,216.9L176.5,216.9L176.5,216.9z M33.9,211.1c3.3,2.4,1.1-8.4-1.6-2.3C26.8,204.2,33.7,213.3,33.9,211.1z M55.9,210.3
		c3.8-4.2-6.4-3.5-0.9-0.5L55,211L55.9,210.3L55.9,210.3z M62.3,196.6c-1.2-2.9-1.1,5.8,0.3,1.1L62.3,196.6z M51.9,191
		c2.3-5-1.6-4.6-2.2,1.3l1.1-0.2L51.9,191L51.9,191L51.9,191z M60.4,179.9c-3.4-1.8,4.4-10.2-2.6-3.5
		C57.3,175.3,57.2,183.6,60.4,179.9z M229.3,174.7c7.9,4.2,5.9-2.6-2.5,0c0.3-4.7-9.1,4.9,0.6,1.5
		C229.2,180.2,227.8,177.1,229.3,174.7L229.3,174.7z M63.7,173.3c-4-5.4,7.7-8.5,1-9.5c5.4-7.9-6.4,1.1-1.3,7.6
		C59.1,167.2,64.2,178.9,63.7,173.3L63.7,173.3z M59,171.2c3.5,1-0.8-6.4,0.1,0.9L59,171.2L59,171.2z M12.6,168.8
		c1.3-1.2,2.6-11.4-0.9-4.1C9.8,164.2,12,171.9,12.6,168.8z M61.7,162.7c0.4-1.6-8.6-3.2-1,0.9C60.8,165.4,64.2,164,61.7,162.7z
		 M286.4,148.7c-0.7-2.8,11.5-5.3,2.5-3.7c-6.6,1.6,1.4-6-5.9-5.6c4.9,3.6-4.8,0-2.3,5.3c5.4-3.6,1.1,5,4.7,1.9
		C287.1,147.3,283.8,150.3,286.4,148.7L286.4,148.7z M298.8,148.4c-3.2-2.7-7.1-3.7-4.3-0.6C295.4,146.9,298.1,149.7,298.8,148.4z
		 M73,146.2c-2.9-2.2-1.3-2.7-1.9-6.9C65.7,143.4,70.1,148.9,73,146.2z M223.3,143c-2.2-3.6-7.4-2,0.8,0.2L223.3,143L223.3,143z
		 M294.3,142c-0.8-8.8,1.5-5.4-4.6-3.8C289.5,136.3,294.3,144.8,294.3,142z M277.7,141.3C280.3,138.3,280.4,140.6,277.7,141.3
		L277.7,141.3z M221.5,138.9c7.1-5.2,0.6-4.3-3.4,1.3C219.4,141.1,219.8,137.8,221.5,138.9z M224.2,138.3
		C222.5,135.5,224.7,135.6,224.2,138.3L224.2,138.3z M293.2,130.9c-0.3-5.5-4.3,5.5-4.2-5.8c0.6-0.7-9.2,5.7-5.5,3.6
		C289.1,128,287.7,134.4,293.2,130.9z M221.3,129.4c2.4-2.2-4.3-3.5-7.1-2.4c4.9-8.4-8.8,0.5,5.6,2.8l0.8,0.4L221.3,129.4
		L221.3,129.4L221.3,129.4z M280.5,128.1c9.8-3.8-0.3-4.4-0.2,0.5L280.5,128.1z M278.9,124.1c0.9-7.1,2.8,3,7.6-3.7
		c3.5-5,5.2,4.9,0.5-6.4c-3.5,3.4,0-6.4,0.7-4.4c-0.9-5.8-8.6-2.3-1-6c0.6-3.4,2.6-9.2-6-5.5c3.4-5.1,3.5-2.3-4.1-5.9
		c7.9-3.3,0.9,4.6,10.7-1.1c-10.3,0.2-11.1-5.1-4.7-3.8c-9.9-6.7-5.6,2.5-6.6,3.5c-6.5,9.6-0.5,4.7,3.2,8.5c4.9,5,4.2,0.9,1.8,6
		c1.3,4.5-6.5-9.2-2.5-1.5c-6.5,4.2,2.3,11.2,3.6,4.6c7.3,1-6.6,6.5,1.5,4.6c2.5,6.7,5.3,10.9-1.8,6.4c-3.4-0.7-5.3-4.6-1.4,3.5
		C277.6,122,275.7,126.6,278.9,124.1L278.9,124.1z M89.7,116.1c7.6-3.3,3.2-1.5,1.9-2.7C94.8,114.9,86.5,116.4,89.7,116.1z
		 M276.6,115.4C284.4,112.7,268.1,115.6,276.6,115.4L276.6,115.4z M90.1,113.9c4.1-3.8-5.3-12.2-0.7-2.7
		C88.5,112.2,87.2,116.4,90.1,113.9z M226,114.5C230.5,113,221.4,114.8,226,114.5L226,114.5z M235.7,97C229.7,93,235,103.1,235.7,97
		L235.7,97z M193.6,96.9c4.8,5,1.3-6.2-1.8,0.9C188.5,99.9,195,97.8,193.6,96.9z M291.9,98.2c-1-3.4-3.4-1.5,0.6,0.6L291.9,98.2z
		 M207.2,95c0.1-2.1,5-4.8-0.9-1.3C205,95.8,207,98.4,207.2,95z M294,94.2c2.1,3.2-2-3.3-1.1,1L294,94.2z M243.9,93.3
		c-1.6-6-4.1,0.7-1.5,0.9C240,95.2,246.1,94.7,243.9,93.3z M246.1,91.7c5.5-10.6-7.5-1.4-1-4.9C245.7,87.2,246,95.3,246.1,91.7z
		 M248.4,88.7c1.2-2.4,0-5.3-0.7,0.1l0.9,0.4L248.4,88.7z M241.1,86.1c-0.4-8.5-2.5-3.5-1.1-0.9C237.5,85.3,240.7,87.9,241.1,86.1z
		 M98,77.1c-2.4-7.3-0.8-9.8-4.5-3.7C96.3,72.5,95.2,79.6,98,77.1z M110.5,72.4c3.6-7.5,8.1-5.6,1.1-12c1.1,1.9-4.9,3.1-7.5,4.2
		C107.8,66.3,107.2,70,110.5,72.4z M79.3,68.2c0.3-5.1-3.3,1.2-2.2-4.4c-3.1,2.6,7-6-4.6-0.6C70.4,65.5,78.9,69.8,79.3,68.2z
		 M271.7,55.9c6.4-1.2,3.7-1.6,1.2-3.7C271.9,52.5,268.3,61.2,271.7,55.9z M124.2,53.1c3.7-1.2,3.8-3.6-2,2.1
		C123.3,55.6,124.3,54.1,124.2,53.1z M81.4,52c-0.4-2.8-4.8-6.1-1.6,0.3C80.7,52.2,81.1,54.5,81.4,52z M145.5,46.3
		c8.9-3.6-7.8-1.7-1.5,0.4C145.7,45.9,143.7,49.2,145.5,46.3z M207.2,42.2c5.8-5.5-4.5-1.5-1.9,1.5L207.2,42.2z M155.3,41.2
		c-5.5,1.4-1.4-9.5-4,0.5C152,43.4,154.8,42.9,155.3,41.2z M168.5,33.6c2.2-5,7.4-4.2,0.2-3.3c3.4,3.4-8.5-4.7-2.7,1.3
		C157.3,31.5,166.5,34.5,168.5,33.6L168.5,33.6z M158.1,31.9c4.6-1.1-0.3-10.1-0.5,1L158.1,31.9L158.1,31.9z M192.9,31.8
		c7,2.1,3.7-6.6-0.7-2.4C190.6,29.9,193.4,33.7,192.9,31.8z M247.7,27.9C245.2,24.3,247.1,31.6,247.7,27.9L247.7,27.9z M196.2,26.8
		c1.9-4.8-7.5,0-0.2-0.7C193.3,25.8,195.6,28.6,196.2,26.8z M191.6,22.9c1.9-9.2-3.7,4.4-2.9,1.4l0.8,0.1L191.6,22.9L191.6,22.9z
		 M167.8,19.4c0.5-1.9-4.6-8.6,0.1,0.4L167.8,19.4z M197.9,16.7c-4.3-1-7.4-0.1-1.2,0.6L197.9,16.7z M139.7,444.1
		C140.8,439,137.2,443.3,139.7,444.1L139.7,444.1z M125.9,435.7c-5.1-2.9-15.5-13.9-19.5-17.3c9.1,1.6,4.9,4.3,7.9,7
		c8.3,7,1.1-2.7,7.3,3.7C123.4,430.6,129.6,436.3,125.9,435.7L125.9,435.7z M151.2,423.4c-5.1-2.9,4.1-0.9-1.8-4.5
		C155.1,419.3,157.4,422.5,151.2,423.4z M172.9,420.2c-6.9-0.6,2.7-4.1,0.7,0.6L172.9,420.2z M117.9,407.6c-1.1-5.6,0.2,1.2,1,0.5
		L117.9,407.6z M184.7,391.6C179.4,389.4,187.4,387.3,184.7,391.6L184.7,391.6z M187,382.6c3.1-3,3.3-5.8,4-2.1
		C191.2,383.9,186.4,382.6,187,382.6z M156.4,366.3c-2.5-2.7-6-4.6,2.3-1.5C158.7,366.3,153.2,369.2,156.4,366.3z M100.9,362
		c-6.8-1.7-2.5-3.1-5.5-5.4c-0.1-3-3.1-5.7-4-8.7c-1.2-3.3-3.1-1.6-4.5-8.3c4.2,1.2,7.9,4.4,8.3,8.7c5.2,2.9,1.6,5,3.6,6.7
		C96.5,356.5,103.8,361.8,100.9,362L100.9,362z M110.1,344.6C108.8,341.6,107.6,343.7,110.1,344.6L110.1,344.6z M162.3,343
		c-20.8-3.7,20.9-1.7,0.9-0.1L162.3,343L162.3,343L162.3,343z M106.8,341.4C106.1,335.8,107.5,336.5,106.8,341.4L106.8,341.4z
		 M142.9,338.9c-13.2-1.6-2.5-4.2,2.7,0.4L142.9,338.9z M99.2,327.5c-1.7,0.9-2.2-5.9,0-6.4c0.2-3.9,6.9-8.8,5.5-5.6
		c5.3,0.1-4.8,5.6-4.4,8.3C99.8,324.7,100.2,328,99.2,327.5L99.2,327.5L99.2,327.5z M190.5,312.3c-2.1-2.4-6.7-2.6-2.2-4.7
		C190.5,304.9,197.3,314.1,190.5,312.3z M145.1,303.5c-1.5-2.2,7.2-9.3,1.4,0.3L145.1,303.5L145.1,303.5L145.1,303.5z M26,267.2
		c-10.6-4,6.1-9.3-2.8-3.9c7.1-2.2,4-3.6,10.1-7.1C34.6,257.4,26.8,264.5,26,267.2z M146.7,243.5c-2.6-4.3,7.8-8.1,1.7-4.5
		C146.2,235.1,150.2,246,146.7,243.5z M98.1,235.5c6.9-7.5,12.6-1.8,0.4,0.5L98.1,235.5z M80.1,232.8c-8.1-0.5-7.6-7.2-4.3-2
		c4.1,1.6,6.1-1.7-1.5-4c2.3-3.5,9,3.8,12.7,5.8C82.9,233,81.9,229.3,80.1,232.8L80.1,232.8z M81.3,205.6c2.1-9.3,2.9-4.1,5.6-6.5
		C85.4,202.1,82.9,203.8,81.3,205.6z M114.3,194.1c-4.7-3-13.1,3.7-8.4-8.2c-0.3,6,5.5,5.3,4.7,2
		C115.6,191.9,120.2,186.4,114.3,194.1L114.3,194.1z M209.6,163.9c-4.6,1.5-5.1-4.1-2.1-3.2c0.7-3.4,15,4.1,6.2,1.7
		C217.4,162.7,210.3,164.8,209.6,163.9L209.6,163.9z M16.5,157.8c-8.5-2.8,0.8-7.8,1.7-0.1l-1.1,0.6L16.5,157.8L16.5,157.8z
		 M93.2,147.1c-5.1-2.2,4.8-10.6,2.5-2.5C94.1,143.4,93.7,150.2,93.2,147.1z M194.6,140c-3.9-6.3,4.6,0.8,0,0.8L194.6,140z
		 M12.7,131.3c4.7-1.1-0.2-7.2,4.9-6.7c3.8,2.6,6.6-7.3,7.5-1.5c0.7,1.7-1.6,6.1-8.5,5.8C15.3,129.7,14.3,133.3,12.7,131.3
		L12.7,131.3z M194.6,116.1C190.3,113.8,194.1,110.9,194.6,116.1L194.6,116.1z M201.9,111.7c-16.5-0.9,9.5-4.2,1.6-1.1
		C203.2,110.9,202.2,113.2,201.9,111.7z M58.2,96.6c6.2-8.1,3.9,2.2,4.3,1.3C61.1,94.7,58.4,99.9,58.2,96.6z M125.8,97.2
		C125,94.9,134,97,125.8,97.2L125.8,97.2z M123.3,97c-0.5-5.6,1.5,1.4,1.1,0.4L123.3,97z M100.2,96.6C99,93.6,97.7,95.6,100.2,96.6
		L100.2,96.6z M66.7,95.5C68.8,89.3,73.7,97.4,66.7,95.5L66.7,95.5z M154.7,92.8c5.4-10.9-5.6,3.9-0.9-6.2c-5.5-1.3-4.8,2.2-2.5-2.8
		c-6.7-1.2,5.5-0.4,6.4,4.1C168.1,77.2,154.3,97.1,154.7,92.8L154.7,92.8z M171.8,90.9c-5.3-4.8,7.8-10.5,1.3-2.4
		C166.5,85.1,179.2,89.6,171.8,90.9z M67.8,90.1c-5.3-1.4,7.3-1.5,2.7,0l-2,0.8L67.8,90.1L67.8,90.1z M131.2,90.2
		c-4-3,2.8-8,6.8-10.3c-2.6,5.7,3.2,8.2-4.4,7C135,88.2,129.1,92.5,131.2,90.2L131.2,90.2z M93.8,89.5c-1-2.5-6.9-3.9-1.2-4.8
		c1.8,0.1,7.8,0,3,5.6L93.8,89.5L93.8,89.5z M57.7,88.8c-7.4-0.9-0.5-3.9,1.2,0.4L57.7,88.8L57.7,88.8z M146.3,87.6
		c-4.6-2.5-2.5-1.6,1.5-4.6C144.7,86.3,149.4,86,146.3,87.6z M223.6,84.4C221.2,82.3,227.5,85.8,223.6,84.4L223.6,84.4z M155.4,83.5
		c-2.7-3.3-10.1-4.2-3.9-8.8c-5.3-5.2,18.7-0.4,4.2,2C150.4,79.3,158.2,83.3,155.4,83.5L155.4,83.5z M201.6,82.8
		C209.8,80.8,207.2,83.5,201.6,82.8L201.6,82.8z M38.5,82.8C38.1,79.5,36.8,81.4,38.5,82.8L38.5,82.8z M134.7,79.1
		C137.6,76.1,133,77.9,134.7,79.1L134.7,79.1z M132.4,74.6c-8.1-11,3.1,3.5,2.4-2.8C138,73.1,133.9,76.1,132.4,74.6z M140.8,74.6
		c3.6-4.7,1.4-4,5.5-5.9C149.1,71.6,139.3,75.4,140.8,74.6z M155.3,72.8c3.1-2.9,1.7-9.8,5-5.1C163.7,62.2,163.2,75.8,155.3,72.8z
		 M144.6,66.2c3.8-4.6-9-6.4,0.9-5.6C146,61.6,147.9,67.9,144.6,66.2z M129.9,60.2c-1.4-5.2,4.5-3.8,0.7,0.5
		C130.4,61.4,128.7,62.5,129.9,60.2z M142,58.6c-1.5-9.6,2.3-5.3,2.1-3.5C146.8,55.3,142.4,60,142,58.6z"
      ></path>

      <path
        id="stroke-mandela"
        d="M271.3,362.5c-21-12.9,10.2-30.6,9.3-49c3.3-15.3,6.9-28.3,16.8-40
		c2.6-12.9,7.7-26.8,13.2-41.4c5.3-12.1,5.2-31.1,1.1-41.3c6.2-0.5,2.7-5.2,3.3-8.4c0.2-8.1-1.3-15-1.5-22.2
		c-1.3-5.9-2.5-6-1.2-12.4c1.9-2.2-2.2-10.2,0.7-13.1c-3.2-4.2-3.4-3.4-6.4-8.7c-1.4-6.2-2.7-12.6-3.9-17.9
		c-0.7-4.5-3.2-4.9-4.4-2.2c-4.9-0.7,4.3-5.6-1.6-11c-0.6-0.8,1-8-1.8-3.4c0.1-8.8-2.6-3.4-2.2-9.5c4.1-1.2-4.6-8-5.9-13.9
		c-3.8-3.5,0.4-3.7-4.2-11.2c-1.8,5-1.5-3.3-3.4-3.2c-4.5-5.4-5.6-3.5-8.5-8.7c1.9-3-8.1-5.9-10.2-9.3c-0.3,7.1-3.9-3.1-4.1-2.9
		c-3.1-3.5-5-2.3-6.5-7.6c-7.3-0.9-7.1-4.6-13.6-8c-2.4,3.1-9.3-6.5-7.7,1.7c-5.2-0.4,2.1-10.5-5.6-8.1c2.3-1.2-3.7-5.5-5.4-1.2
		c1.1-4.2-7-2.6-10.7-1.6c-3.3-1.7-6.1-2.1-7.4-2.9c-0.8,3.3-3.1-1.1-7.6,1.8c-3.1-4.9-2.9,1.8-7.5-3.2c3.6,5.8-5.1-0.1-6.1-1.4
		c0.9,4.9-8.2-0.6-2.7,3.3c-3-1.9-11.3,6-10.9-3.3c-3.7,4.3-3.2-5.8-10.4-0.4c6.5-1.7,0.6,4.5-1.6,0.4c-3.5-4.4-8.7,2.8-8.9,6.6
		c-0.2-8.3-8-1.6-11.8-4c-2.9,0.5-1.8,10.1-4.3,2.4c-1.2,0.6-7,2.2-6.8,2.9c-4.4-2.5-3.9,5.2-8.2,3.4c1.2-2.6-5.1,5-8.5,0.4
		c1.4-2.2-10.8,1.6-13.6,2.5c1.5,6.3-7.4,2.3-7.1,7.4c0.5,0.2,0.9,0.3,1.2,0.4c-2.9-0.6-8.5-1.5-10.3,1c-0.1,6.7-2.6,0-5.3,2.2
		c1.4,5.4,7.9,8.1-3,3.7c1.7,4.3-1.5,4.9-1.5,10.7c0.3-2.5,3.6,0,5.4,1.1c-5.7,7.5,7.1,0.5,6.6,8.8c-1.5-3.6-9.5,0.8-11-1.2
		c1.9-6.3-1-4-3.3-3.2c-8.6-5.7-1.7-1-1.1,1c-5,2.4-7.8-8.1-5.3,2.6c-7.4,2.1,9-2,3,4c5.6-3.3,0.1,3.3,4.1,2.5
		c0.7,2.4,2.9-3.2,5.4,3.3c4.2,5.2-1.9,7.9,3.3,5.9c-6.2,6,7.2-1.2,4.3,4.6c0.6,5.5-1.8,1.6-4.7,4.7c-0.3-4.7-3.2-5.2-3.5-4.2
		c-6.5,6.3-6.7-7-10.2-3c-6.7-3.8-0.4,6.7,0.2,2.8c7.3,2.2,1.5,9.3-1.3,5.8c-2.6,4.6-7.3,1.7-0.5-1.8c-2.5-5.9-5.6,7.3-9.1,1
		c-5.1-7,0.7,4.2,1.1,4.6c1.5,10.3,7-1.1,11.9,3.2c2.5,0.7,5.7,10.4,4,1.9c0.5-3.9,14.1,2.7,8.1-6.6c9.8,6.9,4,4.5,1.9,8.1
		c3.2,0.6,14,1.8,8.4-4.7c-5-1-0.8-1.8,1.3-2.1c-0.3,0-0.6,0.1-1,0.1c0.3-5.2-1.1-5.3-3.4-1c-4.2-2.7,0.5-12.1,2.9-10.8
		c5.1,8.3-2.9-7.3,3.6-3.8c-6.1,8.2,5.9,0.8,1.5,5.8c3.7-2.1,3.1,2.8,2.6,5.3c4.4,1.4,12.8,6.5,14.3,5.7c-4.8-3.7-1.5-6.8,1-2.1
		c15-1-0.7-2.4-3.7-5.5c5.1,2.8,6.8-5.9,7.1,0.1c1.7,4,3.9-1.9,2.9-1.8c-0.1-6.5,8.8,5.6,6.2,5.1c-7.1-0.1,5.1,1.7-0.4,7.1
		c-1.9,5-0.9,11.6,4.4,3.9c-6.6,0.8,2-12.8-1.9-14.4c6.3-1.9-5.1-2.8,1.7-7c2.8,3.3,2.1-6-2.9,0.7c-3.3-5.8,5.7-1.1-0.2-9
		c1.7-2.1,6.1,5.5,4.5-3.2c3.8,8.5,2.4-6.3,3.2,0.2c7.5-4.6,4.6-3.9-0.3-8.9c-1.2-2.9,6.2-6.7,4.2,1.1c-0.1,3.9,7.8-1.3,4,5.7
		c6.2,4.7-0.3-15.6,3.9-4.2c8,9.5,7.1-6.8,12.4-5.3c8.4-5.5-2,1.5-0.6,3.6c-2.6,4.3,2,7.9,6.2,4.6c2.6,2.3,2.9-5.2-1-2.6
		c-5.7,2.9,3.4-2.8,1-11c2.8-0.1-1.7-6.4,4.1-6.2c-2.4,4.1,2.6,0.9,4.5,1c1.6-0.2-3.1,11-3.3,5.1c-2.5-0.6-1,3.6-0.5,4.4
		c-9.7,11.4,4.6-4.1,6-1.9c10-1.1,3.1-4.4,7.4-10.8c-1.5,7.3,7.8,0.7-0.1-1.6c-0.2-3.2-6.2-4.6,1.4-3.7c2.2,13.7,5.8-6.8,6.8,0
		c-3.6,8,0.5,4.6,2.7,6.2c1.6-8.6,8.1-2,2.7-2.4c4.1,5.9,7.5,3.4,14.2,3.5c-6.1,0.9,7.1,9.2-1.9,10.1c5.6-6.5-11.4,6.6,2.5,4.9
		c3.5-2.9,4.9,2.9,6.1-2.6c5,4.8-0.6-5,3.6-0.7c10.5-2.2-3,1.1,3.4,5.5c0.7-12.2,12.1-0.3,5.4-5.1c-3.6-0.9-2.8,6-0.2,5.1
		c3.8,9.9-1.3-6.9,4.3-0.6c0.5,1-1.3,8.1,2.3,6.4c-2.7,8,5.3,1.1,7.8,8.8c-9.7-4.9-2.2,1.5,0.5,3.5c4.1-4.7,6.3,3.2,4.7,2.3
		c-3.3,3.2-5.8,4.4-3.3,11.3c-2.5,0-10-6.8-0.7,2c-0.2,4.1-14.9-4.7-15,8.8c3.9-1.4,5.1-6.3,10.5-7.3c-3.7,2.9-11.2,14.1-9.6,9.6
		c-3.8-4,3.1-11.9-5.7-9c-6.2,7.5-1-3.2-4.4,0.1c-7.2-0.4,3.7,8.4-5.8,3.1c-4,2.1-1.2,7.6-3.5,0.6c-7.7-10.3-2.7,3.8-5.2-1.6
		c-3,3.9-5.8,6.2-6.2,3.9c2-7.1,0.7-2.2,3.1-9.1c-4,0.2-3.5,3.8-5.3,5.7c-7.3,0.9,8.3,1.9-2.4,3.4c2.9,4.7-2.4,0.8-0.9,5.1
		c0,2.6,9.1,1.7,6.7,2.7c9.1,2.3,8.6,2.2-0.8,1.7c3.7,2.5,3.3,8.1,7.7,7.1c-1.2-2.4-3.6-6.1,1.4-4.4c9.9,0.7-5.1,2.8,2,3.6
		c11.9-4.1-6.4,14,2.4,6.1c5-0.1,8.4-6.4,5.8-0.6c-8.1,2.7,4.3,4.8,3.8,4c4.5,6.1,6.6-0.3,8,3.6c-7.2,1.3-5.6,1.3-7.9-1.3
		c-4,4.1-5.7-6.8-3.8,6.8c1.7,6.6,2.3-1.7,10,4.6c3.2,2.2-4.4,3.5-3,5.1c1.1,1.1-7.5,4-1.4,3c-6.1,4.5-6.4-4.4-6.8,5.6
		c-4.8-6.3-11.3,11.6-6.2,3.9c0.9,5.6,4.2-0.6,4.9-1c4.4,3.8,0.9-2.3,8.8,0.4c-10-2.4,2.8,10.8,0.9,4.3c4.1,1.1,6.7,0.4,12.4-0.5
		c1.7,3.4-3.9,8.4-7.6,2c-4.7,7.8-6.9,0.8-3.4,8.7c-7.9-5.2-0.8-7.6-7.5-12.3c3.9,4-4.8,3.5-1.6,4.8c1.5,2.3-6.6,1.5,2,3.8
		c5-3.2,4.7,11.3,7.4,4.4c-0.4-0.2,11-0.1,7.2-3.9c7.1,2-3.6,8.9,1.3,6.1c3.2,0,4,11.8-4.6,3c-3.1-1.3,1.1,2.7-3.8,5.8
		c2.4,5.8,3.5,7.9,6.3,4c-4.8-3.3,9.6,0.7,3.4,2c-5.6-2.9-2.4,5.4-8.4,1.2c0-4.9-4.3,3.3-6.8,2.2c-2.1,4.9,8.4,2.7-0.8,5.9
		c-4-2-2.1-2-6.3-5.5c-3.4-3.2-2.7,11.4,0.9,9.3c-0.8,0.6-2.2-11.8-0.7-6.5c1.5,9.2,7,0.5,6.4,7.8c6.5-1.2,16.3-1,22.1-5.6
		c1.4-2.9,6.3,5.6,1.3,1.9c-7.5,6-3.6,4.4-0.5,5.6c-1.5,1.8,13.5,4.4,5.4,8c2.8,2,3,8,1.4,8.8c4.8,1.1,10.4,5-0.2,3.1
		c-3.8-3.7-11.6-4.5-20-7.5c-10.2-3.7-27.2,2.1-31.9-3.9c9.4,0.4,1.9-3.1,7.3-4.4c3.3-2.9-0.5,0.4,5.6-6c-0.7,0.4-1.9-8.9-4.3-7.5
		c2.4,5.2-1.5,6.3-5.1,1.3c-6.2-1.9-10.6,2.2-9,3.5c0.7,9.5-7.5,1.2-6.4,6.5c-7.3,0.5,5.3,3.5-0.5,9c-1.6-6.6-1.3-3.3-5.4,2.3
		c2,0.5,3.1,0.3,3.4,0.2c-0.3,0.2-1.4,0.8-2.8,2.9c5.5-2.8-6.3,6.8,1.6,7.2c-3.9,0.2,4.6,5.9-3.6,4c-10.1,0.1,8.9,3.5,3.6,5.7
		c-0.7-3.3-6.4,0.9-4.8,3.1c6.5,0.7,2.3,6.3,5.4,8.9c-4.1,1.9,1.3,5.9,0.1,7.5c-4,6.6,5.7,4.9,1.6,7.9c0.4,0.8,1.7,4.9,1.1,6.5
		c8.4-0.9-4.2,2.3,2.7,6.2c-9.3,0.5,2.7,9.8-4.4,6.7c-0.8,3.5-3.9,0.6-7.4,3.3c3.7,6.8-13.3,0.8-7.6,3.4c3.7-1.5-8.4,4.7-7.5,0.9
		c1.9-4.9-4-0.8-6.9,0.1c0.9-0.9-2.1,1.4-7,3.1c-4-0.3-12.1-0.5-11.2,3c-1.5,4.8,6.5,3.4,13.7,2.3c-3.3,1.1,4,3.9,2,0.9
		c6.9,7.2-1.2-2.2,6.9,1.7c4.5-4.8,19.5,4.3,28.9,1.6c2.7-0.3,2.9,4.1,2.6,7.3c-0.2-1.4,13.1,2.7,8.3,9.6c2.8,2.1,0.9,3.3,0,2.9
		c4,6.1-8.1-0.2-3.3-0.1c-1.6-6.2-1.7-1.7-3.9,1.8c-0.3-0.2-11,4.8-11.2,2.1c-9.6,11.9-22.1-4.5-33.3,1.8c-2.4,1.3-11.7,1.4-7.9-5.6
		c-7.9,1.9,1.3,6-9.6,9.6c8.1-6.4-7.8,2.1-3.5-2.8c-3.3,2.8-5.8-2.5-6,2.1c-4.3,1.7-6.2-1.4-4-5.2c-0.7-5.1-6.4,10.3-3,0.8
		c-6-3-1.7,8-1.5,11.8c-2.3,1.2-6.8,3.2-8.1,3.2c2.7,10.5,10.5-9.2,9.9,0.6c5.5,1.1,9.5-1.5,2.6,3.7c4.1,0.3,6-1.4,9.7,5.9
		c4.2,5.6,7.2-7.2,8-0.2c-1.8,2-0.2,2-2.4,4.5c9.4-1.6,4.1,5.5,10.4,3.2c-7.9-11.6,1.9,3.4,2.5-0.9c8.9,8.5-0.3-4.1,4.2-7.8
		c8.1-1-8-4.9-5.7-4.9c-4.7-1.2-11.5-2.8-10.4-6.5c-8.6-3.1,11.2-0.5,13.9-4.4c7.1,0.4,18.1,1.8,25.9,4.6c7.7-4,19.2-0.4,27.9-0.7
		c-2.1,3.5,6.1,4.8-0.1,5.6c-2.1-0.8-9.2,2.6-6,2.9c6.5,1.2-3.7,1.3-0.1,6.8c2.1,3.3-0.8,7.2-4.7,6.7c-8.9,2.5,1.3,7.8-6.4,9.2
		c-1.8-3.4-6.1-0.6-8.7-0.3c-4.6-1.4-3.9-0.3-8.4,2.4c-3.2,3-9.3-6.4-9.5,1c-3.4,0.8,5.6,1.5,10.7,2.1c0-1.5,7.9-2.5,3.5,2.5
		c6.9-0.7,3.4,3.1,1.9,4.8c5.4-2.7,6.6-3.1,10.7-3.1c-8.6,6.3,5.9,0.4,5.7,4.5c2.9-6,2.3,0.7,7.3-3.3c2.7-3.7,3.5,15.1,7.8,6.9
		c1.2-0.3,7.5-6.6,6.7-0.1c1.9-4.4,9.2-13.4,4.5-5.2c3.7,1.5-6.6,10.2-6.8,4.9c-1.6-1.9-2,4.8-5.8,6.2c8.4-0.1-8.2,4,2.7,4.1
		c5.9,2.5,3.9,3.8-0.7,2.1c-4.7,2-3.3,5.1-1.7,7c-5.3-0.5-7.2-1.8-10.9-0.3c-1.4-1.8,3.5,5.9,3.6,2.7c2.3,1.5,4.7-4.9,5.2,0.5
		c-1.7,0.4-8.8,6.8-4.6,7.3c-2.3,0.6-0.9,2.8-5.6,3.7c-5.6,0-4,2.7-2.4,4.4c-9.7-1.4-2.3,2.8-5.5,2.7c-8.6-0.7,7.5,3.3-7,1.7
		c-1.7,4.4-8.2-5.3-5.7,4.9c-6.5-9.4-1.9,1.6-3.8-3c-7.4,9.5,0.5-11.9-4.1-0.5c-1.5-3.1-8.8,3.2-10.6-3.5c-1.7,1,0.3-1.6,3.5-3.4
		c-4.7-0.7-2.8-4.3-7.5-6.5c1.5,5,0.9,10.5-1.8,3.4c-1.9-0.1-11.8-7.4-10-7.6c-3.8-3.1-9.9-5.2-7.6,5.3c-6.6,10.8,3.5-12.7-3.6-2.1
		c-2-2.4-2.4-8.1-5.2-9.2c-2.3,5-6.3-4.3-6.9-5.1c7.3,4.7-4.8-11.1-1.6-4.2c-0.7,3.6-3.1-2.5-4.7-4c-3.5-4.2-0.3-3.3-2.6-9.1
		c-5.4-1.1-4.5-7.1-7.3-11.1c-0.1,0.7-8.3-13.2-3.6-16.7c-8.3-5.5-1.8-10.1-8.3-8.3c-3.3-0.8,1.6-5,1.7-8.3
		c-3.2-2.6-1.4-7.9-4.7-9.7c1.3-0.7,9.2-6.1,8.4-0.4c2.7,7.2,3,11.5,3.6,12.8c7.8,8-2.6-9.6,0.9-14.3c-1.9-8.4,8.4-17.1,12.1-21.4
		c3.3-7.8,7.8-14.4,9.6-20c-1.2-6.6-3.6,11.5-6.8,5.4c-4.2-3.1-1.6,1.3-2.4,5.8c1.4-3-2,8-4.5,0.3c-6.2,3.8-0.8,14-4.4,5.5
		c-4.1,2.2-3.3,6.3-7,9.5c6.5-9.3-4.5-4.1-3.1-2.3c-0.3,3.6,2.7,3.1,0.1,6.2c-7.3,0.9-7.1-1.7-8.7-3.9c4.1,1.7,7-0.7,0.5-1.2
		c-1.2-7.5-6.8,0.5-3.5-5.8c-4.1-0.5-1.8-2.2-0.8-6.3c-2.5-1.5-5.1,1.2-6-1.8c-7.6-3.5-0.4-4.9,1.2-9.2c-4.2,2.4-6.6,2.2-10.2-5.8
		c-3.4-2.5-2.2-2.7-2-5.4c4.3-11-3.5,5-3.2-3.5c1.7,3.2,3.8-7.7,2.7-8.4c-0.4,3-7.4-5.3,1.2-1.4c1.7-6.4,6.9-1.4,4.1-7.1
		c-1-0.3-1.7-2.5-2-3.8c6.4-2.5-10.5,2.2-3.7-5.6c2.7-4.6-5.2-1.8,0.9-6.6c7.7-5.8,0.5-3.9-1.4-3.8c-1.8-11.6,13-4.7,23.7-8.1
		c10-0.3,6.1-4.2,19.5-3c4.3-6,6.8,0.3,17.8-0.9c8.6-7.9,1.9,8.2-0.7,3.6c-6.6,0.9,4.2,5.4-4.7,6.4c1,3.9,7.1,3,7.2,1.6
		c3.8,2.3,4.3,0.9,10.4-3c-0.3,1.3,8.8-2,1.1-6.3c2.2,6.3-9.9-3.6-2.3-3.2c4.3,1.3,1.3-6.2,11.4,0.4c-3.3,5.3,4.4,2.2,3.7-2.6
		c1.8-6.9-9.7-3.1-15.5-8.9c-4.9-0.1-4.3-2.4-12-1.8c1.1,1.4-4.7-0.9-6.6,1.3c-2.1,4.5-10.9,4.9-16.5,7.8c-7.2-3.4-3,3.9-11.6-1.3
		c-5.3,0.1,2.8,5.1-3.4,5.6c1.2-3.5-12.6-3.3-2.7-1.1c3.1,9.7-12.6,3.8-9.4,0.4c7.4,2.9-4.3-4.6,7.1-5.2c5.7,2.6-1.6-4.7,1.7-3.1
		c4.6,0.2,9.9-4.6,10.9-7.1c-2.3-1.7-4.9-0.9-0.2-2.4c5.5-14-0.1-2.8,0.4-6.6c-3.1-9.3-7,8.3-4.5-4.8c-2,5.8-3.2,1.8-5.3,4.2
		c0.3-2.4,1-3.8,1.5-4.5c-0.5,0.4-1.3,0.9-2.7,1.5c-2.9,6-2,4.7-5.8-1.4c0.7-2.9,1.3-3.7-2.1-6.6c-2.7-5.5,3.3,1.5,2.2-3.7
		c-3.8-0.3,6.3-12.3,4-6.3c3,4.5,2.2,4.1,3.8-1.7c-6.9-0.2-5.6-3.6-3.7-3.2c3.6-5.8,2.4-10.4,9-8c-1.2-1.5,12-6.8,6-12
		c-0.7-2.9,5.2-2.2,4.9-6.5c-1.5,1.8,3.2-4.7,1.6-9.9c-1.7-0.8,11.5-2.4,2.2-2.1c4.4-2.2-9.2-5.1-4-0.9c-0.6,9-2.4-3.1-2.9,4.8
		c8.4,1.5-6.5,2.9-0.6,3.5c4.3,7.3-2.9,2.5-3,5.4c1.7,2.5-0.9,3.9,1.7,9.5c-2.8-1.5-4.7,10.6-10.8,5.3c0.3-6.3,1.9,2.6,8.6-4.4
		c0.6-6.9-14.9,6.2-5.3-3.8c1.5-4.2-5.5,7.6-1.8-3.3c7.8-3.4-2.2-3.7,1.3-5.3c-3.3-1.7,10.4-10,5-10.8c-1.8,1.3-8.1-1.8,1.3-4.3
		c0.3-6.1,2.6,3,2.5,0.6c8.5,0,5.4-4.1,9-6.8c8.8-2.3-8.9-2.3-5,0.8c-4.5-1.6-1.1,4.7-4,0.8c-3.7-2.8,21.7-5.5,9-10.1
		c-4.9-6.4,4.4,0.1,7.2,0.2c-1.7-4.1-2.8-4.1-0.4-7.8c5.9,3.3-2.1-7.9-2.1,2.9c-6-0.2-3.6-0.7-10.5-1.7c3.9-0.1,10.6-0.5,6.2-1.5
		c2.1-0.1,8.9-6.3,3.5-11c-6.2,4,5.2,2.7-4.3,8.5c-0.1-0.9-1.7-4.4-3.5-2.6c3-4.7-4.8-0.7-0.5-5.8c-6.8-6.8-13.7,2.1-2.7-0.9
		c-5,6.6-5,4.3-8.7,0.7c-0.6,4.6-9.4,3,1.7,5c6-0.5-4.2,4.4-6,1.9c-2.3,1.2-5.6,3.6-3.6,8c-4.4-4.6-1.4-4.1,0.2-8.4
		c-6.2,3.5-1.6,3.7-3.1-3.2c-2.9,6.5-10.6,1.9-2.4,6.8c-2.8,9.5-2.6,2.4-9.5-1.9c-3.2,9.6,5.3-1.1,3.8,6.7c-2.3,6,5.5-1.8,3.6,7.5
		c-6.5-0.5-6.1-2.9-4.9,3.2c-2.2,2.8-4.5,2-0.3,7.1c-8.1,1.9-2.7,1.9-3.4,5.9c8.3-5.2,2,8.7-0.2,4.5c-0.7-1.4-3.4,4.4-2,3.8
		c2.4,2.7,4.9,0.7,3.1,5.2c3.4-5.2,1.2-7.3,4.3-2.9c6.3-3.2-5.6,10.7-2.5,5.2c-1.9-3.1-2.9,0.3-3.6,2.2c0.3,0.2-4.8,11.1,0.2,11.3
		c1.3,0.6-2.9,2.1-2.8,3.7c4.2,2.7,3.3,3.5,4.4,7.4c7.7,8.8-7.3,11.6-3.4,6.6c2,2.3-9.5-11.7-4.8-3c-3.1,2.1-0.4,2-1.8,7.4
		c5.2,2.9-3.6,4.3-3.6,2.7c-3.3-0.5-4.8,5.5,3.8,1.8c-4.1,4.8,0.8,3.7-0.2,8.4c-6.9-4-3.7-2.7-6.9,1.8c0.8,1.2-11.8,3.8-2-0.5
		c0.4-10.4-1.1,2.8-4.4-2.4c-3.4-9.1-3.7,10.3-7.2-0.3c-8.3,0,6.7-2.7-0.3-3.7c4.9-1.2,4.5-11.3,1.6-3.7c-6.8-0.7,1.1-9.3-3.7-11.3
		c-5-8.4-4.4,8-6.1-4.5c5.1-1.4-0.1-9.4-4-10.8c-2.7,2.9-5.7,3.4-0.3,6.5c-3.8,1.6,1.9,3.9-4.8,7.1c1.9,9.7,7.2-1.1,3,8.9
		c1.1-0.2-0.4,9.6-1.6,9.1c-3.1-2.8-1.1,8.3,0.5,4.8c7.1,3.5-3.2,17.6,7,6.9c9.5,4,2,6.1-0.2,11.8c-4.7,5.6,0.7,9.1-1.1,10.8
		c0.1,9.3,4.1,15.6,4.3,23.6c-2.6-0.4,3.2,11.2,6,11.4c0.8-9.3-2.8-9.5-5.6-20.5c-0.1-5.3-4.5-21.3,1.1-25.9c1.6-7.3,5-10.4,3.5,1.2
		c1.5-5.2,3.4-4.1,8.9-4.1c0.3,8,6.4,15.8,4.4,14.5c-5.5,1.6,0.8,3.1,1.7,3.3c-4.7,2-2,9.1-3.8,11.8c-2.2,9,0.8,15.4,4.9,19.7
		c-0.4,6.2,2.2,10.8,0.7,19.6c1.5,4.1,2.8,4.6,1.5,9.5c2,2.7-0.8,5,0.4,6.8c0.6-10.3,8.1,5.6,7.2,7.8c0.2,5.8,3.4,6,3,11.3
		c5.1,3.2,0.4,6,5.3,11.9c-1.4,4.8,3.3,0.3,4.1,9.6c0.3,2.3,1,2.3,2.4,7c0.2-2.2,1.5-4.4,2.8,5.5c3-3.7,3.2,8.7,8.8,2.5
		c0.7,3.9,2.1,2.1,5.1,4.2c9.8,4.4,0.6,2.3,3.1,4c2.3,2.6,2.5,5.1,3,9.4c4.3-6.5-1.4,10.2-3.5-0.2c-2.5-7.8-6.9-4.8-4.7-12.4
		c-5.1-2.2-10.8-0.4-2.7,1.4c-1.7,5.4,2,11.8,4.9,10.3c-113.2,52.9,8,1.1,12.1,23.6c-8,2.7,11.9,9.3,9.6,12.5
		c-3.4,4.8-8.8-2.5-0.8,7.2c4.7,7.5,9,8,6,0.6c9.1,6.2-0.5,3.5,6.8,8.7c-0.7-1.1,0.8-2.3,2.1-3.1c2.8,6.3,1.8,1,4.3,2.5
		c1,2.7,3,6.5,4.5,2.9c-0.7,7.1,5.7-2.4,4.6,3.7c1-0.1,17.4,9.9,9.5,3.2c-5.6-1.8-1.1-5.9,2.2,0.3c7.4-0.4,1.6,8.6,5.6,7.4
		c4.7,0.8,2.9,5.4,8.9,6.6c-4.7-2.3-2.2-2.8,0.4-8.1c2.9,4.1,8.8,2.7,11.4,8.3c6.2,2.1-5.8,2.1,6.9,6.9c-3,1.5-3.3-2.3-7.1-1.2
		c2-4.2-3.6,6.5-7.2-1c-4,4.6,3,4.6,3.9,3.8c-4.1-2.1,12.6,4.6,12.1-0.6c5.7,0,4.8-8.1,7.2-4.3c4.3,3.7,2.6,9-3,6.9
		c2.2,1.3,6.5,3.4,7,0.4c3.2,6.1,6.2-1.4,9.3-2.4c3.2-2.5,9.2-6.7,15.6-11c8.7-5.3,14.5-11.6,20.1-12.1c6.2-0.7,7.6-5.5,11.3-3.9
		c6.1,1.2,6.4-9.9,7.3-4.7c3.1-8.3,1.7,1.6,1.9,2.6c5.2-9.1,2.8,2.1,6.1,0.6C246.7,343.6,422.1,503.4,271.3,362.5z M261.3,259.8
		c-6.5,0.7-4.1,0.8-7.4,4.7c-4.9-9.3-7.4-2-1.7,0.6c4.4-2.3,7,5.6,2.5,4.9c-3.2-8.5-8,4.7-0.2,3.9c-0.1,7.3-2.4-0.8-5.8-0.8
		c1.9,9.1,6.5,4.8-0.7,10.4c-2.1-4.6,2.4,8.7-1.9,0.4c-8.6,1,7.1,7.1-0.8,6.5c-1.6,6.4,4.6-0.3,4,4.4c0.6,6.9-9.2,1.5-4.7,5.1
		c5.5-1.4,5.9,10.9,3.3,7.3c-4.6-8.6,0.9,0.2-2.6,0.7c5.6,2.3,1.1,2.3,7.8,4.4c-7,1.1-0.6,2.2,0.2,10.3c2.4,6.5-5.4,0.5-3.4,10.1
		c1.6,4-0.9,2.2-1.2,7.2c-4.4,2.2-3.2,4.5-3.1,8.6c-2.2-0.1-6.8,5.3-4.4,5.3c0.5-0.1,1-0.3,1.5-0.4c-0.7,0.3-1.2,0.4-1.5,0.4
		c-5.8,1.7-3,6-7.4,14.4c4-16.8-4.4,1-4.3,3.5l0,0h0c-1-2.9,0.6-10.5,2.4-9.7c3.6-8.4,7.2-20.6,13.4-29.7c5.8-9.8,1.8-21.4-5.2-26.6
		c-11-6.4,7.1-4.4-1.6-5.9c-6.5,1.7-11.8-9.7-14.2-10.7c1.6,2.5,3,5.3,0.6,3c1.3,4.7,11.9,10.1,8.5,12.2c0.4,1.2,2.1,8.8,2.3,10
		c-1.1-0.3,2,8.1,0.3,7.1c-3.2-2.8-1.2,2.4,1.7,2.8c-2.3,7.2-6.5,2.7-4.4-0.8c-2.9-5.9,1.5-10.8-3.6-11.3c-0.1,0.1-0.2,0.1-0.3,0
		c0.1,0,0.2,0,0.3,0c0.7-0.5,1.1-4.8-1.2-4c-0.1,0.2-0.3,0.3-0.6,0.3c0.2-0.2,0.5-0.3,0.6-0.3c0.7-1.2-2.4-5.1-7.6-8.2
		c-2.7,5-6-2.2,0.4-1.4c-3.2-3.1-7-1.2-3.2-5.7c-1.3-3.1,5.6-8.1-2.4-12c4.5-0.8-5.2-7.7-5.1-10.3c1.9,1.1-3-3.7-4.6-6.9
		c0,0.4-11.2-6.6-2.7-6.7c4,3.4,4.4,2.9,6.1,1.7c-5.2-4.5-5.8-3.6-7.5-9.2c-4.8,3.9-5.2-11.6-8.3-9.9c-6.1-4.1-2.6-14.8,0-14.4
		c6.2,0.4-0.5,5.1-1.8,8c5.7-1.7,5.8-6.7,4.8,4.1c1.6-2.7,8,1.5,7.8,0.5c1.2-2.2,10.2,2.5-0.5-3.6c3.2-6.2,7.3-9.6,6.2-12.1
		c-7.4-2.4-4.9,12-8.6,6.3c-7.1,1.7,10.8-4.5-1.7-4.7c-4.7-3.4,1.2-0.3,3.8-2.9c4.7-3.3,22.5-3.7,28.5-0.1c-8,0.6,6.5,1,11.7,0.8
		c-1.7,4.2-4.9-1.8-5.5,2.9c14.9-0.4,5.4,1.4,6.4,5.6c-3.5-1.9-5.2,0.1-7,1.2c8,0.5,17.6,2.4,19.4-0.8c-8.8-4.4,3.7,0.5,5.3-0.4
		c0.5,1.8,7.6,3.7,9.1,4c0.4,0.1,0.3,0.1,0,0c-0.3-0.1-0.7-0.2-1.5-0.3c-3.5-1-12.6-3.8-4.3-0.1c-10.6-0.3-9.5,0.7-4.6,1.7
		c5.4,1.8,9,12.6,4.6,5.8c-4.6,0.7-7.8-4.4-12.1-4.5c2.8,3.9,0.6,3.8,2.7,7.4c6.7,6.2,14.6,8.2,1,6.8c-6.1-4.8,4.7,9.3-2.4,2.3
		c1.1,13.6,2.4-5.8,7.3-0.2c-4.5-1.2,7.1,8.4,0.6,9C259,252.8,260.1,259.9,261.3,259.8z"
      />

      <!-- <clipPath id="clip">
		<use xlink:href="#path-mandela"/>
	</clipPath> -->
    </svg>
    <svg
      aria-hidden="true"
      class="hidden"
      focusable="false"
      style="width: 0; height: 0; position: absolute"
    >
      <linearGradient id="mandela-gradient">
        <stop offset="0%" stop-color="#cdd0d4" />
        <stop offset="100%" stop-color="#aeb1b3" />
      </linearGradient>
    </svg>
  </div>
</template>

<script>
export default {
  name: "Mandela",
  props: ["playMandelaTimeline"],
  data() {
    return {
      timelineMandela: null,
    };
  },
  watch: {
    playMandelaTimeline() {
      this.timelineMandela.play();
    },
  },
  mounted() {
    const tlMandela = new TimelineMax({
      paused: true,
    });

    tlMandela.timeScale(1.2);

    const layerMandela = "#layer-mandela";
    const pathMandela = "#path-mandela";
    const strokeMandela = "#stroke-mandela";

    tlMandela
      .from(
        strokeMandela,
        10,
        {
          drawSVG: "50% 50%",
          ease: Power3.easeIn,
          autoAlpha: 1,
        },
        0
      )
      .add("addFill", 7)
      .to(
        pathMandela,
        3.6,
        {
          autoAlpha: 1,
          ease: Power3.easeInOut,
        },
        "addFill"
      );
    // .to(
    //     strokeMandela,
    //     .7,
    //     {
    //         autoAlpha: 0,
    //         ease: Power2.easeIn
    //     },
    //     "addFill"
    // )

    this.timelineMandela = tlMandela;
  },
};
</script>

<style scoped>
#layer-mandela {
  width: 24em;
}
#layer-mandela svg {
  width: 100%;
}
#path-mandela {
  visibility: hidden;
  stroke: none;
  fill: url(#mandela-gradient);
}
#stroke-mandela {
  /* visibility: hidden; */
  fill: none;
  stroke-width: 1px;
  stroke: url(#mandela-gradient);
}
/*=============================================
=                  MEDIA QUERIES             =
=============================================*/
@media screen and (max-width: 1440px) {
  #layer-mandela {
    width: 23em;
  }
}
@media screen and (max-width: 1200px) {
  #layer-mandela {
    width: 19em;
  }
}
@media screen and (max-width: 1024px) {
  .portrait #layer-mandela {
    width: 15em;
    align-self: flex-end;
  }
}
@media screen and (max-width: 991px) {
  .landscape #layer-mandela {
    width: 15em;
  }
}
@media screen and (max-width: 767px) {
  .landscape #layer-mandela {
    width: 13em;
  }
}
@media screen and (max-width: 479px) {
  .portrait #layer-mandela {
    width: 12em;
  }
}
@media screen and (max-width: 420px) {
  .portrait #layer-mandela {
    width: 10em;
  }
}
</style>
