<template>
  <div class="post-slider-component" v-if="posts">
    <div class="post-slider-heading">
      <div class="h4">
        {{ storiesHeading }}
      </div>
      <div class="sub">from {{ fromSentence }}</div>
    </div>
    <div class="post-slider-wrap" :data-slides="postCount">
      <div class="post-slider">
        <vue-glide
          :options="options"
          :type="sliderType"
          :autoplay="autoPlayDuration"
          :peek="{ before: peekWidth, after: peekWidth }"
        >
          <vue-glide-slide v-for="(post, i) in posts" :key="i">
            <post-card :post="post"></post-card>
          </vue-glide-slide>
        </vue-glide>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import "vue-glide-js/dist/vue-glide.css";

import { handleResize } from "@/mixins/core.js";
import { Glide, GlideSlide } from "vue-glide-js";
import PostCard from "@/components/ui/PostCard.vue";

export default {
  name: "PostSlider",
  mixins: [handleResize],
  props: ["data"],
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
    PostCard,
  },
  computed: {
    ...mapGetters(["getPosts"]),
    posts() {
      return this.data.postsData
        ? this.data.postsData
        : this.getPosts[this.data.name].data;
    },
    fromSentence() {
      return this.data.from ? this.data.from : "the Londolozi Blog";
    },
    postCount() {
      return this.posts.length;
    },
    sliderType() {
      return this.postCount > 2 ? "carousel" : "slider";
    },
    storiesHeading() {
      return this.data.heading ? `${this.data.heading} Stories` : "Stories";
    },
    autoPlayDuration() {
      return this.data.autoplay ? 7000 : false;
    },
    peekWidth() {
      let width = 129;
      if (this.window.width < 479) {
        width = 110;
      } else if (this.window.width < 767) {
        width = 120;
      }
      console.log(width);
      return width;
    },
  },
  data() {
    return {
      initSlider: false,
      options: {
        focusAt: "center",
        gap: 0,
        animationDuration: 600,
        startAt: 0,
        perView: 1,
        bound: true,
        keyboard: false,
        dragThreshold: 10,
        classes: {
          slider: "slider",
          carousel: "carousel",
          activeSlide: "active",
        },
      },
    };
  },
  watch: {
    posts() {
      this.initSlider = true;
    },
  },
  methods: {},
};
</script>

<style scoped>
.post-slider-heading {
  text-align: right;
  padding: 0 0 0.3rem;
}
.post-slider-heading .h4 {
  text-align: right;
  font-weight: 200;
  padding: 0 0 3px 0;
  font-size: 1.28rem;
}
.post-slider-component {
  margin: 0 0 1.3em;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}
.post-slider-wrap {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 16.25rem;
  max-width: calc(100% + 1.25rem + 1.25rem);
}
.post-slider-wrap::before,
.post-slider-wrap::after {
  content: "";
  position: absolute;
  height: calc(100% - 2.8rem);
  background: #eee;
  width: 2px;
  top: 50%;
  transform: translate(0, -50%);
}
.post-slider-wrap[data-slides="1"]::before,
.post-slider-wrap[data-slides="1"]::after {
  display: none;
}
.post-slider-wrap::before {
  left: 0;
}
.post-slider-wrap::after {
  right: 0;
}
.post-slider {
  width: 34rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
.glide__slides {
  margin: 0;
  padding: 0.9rem 0;
}

.glide__slide {
  cursor: grab;
  transform: scale(0.93);
  transition: 0.4s transform cubic-bezier(0.165, 0.84, 0.44, 1);
}
.glide__slide.active {
  transform: scale(1);
  cursor: pointer;
}

.post-slider .post-card {
  pointer-events: none;
}
.post-slider .glide__slide.active .post-card {
  pointer-events: auto;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/
@media screen and (max-width: 479px) {
  .post-slider-wrap {
  }
  .post-slider-component {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
