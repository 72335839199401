<template>
  <div id="menu">
    <div
      class="menu-button fixed"
      ref="menuButton"
      :style="{ right: scrollBarWidth }"
    >
      <button
        @click="toggleMenu"
        class="hamburger hamburger--spring"
        :class="{ 'is-active': menuOpen }"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
    <transition name="modal-overlay-in">
      <div class="menu-backdrop backdrop fixed" v-if="menuOpen"></div>
    </transition>
    <transition name="modal-in">
      <div
        class="menu height-100 layout-column slideout"
        ref="menu"
        v-show="menuOpen"
      >
        <div class="layout-column menu-body flex-grow" ref="menuBody">
          <nav class="layout-column height-100">
            <div
              id="menu-chapters"
              class="menu-section layout-column flex-noshrink"
            >
              <div
                class="
                  menu-africa
                  flex-noshrink
                  layout-row layout-align-end-start
                  width-100
                "
              >
                <div
                  id="menu-logo"
                  class="
                    londolozi-logo
                    logo-small
                    layout-row layout-align-start-center
                  "
                >
                  <a
                    href="/"
                    class="logo-logo logo-a"
                    @click.prevent="goTo('/')"
                  >
                    <img
                      class="logo-img-logo"
                      :src="`${$urls.img}/londolozi-logo-wink.png`"
                      ref="logoImg"
                    />
                  </a>
                  <div
                    class="h1 logo-text layout-column layout-align-center-start"
                  >
                    <a
                      href="/"
                      class="logo-a logo-text-londolozi trajan"
                      @click.prevent="goTo('/')"
                    >
                      <span ref="logoTextLondolozi">
                        Londoloz
                        <span class="uppercase">i</span>
                      </span>
                    </a>
                    <a
                      href="/"
                      class="logo-a logo-text-africa trajan"
                      @click.prevent="goTo('/')"
                    >
                      <span ref="logoTextAfrica">Impact</span>
                    </a>
                  </div>
                  <div class="logo-africa">
                    <svg-africa ref="logoHeaderImgAfrica"></svg-africa>
                  </div>
                </div>
              </div>
              <div class="menu-link menu-item trajan layout-row">
                <a href="/chapters/" @click.prevent="goTo('/chapters/')"
                  >Chapters</a
                >
              </div>
              <ul
                class="layout-column flex-noshrink layout-align-start-start"
                ref="menuBarsChapters"
              >
                <li
                  class="layout-row menu-bar menu-item flex-grow"
                  v-for="(chapter, i) in getChapterLinks"
                  :key="i"
                  :data-item="chapter.slug.substring(2)"
                  :class="{ expanded: openSubmenu == chapter.slug }"
                >
                  <div
                    class="
                      menu-bar-left
                      layout-column layout-align-center-center
                      flex-initial
                    "
                  >
                    <div class="bar-number trajan lowercase">
                      {{ chapter.roman }}
                    </div>
                    <div class="bar-line"></div>
                    <div class="bar-more">
                      <button
                        @click="toggleSubmenu(chapter.slug)"
                        class="btn btn-expand"
                        v-if="!chapter.externalUrl"
                      >
                        <i class="ai-more"></i>
                      </button>
                      <div style="height: 60px; width: 60px" v-else />
                    </div>
                  </div>
                  <div class="menu-bar-right flex">
                    <img :src="chapter.background" class="menu-background" />
                    <div class="bar-gradient"></div>
                    <a
                      class="h3 menu-bar-title flex-grow"
                      :href="chapter.externalUrl ? chapter.externalUrl : `/chapters/${chapter.slug}/`"
                      @click.prevent="goToParent($event, chapter)"
                    >
                      <span>{{ chapter.title }}</span>
                    </a>
                    <div class="menu-main-image">
                      <img :src="chapter.main" class="menu-main" />
                    </div>
                  </div>
                  <div
                    class="
                      section-navigation
                      menu-bar-parts
                      chapter-navigation
                      layout-align-start-center layout-column
                      section-nav
                    "
                  >
                    <a
                      v-for="part in chapter.parts"
                      :key="part.id"
                      class="btn btn-square btn-square-small btn-section-nav"
                      :title="`Part ${part.id}: ${part.title}`"
                      @click.prevent="
                        goTo(`/chapters/${chapter.slug}/${part.id}`)
                      "
                      :class="{
                        'router-link-active':
                          currentPath == `/chapters/${chapter.slug}/${part.id}`,
                      }"
                    >
                      <span class="nav-part-no">{{ part.id }}</span>
                      <span class="nav-part-title h4">{{ part.title }}</span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div class="menu-footer layout-row flex-initial">
          <div class="footer-logo flex-initial">
            <a
              class="btn btn-logo btn-square"
              title="Londolozi.com"
              target="_blank"
              href="https://www.londolozi.com"
            >
              <img
                :src="`${$urls.img}/londolozi-logo-wink.png`"
                alt="Londolozi logo"
              />
            </a>
          </div>
          <div class="footer-blog flex-initial">
            <a
              class="
                btn btn-text btn-icon-prefix
                layout-row layout-align-center-center
              "
              title="Londolozi Blog"
              target="_blank"
              href="https://blog.londolozi.com"
            >
              <i class="ai-feather"></i>
              <span class="italic grey2">Blog</span>
            </a>
          </div>
          <div
            class="footer-social flex-grow layout-row layout-align-end-center"
          >
            <a
              v-for="social in socials"
              :key="social.name"
              :id="`social-icon-${social.name}`"
              class="btn btn-square btn-social"
              :href="social.link"
              target="_blank"
              :title="`Londolozi on ${social.name}`"
            >
              <i :class="`ai-${social.name}`"></i>
            </a>
          </div>
          <div class="footer-enquire flex-initial">
            <a
              class="btn btn-blue btn-square"
              title="Enquire Now"
              target="_blank"
              href="https://londolozi.com#booking"
            >
              <i class="ai-mail"></i>
            </a>
          </div>
        </div>
        <button
          class="btn-close btn absolute btn-menu-close btn-side"
          type="button"
          @click="toggleMenu"
          ref="menuCloseButton"
        ></button>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { eventListeners, storeScrollBarWidth } from "@/mixins/core.js";
import SvgAfrica from "@/components/misc/SvgAfrica.vue";

export default {
  name: "Menu",
  components: {
    SvgAfrica,
  },
  mixins: [eventListeners, storeScrollBarWidth],
  data() {
    return {
      menuTimeline: null,
      menuTimelineOut: null,
      menuButtonTimeline: null,
      openSubmenu: false,
      currentPath: null,
      durations: {
        open: 0.5,
        close: 0.5,
        showButton: 0.6,
      },
      socials: [
        {
          name: "facebook",
          link: "https://www.facebook.com/londolozigamereserve/",
          color: "#3b5998",
        },
        {
          name: "twitter",
          link: "https://twitter.com/londolozi",
          color: "#00aced",
        },
        {
          name: "pinterest",
          link: "https://za.pinterest.com/londolozi/",
          color: "#cb2027",
        },
        {
          name: "instagram",
          link: "https://www.instagram.com/londolozi/",
          color: "#c32aa3",
        },
        {
          name: "youtube",
          link: "https://www.youtube.com/channel/UCBl15S6iFPFTdEE2qGE1WgA",
          color: "#bb0000",
        },
        {
          name: "vimeo",
          link: "https://vimeo.com/londolozigamereserve",
          color: "#45bbff",
        },
      ],
    };
  },
  computed: {
    ...mapState(["menuOpen", "viewReady", "showIntro", "menuButton"]),
    ...mapGetters(["getChapterLinks"]),
  },
  watch: {
    menuOpen(val) {
      this.updateScrollBarWidth();

      if (val) {
        this.openMenu();
      } else {
        this.closeMenu();
      }
    },
    viewReady(val) {
      if (val) {
        if (this.$route.name !== "view-home") {
          this.showMenuButtonAction();
        }
        this.currentPath = this.$route.path;
      } else {
        this.hideMenuButtonAction();
        this.currentPath = null;
      }
    },
    menuButton(val) {
      if (val) {
        let menuButtonShowDelay =
          this.$route.name === "view-home" ? 0 : this.durations.showButton;

        this.showMenuButton(menuButtonShowDelay);
      } else {
        this.hideMenuButton();
      }
    },
  },
  mounted() {
    //set up menu timeline
    this.createTimelines();
  },
  methods: {
    ...mapActions([
      "toggleMenu",
      "hideMenuButtonAction",
      "showMenuButtonAction",
    ]),
    addScrollbarPadding() {
      if (this.scrollbarWidth) {
        this.setBodyCss("paddingRight", this.scrollbarWidth + "px");
      }
    },
    removeScrollbarPadding() {
      this.setBodyCss("paddingRight", "");
    },
    showMenuButton(menuButtonShowDelay) {
      this.menuButtonTimeline.delay(menuButtonShowDelay).restart(true);
    },
    hideMenuButton() {
      this.menuButtonTimeline.reverse().timeScale(2);
    },
    toggleBodyClass(val) {
      if (val) {
        this.bodyEl.classList.add("no-scroll");
      } else {
        this.bodyEl.classList.remove("no-scroll");
      }
    },
    openMenu() {
      const vm = this;

      this.$nextTick(() => {
        this.$refs.menuBody.scrollTop = 0;
      });

      setTimeout(function () {
        vm.menuTimeline.restart().timeScale(1);
      }, 100);

      setTimeout(function () {
        vm.addScrollbarPadding();
        vm.addScrollbarPaddingToStore();
        vm.toggleBodyClass(true);
      }, 100);

      //add event listeners
      this.addEventListeners();
    },
    closeMenu() {
      this.openSubmenu = false;

      const vm = this;
      setTimeout(function () {
        vm.removeScrollbarPadding();
        vm.removeScrollbarPaddingToStore();
        vm.toggleBodyClass(false);
      }, 400);

      this.menuTimelineOut.restart();
      //this.menuTimeline.reverse().timeScale(1.5);

      //remove event listeners
      this.removeEventListeners();
    },
    toggleSubmenu(chapterSlug = false) {
      this.openSubmenu = this.openSubmenu == chapterSlug ? false : chapterSlug;
    },
    goTo(path) {
      this.$router.push(path);

      const vm = this;
      setTimeout(function () {
        vm.toggleMenu();
      }, 300);
    },
    goToParent(event, chapter) {
      const vm = this;
      setTimeout(function () {
        vm.toggleMenu();
      }, 300);

      if (Boolean(chapter.externalUrl)) {
        window.open(chapter.externalUrl, '_blank').focus();
        return;
      }

      event.preventDefault();

      this.$router.push(`/chapters/${chapter.slug}/`);
    },
    createTimelines() {
      const { menu, menuButton } = this.$refs;
      const vm = this;
      const tlMenu = new TimelineMax({
        paused: true,
      });

      tlMenu.timeScale(0.9);
      //menu timeline
      tlMenu
        .staggerFrom(
          ".menu-item",
          0.4,
          {
            ease: Power1.easeIn,
            x: "+=30",
            autoAlpha: 0,
          },
          0.1,
          0
        )
        .staggerFrom(
          ".menu-background",
          2,
          {
            x: "-=18",
          },
          0.1,
          0.3
        );

      this.menuTimeline = tlMenu;

      const tlMenuOut = new TimelineMax({
        paused: true,
      });

      tlMenuOut.timeScale(1.5);

      //menu timeline
      tlMenuOut
        .staggerTo(
          ".menu-item",
          0.4,
          {
            ease: Power1.easeIn,
            x: "+=30",
            autoAlpha: 0,
          },
          -0.1,
          0
        )
        .staggerTo(
          ".menu-background",
          2,
          {
            x: "-=18",
          },
          -0.1,
          0.3
        );

      this.menuTimelineOut = tlMenuOut;

      //menu button timeline
      const tlMenuButton = new TimelineMax({
        paused: true,
      });

      tlMenuButton.to(menuButton, 0.3, {
        y: "0",
        autoAlpha: 1,
        ease: Power2.easeInOut,
      });

      this.menuButtonTimeline = tlMenuButton;
    },
    onEscape(e) {
      if (this.menuOpen && e.keyCode == 27) {
        this.toggleMenu();
      }
    },
    onClick(e) {
      if (this.menuOpen && e.target.classList.contains("backdrop")) {
        this.toggleMenu();
      }
    },
  },
};
</script>

<style scoped>
#menu-logo {
  padding: 0.4rem 1.1rem 0 0;
}
.menu {
  position: fixed;
  max-width: 100%;
  height: 100%;
  background: white;
  top: 0;
  right: 0;
  z-index: 7;
  width: 38.75em;
  min-width: 337px;
}
.menu-bar {
  min-height: 10em;
  height: 10em;
}
.menu-bar-left,
.menu-bar-right {
  z-index: 1;
  position: relative;
}
.menu-bar-left {
  width: 4.4rem;
  padding-left: 1rem;
  background: var(--grey);
  background: white;
}
.menu-bar-right {
  overflow: hidden;
}
.bar-number {
  color: #333;
  font-size: 2.909rem;
  height: 1.34em;
  letter-spacing: -0.1em;
  text-indent: -0.172em;
  padding-top: 0.086em;
  pointer-events: none;
  margin-top: 0.129em;
}
.bar-gradient {
  background: linear-gradient(
    to right,
    rgba(238, 238, 238, 1) 0%,
    rgba(252, 252, 252, 0.9) 25%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(252, 252, 252, 0.9) 25%,
    rgba(255, 255, 255, 0) 100%
  );
  position: absolute;
  z-index: 1;
  width: 70%;
  width: 80%;
  height: 100%;
}
.bar-line {
  width: 2.5em;
  background-color: var(--grey);
  background-color: transparent;
  height: 0.125em;
  margin: 0.875em 0 0.188em;
}
.btn-expand {
  color: #d5d5d5;
}
.btn-expand i {
  padding: 0 1.4rem 0 0;
  transform: rotate(90deg);
  padding: 0 0 1.4rem 0;
  transform: rotate(0deg);
  font-size: 1.6rem;
  width: auto;
}
.btn-expand:hover,
.expanded .btn-expand {
  color: var(--blue);
}
.btn-expand:hover i,
.expanded .btn-expand i {
  font-size: 1.8rem;
}
.menu-body {
  overflow: hidden;
  overflow-y: auto;
}
.menu-body nav {
  padding: 0;
}
.menu-button {
  top: 0;
  right: 0;
  z-index: 7;
  transform: translate(0, 3.75em);
  opacity: 0;
  font-size: var(--button-rem);
}
.menu-button button {
  width: 3.75em;
  height: 3.75em;
  background: black;
}
.menu-background {
  object-fit: cover;
  object-position: bottom right;
  width: 104%;
  height: auto;
  position: absolute !important;
  left: 4% !important;
  filter: url(#filter-menu-blue) grayscale(0.75);
  top: auto !important;
  bottom: -7%;
  transition: width 0.6s ease-out, opacity 0.6s ease-out;
  opacity: 0.8;
}
.menu-bar:hover .menu-background {
  filter: url(#filter-menu-blue) grayscale(0);
  width: 106%;
  opacity: 1;
}
.menu-section ul {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  padding: 0 0 0 1.8rem;
}
.menu-section li {
  overflow: hidden;
  position: relative;
  pointer-events: all !important;
  width: 50%;
  width: 100%;
  padding: 1px 1px 0 0;
}

.menu-section li a {
  padding: 1.48rem 0 0 1.21rem;
  z-index: 2;
  display: flex !important;
  color: black;
  font-size: 1.41rem;
  line-height: 1em;
  width: 100%;
  height: 100%;
  letter-spacing: 0.016em;
}
.menu-section li a {
  padding: 1.55rem 0 0 1.8rem;
  font-weight: normal;
  letter-spacing: 0;
  font-size: 1.4rem;
  line-height: 1.3;
  font-family: Georgia, "Times New Roman", Times, serif;
  text-transform: capitalize;
  font-style: italic;
  color: #444;
}
.menu-section li a span {
  max-width: 5.8em;
  max-width: 7em;
}
.menu-link {
  margin: 0 0 1.2rem;
  letter-spacing: 0.07em;
  justify-content: start;
  align-items: center;
  font-size: 1.412rem;
  line-height: 1.2;
  text-transform: capitalize;
  padding: 1.7rem 2.424rem 1.4rem 2.424rem;
  padding: 0 2.424rem 1.2rem 2.424rem;
}
.menu-link::after {
  content: "";
  height: 2px;
  background: var(--blue);
  width: 4rem;
  position: absolute;
  bottom: 0;
}
.menu-link a {
  color: var(--grey2);
  color: #333;
}
.menu-main-image {
  position: absolute;
  height: 60%;
  z-index: 1;
  width: 50%;
  right: 20%;
  bottom: 0;
  text-align: right;
}
.menu-main {
  max-width: 100%;
  max-height: 100%;
  filter: drop-shadow(3px 3px 5px rgba(255, 255, 255, 0.76));
  transition: 0.6s ease-out;
}

.menu-bar:hover .menu-main {
  transform: scale(1.03) translate(4px, 0);
}

li[data-item="new-systems-for-living"] .menu-main-image {
  top: 13%;
  right: 40%;
  height: 55%;
}
li[data-item="conservation-development-model"] .menu-main-image {
  height: 94%;
  bottom: -15%;
  right: 20%;
}
li[data-item="social-enterprise"] .menu-main-image {
  height: 80%;
  right: 38%;
}
li[data-item="rise-of-consciousness"] .menu-main-image {
  height: 68%;
  bottom: 8%;
  right: 30%;
}
/* eagle
li[data-item="social-enterprise"] .menu-main-image {
  top: 15%;
  height: 55%;
} */

li[data-item="new-systems-for-living"] .menu-background {
  bottom: -16%;
}
li[data-item="conservation-development-model"] .menu-background,
li[data-item="ultimate-photographic-safari-journey"] .menu-background {
  bottom: -38%;
}
li[data-item="social-enterprise"] .menu-background {
  bottom: -35%;
}
li[data-item="londolozi-philanthropy-journey"] .menu-background {
  bottom: -37%;
}

li[data-item="impact-stories"] .menu-background {
    bottom: -50%;
}
li[data-item="impact-stories"] .menu-main-image {
  height: 85%;
  bottom: -10%;
  right: 28%;
}

.menu-africa {
  padding: 0;
  margin-top: auto;
  margin-bottom: 2rem;
}
.menu-footer {
  border-top: none;
  box-shadow: 1px 0 4px rgba(0, 0, 0, 0.1);
  height: 3.813em;
  font-size: var(--button-rem);
  z-index: 2;
}
.menu-footer .btn {
  height: 100%;
}
.menu-footer .btn-logo,
.menu-footer .btn-text.btn-icon-prefix {
  border-right: 1px solid #eeeeee;
}
.modal-overlay-in-leave-active {
  transition-duration: 0.8s;
}

.modal-in-enter-active {
  transition: 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.modal-in-leave-active {
  transition: 0.6s 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.menu-bar-parts {
  left: 5.1rem;
  position: absolute;
  top: 0;
  z-index: 2;
  width: calc(100% - 5.1rem);
  overflow: hidden;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  transform: translate(0, -100%);
  opacity: 0;
  transition: 0.4s;
}
.expanded .menu-bar-parts {
  transform: translate(0, 0);
  opacity: 1;
}
.menu-section li .menu-bar-parts a {
  padding: 0;
  font-size: 1.41rem;
  line-height: 1em;
  font-weight: 200;
  letter-spacing: 0.016em;
  font-family: "din";
  text-transform: uppercase;
}
.menu-section li .menu-bar-parts a.btn-section-nav {
  width: 100%;
  height: 25%;
  align-items: center;
  justify-content: start;
  flex: 1 1 100%;
  max-height: 1.773em;
  border-bottom: 1px solid white;
  font-style: normal;
}
.menu-section li .menu-bar-parts a.btn-section-nav span {
  display: flex;
  white-space: nowrap;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  color: #333;
}
.menu-section li .menu-bar-parts a.btn-section-nav .nav-part-title {
  flex: 1 0 auto;
  padding: 0 0.7em;
  max-width: none;
}
.menu-section li .menu-bar-parts a.btn-section-nav .nav-part-no {
  flex: 0 0 1.773em;
  width: 1.773em;
  justify-content: center;
  align-items: center;
  background: var(--grey);
  color: var(--grey2);
}
.menu-section
  li
  .menu-bar-parts
  a.btn-section-nav.router-link-active
  .nav-part-no,
.menu-section li .btn-section-nav.router-link-active .nav-part-title,
.menu-section li .menu-bar-parts a.btn-section-nav:hover .nav-part-no {
  background: var(--blue);
  color: white;
}
.menu-section li a.btn-section-nav:hover .nav-part-title {
  padding-left: 14px;
}
.menu-section li .menu-bar-parts a.btn-section-nav {
  transition: 0.25s ease-in;
  opacity: 0;
}
.menu-section li .menu-bar-parts a.btn-section-nav:nth-child(2) {
  transition-delay: 0.2s;
}
.menu-section li .menu-bar-parts a.btn-section-nav:nth-child(3) {
  transition-delay: 0.4s;
}
.menu-section li .menu-bar-parts a.btn-section-nav:nth-child(4) {
  transition-delay: 0.6s;
}
.menu-section li .menu-bar-parts a.btn-section-nav:nth-child(5) {
  transition-delay: 0.8s;
}
.menu-section li .menu-bar-parts a.btn-section-nav:nth-child(6) {
  transition-delay: 1s;
}
.menu-section li.expanded .menu-bar-parts a.btn-section-nav {
  opacity: 1;
}
.menu-section li.expanded .menu-bar-parts a.btn-section-nav:nth-child(2) {
  transition-delay: 0.2s;
}
.menu-section li.expanded .menu-bar-parts a.btn-section-nav:nth-child(3) {
  transition-delay: 0.4s;
}
.menu-section li.expanded .menu-bar-parts a.btn-section-nav:nth-child(4) {
  transition-delay: 0.6s;
}
.menu-section li.expanded .menu-bar-parts a.btn-section-nav:nth-child(5) {
  transition-delay: 0.8s;
}

.btn-social {
  font-size: var(--button-rem);
  width: 16.666%;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/
@media screen and (max-width: 1600px) {
  .bar-number {
    height: 1.2em;
  }
  .btn-social {
    font-size: 15.5px;
  }
}
@media screen and (max-width: 1440px) {
  .bar-number {
    font-size: 2.8rem;
    height: 1.1em;
  }
  .btn-social {
    font-size: 14.5px;
  }
}
@media screen and (max-width: 767px) {
  .menu {
    max-width: calc(100% - 49px);
  }
  .bar-number {
    height: 1em;
  }
  .menu-main-image {
    margin-right: -5%;
  }
  .menu-section li a {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 540px) {
  .btn-social {
    font-size: 13px;
    width: 20%;
  }
  #social-icon-pinterest {
    display: none;
  }
  .menu-main-image {
    margin-right: -10%;
  }
  .menu-section li a {
    font-size: 1.2rem;
    padding-top: 1.7rem;
  }
  .menu-bar {
    min-height: 9em;
    height: 9em;
  }
  li[data-item="conservation-development-model"] .menu-main-image {
    height: 90%;
  }

  .bar-number {
    font-size: 2.7rem;
    padding-top: 0.24em;
  }
  .btn-expand i {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 479px) {
  .menu {
    max-width: calc(100% - 24px);
  }
  .bar-number {
    height: 1.06em;
    padding-top: 0.28em;
  }
  .menu-section li a {
    font-size: 1.1rem;
  }
  #menu-logo {
    font-size: 11px;
  }
}
@media screen and (max-width: 420px) {
  .menu {
    max-width: calc(100% - 23px);
  }
  .btn-social {
    font-size: 12px;
  }
  .btn-text.btn-icon-prefix {
    padding: 0 1.063em 0 1.063em;
  }
  .menu-footer .btn-text.btn-icon-prefix span {
    display: none;
  }
  .btn-icon-prefix i {
    padding-right: 0;
  }
}
@media screen and (max-width: 400px) {
  .menu-main-image {
    margin-right: -18%;
  }
  li[data-item="social-enterprise"] .menu-background {
    bottom: -20%;
  }
  li[data-item="conservation-development-model"] .menu-background,
  li[data-item="ultimate-photographic-safari-journey"] .menu-background {
    bottom: -19%;
  }
  li[data-item="social-enterprise"] .menu-main-image {
    height: 75%;
    right: 38%;
  }
  li[data-item="conservation-development-model"] .menu-main-image {
    height: 85%;
    bottom: -10%;
    right: 18%;
  }
  li[data-item="rise-of-consciousness"] .menu-main-image {
    height: 63%;
    bottom: 8%;
    right: 31%;
  }
  li[data-item="impact-stories"] .menu-background {
    bottom: -20%;
  }
  li[data-item="impact-stories"] .menu-main-image {
    height: 85%;
    bottom: -10%;
    right: 28%;
  }
}
</style>
