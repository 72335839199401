<template>
  <div id="view-home" class="view" ref="scrollDiv">
    <div class="scene-wrap" ref="scene">
      <div class="scene">
        <div
          class="layer parallax-item"
          v-for="(image, i) in parallax.images"
          :key="i"
          :data-depth="parallax.depths[i]"
        >
          <img :src="image" />
        </div>
      </div>
      <div class="view-images absolute">
        <img
          class="ink absolute layer parallax-item"
          ref="ink"
          :src="images[0]"
          data-depth="0.1"
        />
        <div
          class="eagle-wrap absolute layer parallax-item"
          data-depth="0.2"
          data-invert-x="false"
          data-invert-y="false"
        >
          <img class="eagle" ref="eagle" :src="images[1]" />
        </div>
      </div>
      <div class="view-overlay overlay"></div>
      <div class="view-contents layout-row layout-align-center-center">
        <div class="content-left flex layout-align-center-center layout-column">
          <info-modal-box
            id="intro-box"
            :show="showInfoBoxes"
            class="parallax-relative parallax-item"
            data-depth="0.4"
            :data="data.boxes.info_1"
            :timescale="1.35"
            :headingDepth="0.05"
            :headingWidth="'70%'"
            :htmlText="true"
          >
          </info-modal-box>
        </div>
        <div class="content-right flex layout-align-center-start layout-column">
          <div
            class="
              londolozi-logo
              layout-column layout-align-center-center
              logo-main
              parallax-relative parallax-item
            "
            data-depth="0.14"
            data-invert-x="false"
            ref="logo"
          >
            <img
              ref="logoImgLogo"
              class="logo-img-logo"
              :src="`${$urls.img}/londolozi-logo-wink.${winkExt}`"
            />
            <img
              class="hidden preload"
              :src="`${$urls.img}/londolozi-logo-wink.gif`"
            />
            <div class="logo-text-londolozi trajan" ref="logoTextLondolozi">
              LondolozI
            </div>
            <div
              class="logo-text-africa trajan parallax-relative parallax-item"
              ref="logoTextAfrica"
              data-depth="0.05"
            >
              Impact
            </div>
            <div class="logo-img-africa absolute" ref="logoAfrica">
              <svg-africa ref="logoImgAfrica"></svg-africa>
            </div>
          </div>

          <div
            class="lead-wrap parallax-relative parallax-item"
            data-depth="0.2"
          >
            <p class="lead" ref="lead">{{ data.meta.description }}</p>
            <div class="lead-line" ref="leadLine"></div>
          </div>

          <div class="home-button" ref="button">
            <router-link
              class="btn btn-primary parallax-relative parallax-item"
              data-depth="0.3"
              data-invert-x="false"
              to="/chapters/1-new-systems-for-living/"
            >
              <span>Go to Chapters</span>
              <i class="ai-right"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <modal-main
      v-for="modal in data.modals"
      :data="modal"
      :key="modal.name"
    ></modal-main>
  </div>
</template>

<script>
import InfoModalBox from "@/components/ui/InfoModalBox.vue";
import ModalMain from "@/modals/ModalMain.vue";
import SvgAfrica from "@/components/misc/SvgAfrica.vue";

import {
  storeDataLoading,
  beforeLeaveRoute,
  loadImages,
  winkLogoMixin,
  menuFunctions,
  introSkip,
} from "@/mixins/core.js";
import { homeTimelines } from "@/mixins/home/timelines.js";

export default {
  name: "ViewHome",
  metaInfo() {
    return {
      title: "Londolozi Impact",
      titleTemplate: null,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.data.meta.description,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `${this.$urls.buildUrl}` + window.location.pathname,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: "Londolozi Impact",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.data.meta.description,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: `${this.$urls.img}/opengraph/home.jpg`,
        },
      ],
    };
  },
  components: {
    InfoModalBox,
    ModalMain,
    SvgAfrica,
  },
  mixins: [
    storeDataLoading,
    beforeLeaveRoute,
    loadImages,
    winkLogoMixin,
    homeTimelines,
    menuFunctions,
    introSkip,
  ],
  data() {
    return {
      viewEnterDelay: 500,
      viewLeaveDuration: 1200,
      timelineTimeScale: 0.7,
      homeTimeline: null,
      homeTimelineOut: null,
      homeLogoTimeline: null,
      showInfoBoxes: false,
      images: [
        this.$urls.img + "/home/ink.png",
        this.$urls.img + "/home/eagle-small2.png",
      ],
      parallax: {
        images: [
          this.$urls.img + "/home/land.png",
          this.$urls.img + "/home/trees.png",
          this.$urls.img + "/home/hedges.png",
          this.$urls.img + "/home/eles.png",
          this.$urls.img + "/home/sand.png",
        ],
        depths: [0.14, 0.2, 0.3, 0.5, 0.9],
        options: {
          frictionX: 0.075,
          frictionY: 0.075,
          hoverOnly: true,
          scalarX: device.type == "mobile" ? 4.5 : 3,
          scalarY: device.type == "mobile" ? 0.75 : 1.5,
          selector: ".layer, .parallax-relative",
        },
        parallax: null,
      },
    };
  },
  computed: {
    bodyEl() {
      return document.body;
    },
    data() {
      return this.$store.state.data.OUTLINE.data.home;
    },
    imagesToLoad() {
      return this.images.concat(this.parallax.images);
    },
    layersX() {
      return this.parallax.depths.map((depth) => "+=" + depth * 5.55 + "vw");
    },
    layersY() {
      return this.parallax.depths.map((depth) => "+=" + depth * 2 + "%"); //3.75
    },
    layersScale() {
      return this.parallax.depths.map((depth) => depth / 10 + 1);
    },
    layersXOut() {
      return this.parallax.depths.map((depth) => "-=" + depth * 5.55 + "vw");
    },
    layersYOut() {
      return this.parallax.depths.map((depth) => "-=" + depth * 2 + "%"); //3.75
    },
    blocks() {
      if (this.data.modals) {
        const blocks = this.data.modals.map((modal) => modal.blocks);
        return []
          .concat(...blocks)
          .filter(
            (block) => block.type == "postslider" && block.data.src == "blog"
          );
      }
      return false;
    },
    postsInModals() {
      if (this.data.posts) {
        return this.data.posts;
      }
      if (this.blocks) {
        //extract posts from modals blocks
        return this.blocks.flatMap((block) => block.data);
      }
      return false;
    },
  },
  created() {
    //load relevant data for this chapter
    this.preloadImages(this.imagesToLoad, this.initView);
  },
  watch: {
    menuOpen(val) {
      if (!val && this.leaving) {
        console.log("leaving view and closing menu");
        return;
      } else {
        this.enableParallax(val);
      }
    },
    modalOpen(val) {
      this.enableParallax(val);
    },
    postsInModals(val) {
      if (val) {
        //get posts in modals
        console.log("%c%s", "color: red;", "getpostsinmodals");
        if (this.postsInModals) {
          this.postsInModals.forEach((post) => {
            this.getPostsData(post);
          });
        }
      }
    },
  },
  mounted() {
    //change duration if intro shown
    // if (this.introShown) {
    //   this.viewEnterDelay = 0;
    //   this.timelineTimeScale = 0.7;
    // }
    //create timeline
    this.createTimelines();

    //get posts if any in modals
    if (this.postsInModals) {
      this.postsInModals.forEach((post) => {
        this.getPostsData(post);
      });
    }
  },
  methods: {
    scrollToBottom() {
      this.$refs.scrollDiv.scrollTop = this.$refs.scrollDiv.scrollHeight;
    },
    scrollToTop() {
      var position = this.$refs.scrollDiv.scrollTop;
      if (position) {
        this.$refs.scrollDiv.scrollBy(
          0,
          -Math.max(1, Math.floor(position / 10))
        );
        const scrollAnimation = setTimeout(this.scrollToTop, 30);
      } else clearTimeout(this.scrollAnimation);
    },
    viewShow() {
      // if(this.introSkip){
      //   this.homeTimeline.timeScale(0.7);
      // }
      this.playTimeline();
    },
    showViewContents() {
      this.showInfoBoxes = true;
    },
    viewLeaveAnimations(to, from) {
      //disable parallax
      this.enableParallax(false);

      //close info box
      this.showInfoBoxes = false;

      //exit timeline
      this.homeTimelineOut.play();
      this.bodyEl.classList.remove("items-shown");
    },
    createParallax() {
      if (this.disableParallax) return;

      this.bodyEl.classList.add("items-shown");

      const vm = this;
      this.parallax.parallax = new this.$parallax(this.$refs.scene, {
        ...vm.parallax.options,
      });
    },
    enableParallax(val) {
      if (this.disableParallax) return;

      if (!this.parallax.parallax) return;

      val ? this.parallax.parallax.disable() : this.parallax.parallax.enable();
    },
  },
};
</script>

<style scoped>
.view-contents {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  position: relative;
  transform-style: preserve-3d;
  z-index: 2;
  max-width: 1940px;
  margin-left: auto;
  margin-right: auto;
}
.landscape .view-contents {
  padding-bottom: 4rem;
}
.view-overlay {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 70%
  );
  z-index: 2;
}
.content-right {
  margin-bottom: 14%;
  margin-top: 5%;
}
.content-left {
  max-width: 40%;
}
.logo-main {
  margin-bottom: 5.063em;
}
.parallax-relative {
  position: relative !important;
  display: flex !important;
}
.lead-wrap {
  margin-left: 6%;
}
.lead {
  line-height: 2.1;
  max-width: 35.752em;
}
.home-button {
  margin: 1.688em 0 0 21.62%;
}
#intro-box {
  margin-left: 21%;
  margin-bottom: 6%;
  justify-self: flex-start;
}
.view-images {
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.eagle-wrap {
  top: 6vw !important;
  left: auto !important;
  right: 12vw;
  z-index: 5;
}
.eagle {
  max-width: 3.75rem;
}
.ink {
  right: -12rem;
  top: -12vw !important;
  left: auto !important;
  max-width: 52%;
}
.scene-wrap {
  position: absolute !important;
  min-height: 100%;
  width: 100%;
  pointer-events: auto !important;
  opacity: 0;
  overflow: hidden;
}
.scene {
  position: absolute;
  width: 110vw;
  height: 50vw;
  left: -5vw;
  bottom: -4vw;
  pointer-events: none;
}
.scene .layer {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-origin: center left;
}
.scene .layer img {
  width: 100%;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  height: 100%;
}
.logo-img-logo {
  transform-origin: center center;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 1600px) {
  .lead {
    line-height: 2;
  }
}
@media screen and (max-width: 1200px) {
  .content-right {
    margin-bottom: 13%;
    margin-right: 2%;
  }
  .content-left {
    max-width: 39%;
    margin-left: 2%;
  }
  #intro-box {
    margin-left: 0;
  }
  .lead-wrap {
    margin-left: 2.7%;
  }
  .lead {
    max-width: 30.8em;
  }
  .ink {
    top: -8vw !important;
  }
  .portrait .scene {
    left: 44%;
    transform: translate(-50%, 0);
    width: 125vw;
    bottom: -2vw;
  }
  .logo-main {
    margin-bottom: 6em;
  }
}
@media screen and (max-width: 1200px) and (min-width: 701px) {
  .portrait .logo-main {
    padding-bottom: 1.8vh;
  }
  .portrait .lead {
    line-height: 2.1;
  }
  .portrait .home-button {
    padding-top: 2vh;
    margin-bottom: 9%;
  }
  .portrait #intro-box {
    margin-top: 11vh;
    margin-bottom: 17%;
  }
  .portrait .ink {
    top: -4% !important;
    max-width: 58%;
  }
}
@media screen and (max-width: 991px) {
  .landscape .view-contents {
    padding-bottom: 0;
  }
  .lead {
    max-width: 25.4em;
  }
  .content-left {
    max-width: 44%;
  }
  .ink {
    top: -13vw !important;
    max-width: 54%;
  }
  /deep/ .info-box p span span {
    display: none;
  }
  #intro-box {
    margin-bottom: 0;
    margin-top: 13%;
  }
  .landscape .content-right,
  .landscape .content-left {
    padding-bottom: 12vh;
  }
}
@media screen and (max-width: 767px) {
  .info-box {
    font-size: 13.5px;
    width: 20em;
  }
  .eagle {
    max-width: 3rem;
  }
  .lead {
    padding-right: 9%;
  }
  .content-right {
    margin-bottom: 18%;
  }
}

@media screen and (max-width: 700px) {
  .landscape .content-right,
  .landscape .content-left {
    padding-bottom: 0;
  }
  .view-contents {
    flex-direction: column;
  }
  .content-left {
    order: 2;
    width: 100%;
    padding: 0 8%;
    max-width: none;
    margin: 0 0 18%;
  }
  .content-right {
    order: 1;
    width: 100%;
    margin: 8% 0 0 0;
    align-items: center;
    padding: 0 8%;
  }
  #intro-box {
    margin-top: 1%;
  }
  /deep/ .info-box {
    width: 23em;
    padding: 2.8em 2.4em;
    max-width: 100%;
  }
  .lead-wrap {
    margin-left: 0px;
  }
  .lead {
    max-width: 36em;
    padding-right: 0;
  }
  .home-button {
    margin: 4% 0 0 0;
    align-self: flex-end;
  }
  .home-button .btn-primary {
    font-size: 17px;
  }
  .ink {
    top: 2.5% !important;
    max-width: 50%;
    right: -15%;
  }
  .logo-main {
    font-size: 10px;
    margin-bottom: 6em;
    margin-top: auto;
  }
  .eagle {
    max-width: 2.8rem;
  }
  .eagle-wrap {
    top: 11% !important;
    right: 7vw;
  }
}
@media screen and (max-width: 620px) {
  .eagle {
    max-width: 2.5rem;
  }
  .eagle-wrap {
    top: 16% !important;
    right: 7vw;
  }
  .ink {
    top: 12% !important;
    max-width: 50%;
    right: -13%;
  }
}
@media screen and (max-width: 479px) {
  .lead {
    font-size: 12px;
    line-height: 1.9;
  }
  .home-button .btn-primary {
    font-size: 16px;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 450px) {
  .logo-main {
    font-size: 8.5px;
  }
  .ink {
    top: 16% !important;
    max-width: 58%;
    right: -11%;
  }
}
</style>
