<template>
  <modal-content
    :modalName="data.name"
    :title="data.title"
    :bottomDecal="data.bottom_image"
    :topDecal="data.top_image"
    :hasPattern="data.pattern"
    :category="data.category"
  >
    <template v-slot:content>
      <h4 v-if="data.subtitle" class="subtitle">{{ data.subtitle }}</h4>
      <component
        v-for="(block, i) in data.blocks"
        :key="i"
        :is="block.component"
        :data="block.data"
        :class="block.classes"
        class="wp-block"
      >
      </component>
    </template>
  </modal-content>
</template>

<script>
import BlockHtml from "@/components/ui/BlockHtml.vue";
import ImageWithCaption from "@/components/ui/ImageWithCaption.vue";
import VideoVimeo from "@/components/ui/VideoVimeo.vue";
import VideoYoutube from "@/components/ui/VideoYoutube.vue";
import VideoNative from "@/components/ui/VideoNative.vue";
import ButtonDownload from "@/components/ui/ButtonDownload.vue";
import PostSlider from "@/components/ui/PostSlider.vue";
import ImageList from "@/components/ui/ImageList.vue";
import Flywheels from "@/components/ui/Flywheels.vue";

import ModalContent from "@/components/ui/Modal.vue";

export default {
  name: "ModalTest",
  data() {
    return {};
  },
  props: ["data"],
  components: {
    BlockHtml,
    ImageWithCaption,
    VideoVimeo,
    VideoYoutube,
    VideoNative,
    ButtonDownload,
    PostSlider,
    ImageList,
    Flywheels,
    ModalContent,
  },
};
</script>

<style scoped>
.modal-body > p:last-child {
  margin: 0;
}
</style>
