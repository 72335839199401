<template>
  <div class="imagelist">
    <div
      class="imagelist-row layout-row layout-align-start-start"
      v-for="(item, i) in data.items"
      :key="i"
      :class="{ 'imagelist-row-reverse': !item.swap }"
    >
      <div v-if="!item.swap" class="imagelist-text" v-html="item.text"></div>
      <div class="imagelist-icon">
        <img v-if="item.image" :src="item.image" />
      </div>
      <div v-if="item.swap" class="imagelist-text" v-html="item.text"></div>
    </div>
  </div>
</template>

<script scoped>
export default {
  name: "ImageList",
  props: ["data"],
};
</script>

<style scoped>
.imagelist-row {
  margin-bottom: 1.7rem;
}
.imagelist img {
  max-width: 100%;
  margin: 0;
}
.imagelist-row-reverse img {
  margin-left: -0.6em;
}
.imagelist-row > div:first-child {
  width: 21%;
  min-width: 60px;
  flex: 0 0 auto;
  padding: 0.3rem 0.3% 0 0;
}
.imagelist-row > div:last-child {
  padding-left: 4%;
}
/deep/ .imagelist-text *:last-child {
  margin-bottom: 0;
}
/deep/ .imagelist-row .pink {
  font-family: "din";
  text-transform: uppercase;
  font-size: 1.2em;
  line-height: 1.2em;
  display: inline-block;
}
</style>
