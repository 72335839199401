<template>
  <div id="svg-definitions" class="hidden">
    <svg class="defs-only">
      <filter id="filter-menu-blue">
        <feColorMatrix
          type="matrix"
          values=".33 .33 .33 0 0
              .33 .33 .33 0 0
              .33 .33 .33 0 0
              0 0 0 1 0"
        ></feColorMatrix>

        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR
            type="table"
            :tableValues="colorFilters.menuBlue.R"
          ></feFuncR>
          <feFuncG
            type="table"
            :tableValues="colorFilters.menuBlue.G"
          ></feFuncG>
          <feFuncB
            type="table"
            :tableValues="colorFilters.menuBlue.B"
          ></feFuncB>
        </feComponentTransfer>
      </filter>
      <filter id="filter-blue">
        <feColorMatrix type="matrix" :values="grey"></feColorMatrix>
      </filter>
    </svg>
  </div>
</template>

<script>
import { colorFilterValues } from "@/mixins/chapters/slider.js";
import { colorFilterBluePulse } from "@/mixins/svgFilters.js";

export default {
  name: "SvgDefs",
  mixins: [colorFilterValues, colorFilterBluePulse],
  data() {
    return {};
  },
};
</script>

<style></style>
