<template>
  <div
    id="header"
    class="absolute header flex layout-row layout-align-start-center width-100"
  >
    <div class="logo-wrap">
      <div
        id="header-logo"
        ref="headerLogo"
        class="londolozi-logo logo-small layout-row layout-align-start-center"
      >
        <router-link class="logo-logo logo-a" to="/">
          <img
            class="logo-img-logo"
            :src="`${$urls.img}/londolozi-logo-wink.${winkExt}`"
            ref="logoImg"
          />
        </router-link>
        <h1 class="logo-text layout-column layout-align-center-start">
          <router-link class="logo-a logo-text-londolozi trajan" to="/">
            <span ref="logoTextLondolozi">
              Londoloz
              <span class="uppercase">i</span>
            </span>
          </router-link>
          <router-link class="logo-a logo-text-africa trajan" to="/">
            <span ref="logoTextAfrica">Impact</span>
          </router-link>
        </h1>
        <div class="logo-africa">
          <svg-africa ref="logoHeaderImgAfrica"></svg-africa>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgAfrica from "@/components/misc/SvgAfrica.vue";

import { mapState } from "vuex";
import { winkLogoMixin } from "@/mixins/core.js";

export default {
  name: "Header",
  components: {
    SvgAfrica,
  },
  mixins: [winkLogoMixin],
  data() {
    return {
      logoTimeline: null,
    };
  },
  computed: {
    ...mapState(["viewLoaded", "viewReady"]),
  },
  watch: {
    viewReady(val) {
      if (this.$route.name !== "view-home") {
        if (val) {
          this.logoTimeline.play();
        } else {
          this.logoTimeline.reverse();
        }
      }
    },
  },
  mounted() {
    this.createTimeline();
  },
  methods: {
    createTimeline() {
      const { headerLogo, logoImg, logoTextLondolozi, logoTextAfrica } =
        this.$refs;

      const tlLogo = new TimelineMax({
        paused: true,
      });

      tlLogo
        .set(headerLogo, {
          y: "0",
          delay: 0.6,
        })
        .set([logoImg, logoTextLondolozi, logoTextAfrica], {
          autoAlpha: 0,
          y: "-60",
        })
        .staggerTo(
          [logoImg, logoTextLondolozi, logoTextAfrica],
          0.3,
          {
            y: 0,
            autoAlpha: 1,
            ease: Power2.easeInOut,
          },
          0.02
        );

      this.logoTimeline = tlLogo;
    },
  },
};
</script>

<style scoped>
#header-logo {
  transform: translate(0, -60px);
}
.logo-wrap {
  transition: transform 0.6s 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
.menu-open .logo-wrap {
  transform: translate(-100%, 0);
  transition: transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}
</style>
