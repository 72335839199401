<template>
  <div class="flywheels">
    <div v-for="(flywheel, i) in data" :key="i" class="flywheel-wrap">
      <h4 class="flywheel-title">Flywheel #{{ i + 1 }}</h4>
      <h3 class="flywheel-title">{{ flywheel.title }}</h3>
      <div class="flywheel layout-align-center-center layout-column">
        <img
          :src="`${$urls.img}/diagrams/flywheels/londolozi-flywheel-${
            i + 1
          }.png`"
        />
        <div
          v-for="(item, n) in flywheel.items"
          :key="n"
          :class="`flywheel-text flywheel-text-${n + 1}`"
        >
          <vue-simple-scrollbar>
            <span v-html="item"></span>
          </vue-simple-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSimpleScrollbar from "vue-simple-scrollbar";

export default {
  name: "Flywheels",
  props: ["data"],
  components: {
    VueSimpleScrollbar,
  },
};
</script>

<style scoped>
@import "~vue-simple-scrollbar/dist/vue-simple-scrollbar.css";
.flywheels .flywheel-wrap:last-child {
  margin-bottom: 3em;
}
.flywheel {
  font-size: 1rem;
  padding: 4em 0;
  position: relative;
  margin: 0 -1.5em 4.8em;
  width: calc(100% + 3em);
  max-width: none;
}
.flywheel::after {
  content: "";
  height: 1px;
  background: #eee;
  width: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  bottom: -1.8em;
  left: 50%;
}
.flywheel img {
  margin: 0;
  max-width: 45%;
}
h4.flywheel-title {
  margin-bottom: 0;
  color: var(--grey3);
  text-align: center;
  font-weight: 200;
  position: relative;
  padding-bottom: 0.9em;
}
h4.flywheel-title::after {
  content: "";
  height: 3px;
  position: absolute;
  transform: translate(-50%, 0);
  width: 3.8em;
  left: 50%;
  background: var(--blue);
  bottom: 0;
}
h3.flywheel-title {
  margin: 0.9em 0 1.5em;
  font-family: "trajan";
  text-transform: capitalize;
  font-size: 1.3em;
  text-align: center;
  line-height: 1.3;
}
.no-top-item h3.flywheel-title {
  margin-bottom: 0;
}
.flywheel-text {
  font-style: italic;
  font-size: 0.72em;
  position: absolute;
  height: 4.3em;
  display: block;
  line-height: 1.2em;
  color: var(--grey2);
  right: 0;
  width: 12.4em;
  left: auto;
  transform: translateY(-50%);
  justify-content: flex-start;
  text-align: left;
  overflow: hidden;
}
.flywheel-text-1,
.flywheel-text-7,
.flywheel-text-8 {
  text-align: right;
  justify-content: flex-end;
  right: auto;
  left: 0;
}
.flywheel-text-2 {
  top: 0;
}
.flywheel-text-1,
.flywheel-text-3 {
  top: 25%;
}
.flywheel-text-4,
.flywheel-text-8 {
  top: 50%;
  transform: translate(0, -50%);
}
.flywheel-text-5,
.flywheel-text-7 {
  top: 75%;
}
.flywheel-text-6 {
  top: auto;
  bottom: -0.5em;
}
.flywheel-text-1,
.flywheel-text-7 {
  left: 3%;
}
.flywheel-text-2,
.flywheel-text-6 {
  left: 50%;
  transform: translate(-50%, 0);
  justify-content: center;
  text-align: center;
}
.flywheel-text-3,
.flywheel-text-5 {
  right: 3%;
}
.flywheel-text-4,
.flywheel-text-8 {
  right: 0;
}

.modal-body .flywheel-wrap:last-child .flywheel {
  margin-bottom: 0;
}
.modal-body .flywheel-wrap:last-child .flywheel::after {
  display: none;
}

.flywheel-text::after {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  position: absolute;
  content: "";
  width: 100%;
  height: 1.4em;
  top: 3.1em;
  left: 0;
  z-index: 1;
}

/deep/ .ss-scroll {
  opacity: 0;
}
/deep/ .flywheel .ss-content span {
  padding-bottom: 0.6em;
}

/deep/ .flywheel-text b {
  font-size: 0.997em;
}

/deep/ .flywheel-text ul {
  margin: 0;
  padding: 0;
}

/deep/ .flywheel-text li {
  margin: 0;
  padding: 0;
  list-style-position: inside;
}
/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 550px) {
  .flywheel {
    padding: 5.7em 0;
    margin: 0 -1.2em 4.8em;
    width: calc(100% + 2.5em);
  }
  .flywheel-text-1,
  .flywheel-text-3 {
    top: 22%;
  }
  .flywheel-text-4,
  .flywheel-text-8 {
    width: 9em;
  }
  .flywheel-text-5,
  .flywheel-text-7 {
    top: 78%;
  }
}
</style>
