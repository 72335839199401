//CSS
import "normalize.css";
import "./assets/css/helpers/flex.css";
import "./assets/css/style.css";
import "./assets/css/compatibility.css";
import "vue-thin-modal/dist/vue-thin-modal.css";
import "./assets/css/responsive.css";

//Vue related imports
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//gtags
import VueGtag from "vue-gtag";

//dynamic meta tags
import VueMeta from "vue-meta";
Vue.use(
  VueGtag,
  {
    config: { id: "UA-156543173-1" },
  },
  router
);

//current device
import device from "current-device";
//bowser browser detection
import Bowser from "bowser";
Object.defineProperty(Vue.prototype, "$bowser", {
  value: Bowser.parse(window.navigator.userAgent),
});

//modal
import VueThinModal from "./assets/js/vue-thin-modal/vue-thin-modal.js";

//axios
import axios from "axios";
import VueAxios from "vue-axios";

//gsap
import TweenMax from "gsap/TweenMax";
import SplitText from "./assets/js/gsap-bonus/SplitText";
import MorphSVGPlugin from "./assets/js/gsap-bonus/MorphSVGPlugin";
import DrawSVGPlugin from "./assets/js/gsap-bonus/DrawSVGPlugin";

//parallax
import Parallax from "parallax-js";
Object.defineProperty(Vue.prototype, "$parallax", { value: Parallax });

//use
Vue.use(VueAxios, axios);

Vue.use(VueMeta);

Vue.use(VueThinModal, {
  autoMountPortal: false,
});

//urls
const baseURL =
  process.env.NODE_ENV === "development"
    ? window.location.protocol + "//" + window.location.host
    : "https://" + window.location.host;

//const buildUrl = 'https://londolozi.pillbox.co.za'
//const buildUrl = 'https://staging.londolozi.africa'
const buildUrl = "https://londolozi.africa";

Vue.prototype.$urls = {
  base: baseURL,
  data: baseURL + "/data/",
  img: "https://media.londolozi.com/africa",
  video: "https://media.londolozi.com/africa/videos/",
  buildUrl: buildUrl,
};

//use amazon s3 url for data
// if (typeof baseURL !== 'undefined'){
//     Vue.axios.defaults.baseURL = baseURL+'/data';
// }
Vue.axios.defaults.baseURL =
  "https://s3.amazonaws.com/londolozi.live/africa/data";

//sharpspring
Vue.prototype.$sharpspring = {
  siteID: "KOI-45YT8Z655U",
  forms: {
    enquiry_traveller: {
      baseURI:
        "https://app-3QNFJS5T58.marketingautomation.services/webforms/receivePostback/MzawMDGzNDMxAQA/",
      endpoint: "b36d7f99-9f98-44f5-9f81-e4a178627a6b",
    },
  },
};
window._ss = [];

Vue.config.productionTip = false;

//global navigation guards
router.beforeEach((to, from, next) => {
  console.log("beforeEach");

  //if going to home and intro not yet show, set it to be shown by loader
  if (!store.state.introShown && to.path === "/") {
    console.log("going to home and intro not yet show, set it to show");

    store.dispatch("toggleStatus", {
      type: "showIntro",
      status: true,
    });
  }

  next();
});
router.beforeResolve((to, from, next) => {
  console.log("beforeResolve");
  next();
});
router.afterEach((to, from) => {
  console.log("afterEach");

  //modal hash
  if (to.path === from.path && to.has != from.hash) {
  }
  //swapping slides
  else if (to.name != "view-chapter-section" && to.name === from.name) {
  } else {
    //push sharpsrping pageview
    window._ss.push(["_trackPageView"]);
    console.log("%c%s", "color: yellow;", "_trackPageView view-change");

    store.dispatch("toggleStatus", {
      type: "viewLoaded",
      status: false,
    });
    store.dispatch("toggleStatus", {
      type: "viewDataLoaded",
      status: false,
    });
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    setTimeout(function () {
      document.dispatchEvent(new Event("x-app-rendered"));
    }, 10 * 1000);
  },
}).$mount("#app");
