<template>
  <div class="media-wrap">
    <div class="image-container">
      <img :src="data.image" />
    </div>
    <media-caption v-if="data.description || info">
      <template v-slot:desc v-if="data.description">
        {{ data.description }}
      </template>
      <template v-slot:sub v-if="info">
        <span v-html="info"></span>
      </template>
    </media-caption>
  </div>
</template>

<script>
import MediaCaption from "@/components/ui/MediaCaption.vue";

export default {
  name: "ImageWithCaption",
  props: ["data"],
  data() {
    return {};
  },
  computed: {
    info() {
      if (this.data.title || this.data.photographer) {
        let info = "image: ";

        this.setLink();

        info = this.data.title ? info + this.data.title : info;
        info = this.data.photographer
          ? info + " by " + this.data.photographer
          : info;

        return info;
      }
      return false;
    },
  },
  components: {
    MediaCaption,
  },
  methods: {
    setLink() {
      if (this.data.title && this.data.link) {
        this.data.title = `<a href='${this.data.link}' target='_blank'>${this.data.title}</a>`;
      }
    },
  },
};
</script>

<style scoped></style>
