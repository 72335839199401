import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loaderLoaded: false,
    coreDataLoaded: false,
    viewDataLoaded: false,
    viewLoaded: false,
    viewReady: false,
    menuOpen: false,
    introShown: false,
    showIntro: false,
    modalOpen: false,
    menuButton: false,
    introSkip: false,
    disableParallax: false,
    lightboxData: false,
    scrollBarWidth: "0px",
    currentScrollBarWidth: "0px",
    data: {
      POSTS: {},
    },
  },
  getters: {
    getChapterPartBasics(state) {
      return state.data.OUTLINE.data.chapters.map((chapter) => {
        return {
          parts: chapter.parts,
          title: chapter.title,
          roman: chapter.roman,
          main: chapter.images.main.replace("chapters/", "chapters/small/"),
          slug: chapter.id,
        };
      });
    },
    getChapterLinks(state) {
      return state.data.OUTLINE.data.chapters.map((chapter) => {
        return {
          parts: chapter.parts,
          slug: chapter.id,
          title: chapter.title,
          externalUrl: chapter.external_chapter_link,
          main: chapter.images.main.replace("chapters/", "chapters/small/"),
          background: chapter.images.background.replace(
            "chapters/",
            "chapters/small/"
          ),
          roman: chapter.roman,
        };
      });
    },
    getPosts(state) {
      return state.data.POSTS;
    },
  },
  mutations: {
    TOGGLE(state, payload) {
      state[payload.type] = payload.status;
    },
    SET_DATA(state, payload) {
      //state.data[payload.type] = payload.data;
      state.data[payload.type] = payload;
    },
    TOGGLE_MENU(state) {
      state.menuOpen = !state.menuOpen;
    },
    SET_POSTS(state, payload) {
      state.data.POSTS[payload.name] = payload;
    },
    SET_LIGHTBOX(state, payload) {
      state.lightboxData = payload;
    },
    ADD_DATA_TO_HOME(state, payload) {
      state.data[payload.type].data.home[payload.name] = payload.data;
    },
    ADD_DATA_TO_PART(state, payload) {
      state.data[payload.type].data[payload.name] = payload.data;
    },
    ADD_MODALS_TO_LOAD(state, payload) {
      state.data[payload.type].data.modals_to_load.push(...payload.data);
    },
  },
  actions: {
    showModal({ commit }, modalName) {
      commit("TOGGLE", {
        type: "modalOpen",
        status: modalName,
      });
    },
    hideModal({ commit }) {
      commit("TOGGLE", {
        type: "modalOpen",
        status: false,
      });
    },
    setLightboxData({ commit }, payload) {
      commit("SET_LIGHTBOX", payload);
    },
    toggleMenu({ commit }) {
      commit("TOGGLE_MENU");
    },
    toggleStatus({ commit }, payload) {
      console.log("toggelStatus " + payload.type + " to " + payload.status);
      commit("TOGGLE", payload);
    },
    showMenuButtonAction({ commit }) {
      commit("TOGGLE", {
        type: "menuButton",
        status: true,
      });
    },
    hideMenuButtonAction({ commit }) {
      console.log("hideMenuButtonAction");
      commit("TOGGLE", {
        type: "menuButton",
        status: false,
      });
    },
    loadCoreData({ commit, state }, payload) {
      //dont load if exists in state
      if (state.data[payload.type]) {
        console.log("data already exists - " + payload.type);
        commit("TOGGLE", {
          type: "coreDataLoaded",
          status: true,
        });
      }
      //fetch the data
      else {
        console.log("loading new data - " + payload.type);
        axios
          .get(axios.defaults.baseURL + payload.url)
          .then((response) => {
            console.log(
              "%c%s",
              "color: purple;",
              "commiting base data: " + payload.type
            );

            commit("SET_DATA", {
              type: payload.type,
              data: response.data,
              error: false,
              loaded: true,
            });

            if (response.data.home.modals_to_load) {
              console.log("%c%s", "color: purple;", "has modals to load");

              return response.data.home.modals_to_load;
            }

            return false;
          })
          .then((response) => {
            if (response) {
              console.log("%c%s", "color: purple;", "loading modals");

              let modals = response.map((modal) =>
                axios.get(axios.defaults.baseURL + `/modals/${modal}.json`)
              );

              return axios.all(modals).then((res) => {
                return res.map((modal) => modal.data);
              });
            }

            console.log("%c%s", "color: purple;", "no modals to load");

            return false;
          })
          .then((response) => {
            if (response) {
              console.log(
                "%c%s",
                "color: purple;",
                "committing modals to outline"
              );

              commit("ADD_DATA_TO_HOME", {
                type: payload.type,
                data: response,
                name: "modals",
                error: false,
                loaded: true,
              });
            }

            //is this core data for the app?
            commit("TOGGLE", {
              type: "coreDataLoaded",
              status: true,
            });
          })
          .catch((error) => {
            commit("SET_DATA", {
              type: payload.type,
              data: false,
              error: error,
              loaded: true,
            });
            commit("TOGGLE", {
              type: "coreDataLoaded",
              status: true,
            });
          });
      }
    },
    loadPartData({ commit, state }, payload) {
      //slide or timeline for use later
      let type = false;

      //dont load if exists in state
      if (state.data[payload.type]) {
        console.log(
          "%c%s",
          "color: orange;",
          "data already exists - " + payload.type
        );

        commit("TOGGLE", {
          type: "viewDataLoaded",
          status: true,
        });
      }
      //fetch the data
      else {
        console.log(
          "%c%s",
          "color: orange;",
          "fetching data - " + payload.type
        );
        axios
          .get(axios.defaults.baseURL + payload.url)
          .then((response) => {
            console.log(
              "%c%s",
              "color: orange;",
              "commiting base data: " + payload.type
            );

            commit("SET_DATA", {
              type: payload.type,
              data: response.data,
              error: false,
              loaded: true,
            });

            return response.data;
          })
          .then((response) => {
            console.log(
              "%c%s",
              "color: orange;",
              "checking part data for sliders and timelines"
            );

            if (response.components.sliders) {
              type = "sliders";
              console.log(type);
              console.log(
                "%c%s",
                "color: orange;",
                "slider found, fetching data"
              );
              return axios
                .get(
                  axios.defaults.baseURL +
                    `/sliders/${response.components.sliders}.json`
                )
                .then((res) => {
                  return res.data;
                });
            } else if (response.components.timelines) {
              type = "timelines";
              console.log(type);
              console.log(
                "%c%s",
                "color: orange;",
                "timeline found, fetching data"
              );
              return axios
                .get(
                  axios.defaults.baseURL +
                    `/timelines/${response.components.timelines}.json`
                )
                .then((res) => {
                  return res.data;
                });
            }

            console.log(
              "%c%s",
              "color: orange;",
              "no slider or timeline found"
            );
            return false;
          })
          .then((response) => {
            if (response) {
              console.log(
                "%c%s",
                "color: orange;",
                "commiting " + type + " data to state"
              );

              commit("ADD_DATA_TO_PART", {
                type: payload.type,
                data: response,
                name: type,
                error: false,
                loaded: true,
              });

              if (response.modals_to_load) {
                console.log(
                  "%c%s",
                  "color: orange;",
                  type + " has modals, committing to modals to load"
                );
                commit("ADD_MODALS_TO_LOAD", {
                  type: payload.type,
                  data: response.modals_to_load,
                  error: false,
                  loaded: true,
                });
              }
            }

            let state_modals_to_load =
              state.data[payload.type].data.modals_to_load;
            if (state_modals_to_load.length) {
              console.log(
                "%c%s",
                "color: orange;",
                "models to load",
                state_modals_to_load
              );
              return state_modals_to_load;
            }

            return false;
          })
          .then((response) => {
            if (response) {
              console.log("%c%s", "color: orange;", "loading modals");

              let modals = response.map((modal) =>
                axios.get(axios.defaults.baseURL + `/modals/${modal}.json`)
              );

              return axios.all(modals).then((res) => {
                return res.map((modal) => modal.data);
              });
            }

            console.log("%c%s", "color: orange;", "no modals to load");

            return false;
          })
          .then((response) => {
            if (response) {
              console.log(
                "%c%s",
                "color: orange;",
                "committing modals to part"
              );

              commit("ADD_DATA_TO_PART", {
                type: payload.type,
                data: response,
                name: "modals",
                error: false,
                loaded: true,
              });
            }

            console.log(
              "%c%s",
              "color: orange;",
              "toggle state viewDataLoaded"
            );
            //is this core data for the app?

            commit("TOGGLE", {
              type: "viewDataLoaded",
              status: true,
            });
          })
          .catch((error) => {
            commit("SET_DATA", {
              type: payload.type,
              data: false,
              error: error,
              loaded: true,
            });
            commit("TOGGLE", {
              type: "viewDataLoaded",
              status: true,
            });
          });
      }
    },
    getPostsData({ commit, state }, payload) {
      //check if exist
      if (state.data.POSTS[payload.name]) {
        console.log("posts data already exists for " + payload.name);

        return;
      }

      //get the data and commit
      else {
        let ids = payload.ids.toString();
        let number = payload.number ? payload.number : 6;
        let params = {};

        if (payload.type == "ids" || !payload.type) {
          params.include = ids;
          payload.number = payload.ids.length;
        } else if (payload.type == "categories") {
          params.categories = ids;
        } else if (payload.type == "tags") {
          params.tags = ids;
        } else {
          return false;
        }

        params.per_page = number;

        console.log("loading new posts for " + payload.name);
        axios
          .get("https://blog.londolozi.com/wp-json/wp/v2/posts/", {
            params: params,
          })
          .then((response) => {
            let postData = response.data.map((post) => {
              return {
                id: post.id,
                name: post.name,
                author_name: post.author_name,
                author_picture: post.author_picture,
                thumbnail: post.thumbnail,
                date_formatted: post.date_formatted,
                link: post.link,
              };
            });

            if (response.data.length > 0) {
              commit("SET_POSTS", {
                name: payload.name,
                data: postData,
                error: false,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
});
