<template>
  <div class="info-box-container relative" :class="{ 'info-box-wide': wide }">
    <div class="info-box-wrap no-select ghost">
      <div class="info-box info-box-modal">
        <h3>
          <span :style="{ width: headingWidth }">
            {{ data.title }}
          </span>
        </h3>
        <p v-if="htmlText">
          <span v-html="data.text"></span>
        </p>
        <p v-else>
          {{ data.text }}
        </p>
      </div>
    </div>
    <div class="info-box-wrap corporeal" :class="{ open: open }" ref="box">
      <div class="info-box info-box-modal" :id="`box-${data.modal}`">
        <h3
          class="parallax-item parallax-relative"
          :data-depth="headingParallaxDepth"
        >
          <span :style="{ width: headingWidth }">
            {{ data.title }}
          </span>
        </h3>
        <p v-if="htmlText">
          <span v-html="data.text"></span>
        </p>
        <p v-else>
          {{ data.text }}
        </p>
        <button
          class="btn btn-round btn-info-box"
          @mouseover="hoverButton(true)"
          @mouseleave="hoverButton(false)"
          @click="showModal(data.modal)"
        >
          <i class="ai-feather"></i>
        </button>
        <div class="frame frame-normal" ref="frame">
          <div class="line bottom left">
            <div class="line-dynamic"></div>
          </div>
          <div class="line bottom right">
            <div class="line-dynamic"></div>
          </div>
          <div class="line side left">
            <div class="line-dynamic"></div>
          </div>
          <div class="line side right">
            <div class="line-dynamic"></div>
          </div>
          <div class="line top right">
            <div class="line-dynamic"></div>
          </div>
          <div class="line top left">
            <div class="line-dynamic"></div>
          </div>
        </div>
        <div class="frame frame-wide" ref="frameWide">
          <div class="line bottom left horizontal-half-left">
            <div class="line-dynamic"></div>
          </div>
          <div class="line bottom right horizontal-half-right">
            <div class="line-dynamic"></div>
          </div>
          <div class="line left-bottom left">
            <div class="line-dynamic"></div>
          </div>
          <div class="line left-top left">
            <div class="line-dynamic"></div>
          </div>
          <div class="line side right side-right-top">
            <div class="line-dynamic"></div>
          </div>
          <div class="line side right side-right-bottom">
            <div class="line-dynamic"></div>
          </div>
          <div class="line top right horizontal-half-right">
            <div class="line-dynamic"></div>
          </div>
          <div class="line top left horizontal-half-left">
            <div class="line-dynamic"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modalFunctions } from "@/mixins/core.js";
import { partComponent } from "@/mixins/core.js";

export default {
  name: "InfoModalBox",
  mixins: [modalFunctions, partComponent],
  props: {
    data: Object,
    headingDepth: Number,
    headingWidth: {
      type: String,
      default: "80%",
    },
    wide: {
      type: Boolean,
    },
    htmlText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timelineHover: null,
      open: false,
    };
  },
  computed: {
    headingParallaxDepth() {
      return this.headingDepth ? this.headingDepth : 0.4;
    },
  },
  mounted() {
    const vm = this;
    const { box, frame } = this.$refs;

    //enter timeline
    const tlBoxEnter = new TimelineMax({
      paused: true,
      onComplete: function () {
        vm.open = true;
      },
    });

    tlBoxEnter.from(box, 1.7, {
      height: 0,
      paddingBottom: 0,
      ease: Power3.easeInOut,
    });

    this.timeline = tlBoxEnter;

    //hover timeline
    const tlBoxHover = new TimelineMax({
      paused: true,
      ease: Power0.noEase,
    });

    tlBoxHover
      .add("start")
      .to(
        `#box-${this.modalName}.info-box-modal .frame-normal .line.bottom .line-dynamic`,
        0.1,
        {
          width: "100%",
        },
        "start"
      )
      .add("firstLineIn")
      .to(
        `#box-${this.modalName}.info-box-modal .frame-normal .line.side .line-dynamic`,
        0.2,
        {
          height: "100%",
        },
        "firstLineIn"
      )
      .add("secondLineIn")
      .to(
        `#box-${this.modalName}.info-box-modal .frame-normal .line.top .line-dynamic`,
        0.1,
        {
          width: "100%",
        }
      );

    //wide frame
    tlBoxHover
      .to(
        `#box-${this.modalName}.info-box-modal .frame-wide .line.left-bottom .line-dynamic`,
        0.1,
        {
          height: "100%",
        },
        "start"
      )
      .to(
        `#box-${this.modalName}.info-box-modal .frame-wide .line.left-top .line-dynamic`,
        0.1,
        {
          height: "100%",
        },
        "start"
      )
      .to(
        `#box-${this.modalName}.info-box-modal .frame-wide .horizontal-half-left .line-dynamic`,
        0.1,
        {
          width: "100%",
        },
        "firstLineIn"
      )
      .to(
        `#box-${this.modalName}.info-box-modal .frame-wide .horizontal-half-right .line-dynamic`,
        0.1,
        {
          width: "100%",
        },
        "firstLineIn+=0.1"
      )
      .to(
        `#box-${this.modalName}.info-box-modal .frame-wide .line.side.right .line-dynamic`,
        0.2,
        {
          height: "100%",
        },
        "secondLineIn"
      );

    this.timelineHover = tlBoxHover;
  },
  methods: {
    hoverButton(play) {
      if (device.type == "desktop") {
        play ? this.timelineHover.play() : this.timelineHover.reverse();
      }
    },
  },
};
</script>

<style scoped>
.items-shown .info-box-wrap {
  height: auto !important;
}

.info-box-wrap {
  overflow: hidden;
  transform-origin: top left;
  padding-top: 1.1rem;
  padding-bottom: 4.1rem;
  display: block !important;
}
.info-box {
  width: 21.8rem;
  background: rgba(255, 255, 255, 0.65);
  position: relative;
  padding: 2.8rem 2.4rem;
  text-align: center;
}
.btn-round {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  box-shadow: 0 0 0 0 rgba(55, 152, 251, 0.6);
}
.info-box-wrap.open .btn-round {
  animation: pulse 1.25s 2 cubic-bezier(0.66, 0, 0, 1);
}
p {
  font-size: 0.97rem;
  margin: 1.66rem 0 1.82rem;
  line-height: 1.63;
  z-index: 2;
  position: relative;
}
h3 {
  position: relative;
  padding: 0 0 2.1rem 0;
  margin: 0;
  z-index: 2;
  font-weight: normal;
}
h3 span {
  width: 80%;
  margin: 0 auto;
  display: block;
}
h3::after {
  content: "";
  background: var(--blue);
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 23%;
  height: 3px;
  position: absolute;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

/*----------  WIDE  ----------*/
@media screen and (min-width: 479px) {
  .info-box-wide .info-box-wrap {
    padding-left: 4.1rem;
    padding-bottom: 0.5rem;
  }

  .info-box-wide .info-box {
    width: 40em;
    text-align: left;
    padding: 2.4em 2.2em 1.6em 4.9em;
  }

  .info-box-wide h3 {
    padding-bottom: 1.3rem;
  }

  .info-box-wide h3 span {
    margin: 0;
  }

  .info-box-wide h3::after {
    display: none;
  }

  .info-box-wide p {
    margin-top: 0;
    margin-bottom: 1.2em;
  }

  .info-box-wide .info-box-wrap .btn-round {
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .info-box-wide .frame {
    display: none;
  }
  .info-box-wide .frame-wide {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .info-box {
    font-size: 13px;
    width: 21.8em;
    padding: 2.8em 2.4em;
  }
}

@media screen and (max-width: 479px) {
  h3 span {
    max-width: 60%;
  }
}
</style>
