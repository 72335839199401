//color filter values
export const colorFilterBluePulse = {
  data() {
    return {
      filterBlueLoadingTimeline: null,
      filterBlueID: "filter-blue",
      grey: "0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 1 0",
      blue: "0 1.0 0 0 0 0 1.05 0.3 0.035 0 0 0.6 2.2 0.1 0 0 0 0 1 0",
      blue2: "0 1 0 0 0 0 1.03 0.18 0.021 0 0 0.759 1.321 0.06 0 0 0.399 0 1 0",
      blue3:
        "0 1 0 0 0 0 1.017 0.103 0.012 0 0 0.861 0.756 0.034 0 0 0.655 0 1 0",
    };
  },
  methods: {
    createFilterBlueTimeline() {
      const blueColorFilter = document.getElementById(
        this.filterBlueID
      ).firstChild;
      const tlFilterBlue = new TimelineMax({
        paused: true,
      });

      tlFilterBlue.to(blueColorFilter, 6, {
        attr: { values: this.blue3 },
        ease: Power3.easeInOut,
        yoyo: true,
        repeat: -1,
      });

      this.filterBlueLoadingTimeline = tlFilterBlue;
    },
    runFilterBlueTimeline() {
      this.filterBlueLoadingTimeline.restart();
    },
    pauseFilterBlueTimeline() {
      this.filterBlueLoadingTimeline.pause();
    },
  },
};
