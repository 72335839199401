<template>
  <div id="app" :class="[$route.name, classMenu, classModal]">
    <app-header v-show="viewLoaded"></app-header>
    <transition v-if="coreDataLoaded">
      <router-view />
    </transition>
    <app-menu v-if="coreDataLoaded"></app-menu>
    <modal-portal :currentScrollBarWidth="currentScrollBarWidth" />
    <app-svg-defs></app-svg-defs>
    <app-loading></app-loading>
    <div class="responsive-info">
      {{ window.width }} x {{ window.height }}
      <br />
      {{ window.orientation }}
      <br />
      {{ deviceType }}
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { handleResize } from "@/mixins/core.js";
import { colorFilterValues } from "@/mixins/chapters/slider.js";

import Header from "./components/layout/Header.vue";
import LoadingScreen from "./components/ui/LoadingScreen.vue";
import Menu from "./components/ui/Menu.vue";
import SvgDefs from "@/components/misc/SvgDefs";

export default {
  metaInfo: {
    title: "Impact",
    titleTemplate: "%s | Londolozi Impact",
    meta: [
      {
        vmid: "og:type",
        property: "og:type",
        content: "website",
      },
      {
        vmid: "og:image",
        property: "og:image",
        content: "https://media.londolozi.com/africa/opengraph/home.jpg",
      },
      {
        vmid: "twitter:card",
        property: "twitter:card",
        content: "summary_large_image",
      },
      {
        vmid: "twitter:site",
        property: "twitter:site",
        content: "@londolozi",
      },
      {
        vmid: "fb:app_id",
        property: "fb:app_id",
        content: "308681132868293",
      },
    ],
  },
  mixins: [colorFilterValues, handleResize],
  components: {
    "app-header": Header,
    "app-loading": LoadingScreen,
    "app-menu": Menu,
    "app-svg-defs": SvgDefs,
  },
  data() {
    return {
      validChapters: null,
      validParts: null,
      currentScrollBarWidth: null,
      rem: 16,
    };
  },
  computed: {
    ...mapState([
      "viewDataLoaded",
      "viewLoaded",
      "loaderLoaded",
      "coreDataLoaded",
      "menuOpen",
      "modalOpen",
    ]),
    classMenu() {
      return this.menuOpen ? "menu-open" : "menu-closed";
    },
    classModal() {
      return this.modalOpen ? "modal-open" : "modal-closed";
    },
    classBrowser() {
      return (
        "browser-" + this.$bowser.browser.name.toLowerCase().replace(" ", "-")
      );
    },
    classEngine() {
      return (
        "engine-" + this.$bowser.engine.name.toLowerCase().replace(" ", "-")
      );
    },
    deviceType() {
      return device ? device.type : null;
    },
  },
  watch: {
    rem(val) {
      document.documentElement.style.fontSize = this.rem + "px";
    },
    modalOpen() {
      this.setCurrentScrollBarWidth();
    },
    coreDataLoaded(val) {
      console.log("%c%s", "color: red;", "core data loaded, checking route");
      //set valid chapters
      this.validChapters = this.$store.state.data.OUTLINE.data.chapters.map(
        (slide) => slide.id
      );

      //set valid parts
      this.validParts = this.$store.state.data.OUTLINE.data.chapters.map(
        (slide) => slide.parts
      );
    },
    validChapters(val) {
      if (this.$route.params.slide) {
        const thisChapter = this.$route.params.slide;

        //check chapter
        if (!val.includes(thisChapter)) {
          console.log("%c%s", "color: red;", "invalid chapter");
          return this.$router.replace("/404/");
        }

        //check section
        if (this.$route.params.section) {
          let chapterIndex = val.indexOf(thisChapter);
          if (!this.validParts[chapterIndex][this.$route.params.section - 1]) {
            console.log(
              "%c%s",
              "color: red;",
              "invalid section, redirecting to 404"
            );
            return this.$router.replace("/404/");
          } else if (!this.$route.name) {
            return this.$router.replace("/404/");
          }
        }

        //check for chapter without trailing slash
        if (!this.$route.name && val.includes(thisChapter)) {
          const lastChar = !this.$route.path.substr(-1);
          if (lastChar !== "/") {
            console.log("%c%s", "color: red;", "chapter missing slash");
            return this.$router.push({
              name: "view-chapter-slide",
              params: {
                slide: thisChapter,
              },
            });
          }
        }
      }
    },
  },
  created() {
    this.loadCoreData({
      url: "/outline.json",
      type: "OUTLINE",
      coreData: true,
    });

    this.compatibilityConfig();
  },
  methods: {
    ...mapActions(["loadCoreData", "toggleStatus"]),
    setCurrentScrollBarWidth() {
      let width = window.innerWidth - document.documentElement.offsetWidth;

      console.log("%c%s", "color: red;", "currentScrollBarWidth is " + width);

      this.toggleStatus({
        type: "currentScrollBarWidth",
        status: width + "px",
      });
    },
    turnOffParallax() {
      console.log("%c%s", "color: red;", "turn off parallax globally");
      this.toggleStatus({
        type: "disableParallax",
        status: true,
      });
    },
    compatibilityConfig() {
      console.log(device.type);
      console.log(device.os);
      console.log(device.orientation);
      console.log(this.$bowser.engine.name);
      console.log(this.$bowser.browser);
      const documentElement = window.document.documentElement;

      documentElement.classList.add(this.classEngine);
      documentElement.classList.add(this.classBrowser);
    },
  },
  mounted() {
    let vimeoScript = document.createElement("script");
    vimeoScript.setAttribute("src", "https://player.vimeo.com/api/player.js");
    document.head.appendChild(vimeoScript);

    //sharpspring

    //add tracking script
    let ss = document.createElement("script");
    ss.type = "text/javascript";
    ss.async = true;
    ss.src =
      ("https:" == document.location.protocol ? "https://" : "http://") +
      "koi-3QNFJS5T58.marketingautomation.services/client/ss.js?ver=2.2.1";
    let scr = document.getElementsByTagName("script")[0];
    scr.parentNode.insertBefore(ss, scr);

    //sharpspring tracking
    window._ss.push([
      "_setDomain",
      "https://koi-3QNFJS5T58.marketingautomation.services/net",
    ]);
    window._ss.push(["_setAccount", this.$sharpspring.siteID]);

    //get tracking id and add to sharpspring window object
    window._ss.sharpsringResponseCallback = function (response) {
      if (response) {
        if (response.trackingID) {
          window._ss.trackingID = response.trackingID;
          console.log("tracking id is " + response.trackingID);
        }
      }
    };
    window._ss.push([
      "_setResponseCallback",
      window._ss.sharpsringResponseCallback,
    ]);

    //track page view
    window._ss.push(["_trackPageView"]);
    console.log("%c%s", "color: yellow;", "_trackPageView main");
  },
};
</script>

<style scoped>
.responsive-info {
  display: none;
}
.responsive-info {
  color: red;
  font-family: "din";
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 215px;
  font-weight: 200;
  padding: 10px;
  line-height: 1;
  font-size: 17px;
}
#app {
  background: white;
}
</style>
