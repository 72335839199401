export const homeTimelines = {
  methods: {
    playTimeline() {
      this.homeTimeline.play();
    },
    createTimelines() {
      const {
        scene,
        ink,
        eagle,
        contents,
        logo,
        logoImgLogo,
        logoTextLondolozi,
        logoTextAfrica,
        logoAfrica,
        lead,
        leadLine,
        button,
      } = this.$refs;

      const layers = scene.childNodes[0].childNodes;

      const tlHomeOut = new TimelineMax({
        paused: true,
      });

      tlHomeOut
        .to(leadLine, 0.4, {
          height: 0,
          ease: Power2.easeOut,
        })
        .staggerTo(
          [logo, lead, button, eagle],
          1.2,
          {
            transformOrigin: "top center",
            y: "-=20",
            autoAlpha: 0,
            rotationX: -25,
            ease: Power1.easeInOut,
          },
          0.2,
          0
        )
        .staggerTo(
          layers,
          3,
          {
            transformOrigin: "center right",
            ease: Power1.easeIn,
            cycle: {
              x: this.layersXOut,
              y: this.layersYOut,
              scale: this.layersScale,
            },
          },
          0,
          0
        )
        .to(
          ink,
          3,
          {
            ease: Power1.easeIn,
            scale: 1.02,
            x: "-=2vw",
            y: "+=0.3%",
            autoAlpha: 0,
          },
          0
        )
        .to(
          scene,
          2.3,
          {
            autoAlpha: 0,
            ease: Power1.easeIn,
          },
          0.7
        );

      this.homeTimelineOut = tlHomeOut;

      const tlHomeElements = new TimelineMax();

      tlHomeElements
        .from(
          lead,
          0.9,
          {
            autoAlpha: 0,
            x: "-=35",
            ease: Power1.easeOut,
          },
          "africaIn+=.6"
        )
        .from(
          leadLine,
          0.9,
          {
            x: "-=35",
            ease: Power1.easeOut,
          },
          "africaIn+=.6"
        )
        .from(
          leadLine,
          0.51,
          {
            height: 0,
            ease: Power2.easeInOut,
          },
          "africaIn+=0.99"
        )
        .from(
          button,
          0.9,
          {
            autoAlpha: 0,
            x: "+=35",
            ease: Power1.easeOut,
          },
          "africaIn+=0.6"
        )
        .call(this.showViewContents, null, this, "africaIn+=.1")
        .from(
          eagle,
          1.9,
          {
            ease: Power3.easeInOut,
            scale: 0.3,
            x: "+=15vw",
            autoAlpha: 0,
          },
          "africaIn-=.13"
        )
        .from(
          eagle,
          1.2,
          {
            ease: Power3.easeInOut,
            y: "-=4.5vw",
          },
          "africaIn+=.3"
        )
        .call(this.showMenuButtonAction, null, this, "-=0.95");

      tlHomeElements.timeScale(0.7);

      const tlHomeLogo = new TimelineMax({
        paused: false,
      });

      tlHomeLogo.timeScale(2.5);

      const splitText = {
        africa: new SplitText(logoTextAfrica, {
          type: "chars",
        }),
        londolozi: new SplitText(logoTextLondolozi, {
          type: "chars",
        }),
      };

      tlHomeLogo
        .add("start")
        .from(logoImgLogo, 1.8, {
          y: "+=20",
          ease: Power2.easeInOut,
          autoAlpha: 0,
        })
        .from(
          logoTextLondolozi,
          1.8,
          {
            y: "+=20",
            ease: Power2.easeInOut,
          },
          "start+=.3"
        )
        .from(
          logoImgLogo,
          1.2,
          {
            // rotationX: -25,
            ease: Power2.easeOut,
          },
          "start+=.4"
        )
        .staggerFrom(
          splitText.londolozi.chars,
          0.8,
          {
            autoAlpha: 0,
            ease: Power3.easeIn,
          },
          0.07,
          "start+=.6"
        )
        .staggerFrom(
          splitText.africa.chars,
          1.8,
          {
            autoAlpha: 0,
            scale: 0.5,
            y: "+=10",
            transformOrigin: "50% top",
            ease: Power3.easeInOut,
            cycle: {
              x: ["+=64", "+=38", "+=12", "-=12", "-=38", "-=64"],
              delay: function (i) {
                return (
                  Math.floor(
                    Math.abs(i - (splitText.africa.chars.length - 1) / 2)
                  ) * 0.1
                );
              },
            },
          },
          0,
          "start+=1.1"
        )
        .from(
          logoAfrica,
          3,
          {
            ease: Power3.easeIn,
            autoAlpha: 0,
          },
          "start"
        )
        .add("africaIn")
        .call(this.winkLogo, null, this, "africaIn-=0.7")
        .add(tlHomeElements, "africaIn-=2.18");

      this.homeLogoTimeline = tlHomeLogo;

      const tlHome = new TimelineMax({
        paused: true,
        delay: 0.3,
      });

      tlHome.timeScale(this.timelineTimeScale);

      tlHome
        .call(this.scrollToBottom, null, this)
        .add("startScene")
        .to(scene, 1.5, {
          autoAlpha: 1,
          ease: Power1.easeOut,
        })
        .staggerFrom(
          layers,
          3.2,
          {
            ease: Power1.easeOut,
            cycle: {
              x: this.layersX,
              y: this.layersY,
              scale: this.layersScale,
            },
          },
          0,
          "startScene"
        )
        .from(
          ink,
          3.2,
          {
            ease: Power1.easeOut,
            scale: 1.02,
            x: "+=2vw",
            y: "-=0.3%",
            autoAlpha: 0,
          },
          "startScene"
        )
        .add("layersIn")
        .call(this.scrollToTop, null, this, "layersIn-=2")
        .add(tlHomeLogo, "layersIn-=1.8")
        .call(this.createParallax, null, this, "-=0.26");

      this.homeTimeline = tlHome;
    },
  },
};
