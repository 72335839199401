<template>
  <div class="post-card">
    <a
      :href="post.link"
      class="post-card-content layout-column"
      target="_blank"
      :class="{ 'no-avatar': !post.author_picture }"
    >
      <div class="post-image relative">
        <img :src="post.thumbnail" :alt="post.name" />
        <div
          class="
            post-image-overlay
            absolute
            layout-align-end-start layout-column
          "
        >
          <div v-if="post.author_name" class="post-author italic grey">
            {{ post.author_name }}
          </div>
        </div>
      </div>
      <div class="post-content relative">
        <div v-if="post.author_picture" class="post-avatar absolute">
          <img :src="post.author_picture" :alt="post.author_name" />
        </div>
        <div class="post-date">
          {{ post.date_formatted }}
        </div>
        <div class="post-title">
          {{ post.name }}
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "PostCard",
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
img {
  display: block;
  max-width: 100%;
}
.post-card {
  font-size: 1rem;
  border: 2px solid #eee;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
}
.post-card-content {
  width: 17.5em;
  max-width: 100%;
}
.post-image {
  height: 9.5em;
  overflow: hidden;
  object-position: center;
  background: var(--grey);
}
.post-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.post-image-overlay {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.13) 0%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 0) 48%,
    rgba(0, 0, 0, 0.16) 76%,
    rgba(0, 0, 0, 0.65) 100%
  );
}
.post-title {
  color: rgb(35, 31, 32);
  font-size: 1.05em;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0.6em 1.1em 0.9em;
}
.post-author {
  color: var(grey);
  font-size: 0.875em;
}
.post-date {
  font-size: 0.813em;
  color: #aaa;
}
.post-author,
.post-date {
  padding: 0.2rem 0 0.2rem 5rem;
}
.no-avatar .post-author,
.no-avatar .post-date {
  padding-left: 1.2rem;
}
.post-avatar {
  width: 3.3em;
  height: 3.3em;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #eee;
  left: 0.9em;
  top: 0;
  transform: translate(0, -50%);
  background: white;
}
.post-card .post-image img,
.post-card,
.post-title {
  transition: 0.3s ease-out;
}

.post-card:hover .post-image img {
  transform: scale(1.1);
}
.post-card:hover .post-title {
  color: var(--blue);
}
.post-card:hover {
  box-shadow: -2px 4px 12px rgba(0, 0, 0, 0.05);
}
</style>
