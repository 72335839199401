import { render, staticRenderFns } from "./MediaCaption.vue?vue&type=template&id=3233c541&scoped=true&"
import script from "./MediaCaption.vue?vue&type=script&lang=js&"
export * from "./MediaCaption.vue?vue&type=script&lang=js&"
import style0 from "./MediaCaption.vue?vue&type=style&index=0&id=3233c541&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3233c541",
  null
  
)

export default component.exports